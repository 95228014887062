import { FC } from "react";

export interface GridElementsProps {
  elements: string[];
  selectedElement?: string;
}

export const GridElements: FC<GridElementsProps> = ({
  elements,
  selectedElement = "",
}) => (
  <div className="grid grid-cols-3 md:grid-cols-10 gap-2 mb-8">
    {elements.map((element, idx) => (
      <p
        key={`solution-${element}-${idx}`}
        className={`text-2xl rounded-lg text-center border-2 ${
          selectedElement === element
            ? "bg-amber-200 border-amber-500 text-amber-800"
            : (idx + 1) % 5 === 0
              ? "bg-slate-200"
              : "bg-slate-50"
        } `}
      >
        {element}
      </p>
    ))}
  </div>
);
