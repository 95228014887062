import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useEffectOnce } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";

interface DismissalsContextType {
  dismissals: string[];
  dismiss: (name: string) => void;
}

const DismissalsContext = createContext<DismissalsContextType | undefined>(
  undefined,
);

export const useDismissals = () => {
  const context = useContext(DismissalsContext);

  if (context === undefined) {
    throw new Error("useDismissals must be used within a DismissalsProvider");
  }

  return context;
};

interface DismissalsProviderProps {
  children: ReactNode;
}

export const DismissalsProvider: React.FC<DismissalsProviderProps> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  const [dismissals, setDismissals] = useState<string[] | null>(null);

  const refreshDismissalsCallback = useCallback(
    () =>
      supabase
        .from("dismissals")
        .select("name")
        .eq("user_id", user.id)
        .then(({ data }) => setDismissals(data?.map((d) => d.name) ?? [])),
    [user.id],
  );

  const dismissCallback = useCallback(
    (name: string) => {
      if (dismissals?.includes(name)) {
        return;
      }

      return supabase
        .from("dismissals")
        .insert({ user_id: user.id, name })
        .then(refreshDismissalsCallback);
    },
    [dismissals, refreshDismissalsCallback, user.id],
  );

  useEffectOnce(() => {
    refreshDismissalsCallback();
  });

  if (dismissals === null) {
    return <LayoutLoading />;
  }

  return (
    <DismissalsContext.Provider
      value={{ dismissals, dismiss: dismissCallback }}
    >
      {children}
    </DismissalsContext.Provider>
  );
};
