import { useAtom } from "jotai";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce, usePrevious } from "react-use";

import { suiviCurrentGroupAtom } from "~/routes/suivi/atoms.ts";
import { Charts } from "~/routes/suivi/components/Charts.tsx";
import { Dimensions } from "~/routes/suivi/components/Dimensions.tsx";
import { ExercisesResults } from "~/routes/suivi/components/ExercisesResults.tsx";
import { Navigation } from "~/routes/suivi/components/Navigation.tsx";
import { useEplTestAttempts } from "~/routes/suivi/hooks/useEplTestAttempts.tsx";
import { usePilotestAttempts } from "~/routes/suivi/hooks/usePilotestAttempts.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const StatsPage: FC = () => {
  const navigate = useNavigate();

  const { settings } = useSettings();
  const { syncEplTest } = useEplTestAttempts();
  const { hasPilotestAutoSyncEnabled } = usePilotestCredentials();
  const { syncPilotest, retrieveResultsFromPilotest } = usePilotestAttempts();

  const prevHasPilotestAutoSyncEnabled = usePrevious(
    hasPilotestAutoSyncEnabled,
  );
  const prevEplTestSyncEnabled = usePrevious(settings?.epl_api_key);

  const [group, setGroup] = useAtom(suiviCurrentGroupAtom);

  useEffect(() => {
    if (!settings) {
      return navigate("/suivi/onboarding");
    }
  }, [navigate, settings]);

  // This one is used to get Pilotest results when auto-sync is enabled…
  useEffect(() => {
    if (
      prevHasPilotestAutoSyncEnabled === false &&
      hasPilotestAutoSyncEnabled
    ) {
      retrieveResultsFromPilotest();
    }
  }, [
    hasPilotestAutoSyncEnabled,
    prevHasPilotestAutoSyncEnabled,
    retrieveResultsFromPilotest,
  ]);

  // This one is used to get EPLtest results when sync is enabled…
  useEffect(() => {
    if (prevEplTestSyncEnabled === null && !!settings?.epl_api_key) {
      syncEplTest();
    }
  }, [prevEplTestSyncEnabled, settings?.epl_api_key, syncEplTest]);

  useEffectOnce(() => {
    setTimeout(
      () =>
        Promise.all([
          syncPilotest(),
          retrieveResultsFromPilotest(),
          syncEplTest(),
        ]),
      750,
    );
  });

  if (!settings) {
    return null;
  }

  return (
    <div>
      <Navigation selectedGroup={group} onSelectGroup={setGroup} />
      <section className="space-y-8 sm:space-y-8 mt-8">
        <Dimensions />
        <ExercisesResults group={group} />
        <Charts group={group} />
      </section>
    </div>
  );
};
