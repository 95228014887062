import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC, useEffect } from "react";

import { useDrawer } from "~/providers/DrawerProvider.tsx";

export const Drawer: FC = () => {
  const { isOpen, closeDrawer, drawerTitle, drawerContent } = useDrawer();

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === "Escape" ? closeDrawer() : null;

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [closeDrawer]);

  return (
    <Dialog open={isOpen} onClose={closeDrawer} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-3xl transform transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    onClick={closeDrawer}
                    className="relative rounded-md text-slate-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Fermer</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="bg-slate-200 px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="font-medium text-2xl">
                      {drawerTitle}
                    </DialogTitle>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  {drawerContent}
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
