import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Enums, Tables } from "~/supabase.types.ts";

type PartialImport = Pick<
  Tables<"suivi_imports">,
  "provider" | "attempts_added" | "at"
>;

interface ImportsContextType {
  imports: PartialImport[];
  saveImport: (
    source: Enums<"import_source">,
    provider: string,
    newAttemptsCount?: number,
  ) => Promise<void>;
  lastPilotestImportDate: Date | null;
}

const ImportsContext = createContext<ImportsContextType | undefined>(undefined);

export const useImports = () => {
  const context = useContext(ImportsContext);

  if (context === undefined) {
    throw new Error("useImports must be used within an ImportsProvider");
  }

  return context;
};

interface ImportsProviderProps {
  children: ReactNode;
}

export const ImportsProvider: React.FC<ImportsProviderProps> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  const [imports, setImports] = useState<PartialImport[]>([]);
  const [lastPilotestImportDate, setLastPilotestImportDate] =
    useState<Date | null>(null);

  const refreshImportsCallback = useCallback(
    async () =>
      supabase
        .from("suivi_imports")
        .select("provider, attempts_added, at")
        .eq("user_id", user.id)
        .order("at", { ascending: false })
        .then(({ data }) => setImports(data ?? [])),
    [user.id],
  );

  const saveImportCallback = useCallback(
    async (
      source: Enums<"import_source">,
      provider: string,
      newAttemptsCount?: number,
    ) => {
      if (source === "auto_sync" && !newAttemptsCount) {
        const { data: existingEmptyAutoSync } = await supabase
          .from("suivi_imports")
          .select("id")
          .eq("user_id", user.id)
          .eq("source", "auto_sync")
          .eq("attempts_added", 0)
          .order("at", { ascending: false })
          .limit(1)
          .single();

        if (existingEmptyAutoSync) {
          return supabase
            .from("suivi_imports")
            .update({ at: new Date().toISOString() })
            .eq("id", existingEmptyAutoSync.id)
            .then(refreshImportsCallback);
        }
      }

      return supabase
        .from("suivi_imports")
        .upsert({
          user_id: user.id,
          provider,
          source,
          attempts_added: newAttemptsCount ?? 0,
        })
        .then(refreshImportsCallback);
    },
    [refreshImportsCallback, user.id],
  );

  useEffect(() => {
    refreshImportsCallback();
  }, [refreshImportsCallback]);

  useEffect(() => {
    if (imports.length > 0) {
      setLastPilotestImportDate(new Date(imports[0].at));
    }
  }, [imports]);

  return (
    <ImportsContext.Provider
      value={{
        imports,
        saveImport: saveImportCallback,
        lastPilotestImportDate,
      }}
    >
      {children}
    </ImportsContext.Provider>
  );
};
