import { createBrowserRouter, redirect } from "react-router-dom";

import Layout from "~/components/Layout.tsx";
import { Alphabet } from "~/routes/alphabet";
import { Angles } from "~/routes/angles";
import { BoitesMots } from "~/routes/boites-mots";
import { Calculs } from "~/routes/calculs";
import { Cm1 } from "~/routes/cm1";
import { Cm2 } from "~/routes/cm2";
import { Liens } from "~/routes/liens";
import { Mathematiques } from "~/routes/mathematiques";
import { Memory } from "~/routes/memory";
import { NombresPremiers } from "~/routes/nombres-premiers";
import { Quizlet } from "~/routes/quizlet";
import { Root } from "~/routes/root";
import { Donate } from "~/routes/root/Donate.tsx";
import { ErrorPage } from "~/routes/root/ErrorPage.tsx";
import { LoginPage } from "~/routes/root/LoginPage.tsx";
import { Privacy } from "~/routes/root/Privacy.tsx";
import { Suivi } from "~/routes/suivi";
import { OnboardingPage } from "~/routes/suivi/OnboardingPage.tsx";
import { ResetPage } from "~/routes/suivi/ResetPage.tsx";
import { StatsPage } from "~/routes/suivi/StatsPage.tsx";
import { Tangram } from "~/routes/tangram";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Root />,
      },
      {
        path: "/suivi",
        element: <Suivi />,
        children: [
          {
            index: true,
            loader: () => redirect("/suivi/stats"),
          },
          {
            path: "onboarding",
            element: <OnboardingPage />,
          },
          {
            path: "stats",
            element: <StatsPage />,
          },
          {
            path: "reset",
            element: <ResetPage />,
          },
        ],
      },
      {
        path: "/alphabet",
        element: <Alphabet />,
      },
      {
        path: "/nombres-premiers",
        element: <NombresPremiers />,
      },
      {
        path: "/mathematiques",
        element: <Mathematiques />,
      },
      {
        path: "/boites-mots",
        element: <BoitesMots />,
      },
      {
        path: "/cm1",
        element: <Cm1 />,
      },
      {
        path: "/cm2",
        element: <Cm2 />,
      },
      {
        path: "/angles",
        element: <Angles />,
      },
      {
        path: "/tangram",
        element: <Tangram />,
      },
      {
        path: "/calculs",
        element: <Calculs />,
      },
      {
        path: "/liens",
        element: <Liens />,
      },
      {
        path: "/quizlet",
        element: <Quizlet />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/memory",
        element: <Memory />,
      },
      { path: "/donate/thank-you", element: <Donate /> },
    ],
  },
  { path: "/login", element: <LoginPage /> },
]);

export default router;
