import { useCallback } from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

export type BasicScore = Pick<Tables<"scores">, "success" | "timing">;

export const useScores = () => {
  const { user } = useCurrentUser();

  const saveScore = useCallback(
    async (exercise: string, variant: string, score: BasicScore) =>
      await supabase.from("scores").insert({
        user_id: user.id,
        exercise,
        variant: variant.toLocaleLowerCase(),
        ...score,
        at: new Date().toISOString(),
      }),
    [user.id],
  );

  const getScores = useCallback(
    async (exercise: string, variant: string) => {
      const { data } = await supabase
        .from("scores")
        .select("exercise, variant, success, timing, at")
        .eq("user_id", user.id)
        .eq("exercise", exercise)
        .eq("variant", variant)
        .order("at", { ascending: true });

      return data ?? [];
    },
    [user.id],
  );

  return { saveScore, getScores };
};
