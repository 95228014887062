import { useAtom } from "jotai";
import { FC } from "react";

import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Role } from "~/role.ts";
import { cm1TemplateAtom } from "~/routes/cm1/atoms.ts";
import { CM1_TEMPLATES } from "~/routes/cm1/templates.ts";
import { TrainingBaseView } from "~/routes/cm1/TrainingBaseView.tsx";

export const Cm1: FC = () => {
  const [template, setTemplate] = useAtom(cm1TemplateAtom);

  return (
    <Wrapper
      requireRole={Role.CM1}
      header={
        <div className="flex items-center justify-between">
          <h1 className="w-full flex justify-between items-center">
            <span className="text-3xl font-bold underline">CM1</span>
            <div className="flex items-center justify-center gap-3">
              <ScoreButton
                label="CM1"
                exercise="cm1"
                variant={template}
                withTimingReferenceLine={
                  template === "9 opérations (KD)" ? 25 : undefined
                }
              />

              <Select value={template} onValueChange={setTemplate}>
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner un mode…" />
                </SelectTrigger>
                <SelectContent>
                  {CM1_TEMPLATES.map((template, idx) => (
                    <SelectItem key={`option-${idx}`} value={template.name}>
                      {template.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </h1>
        </div>
      }
    >
      <TrainingBaseView template={template} />
    </Wrapper>
  );
};
