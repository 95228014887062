import { keyBy } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface FlaggedExercisesType {
  flaggedExercises: Tables<"suivi_flagged_exercises">[];
  flaggedExercisesList: Record<string, Tables<"suivi_flagged_exercises">>;
  refreshFlaggedExercises: () => void;
}

const FlaggedExercises = createContext<FlaggedExercisesType | undefined>(
  undefined,
);

export const useFlaggedExercises = () => {
  const context = useContext(FlaggedExercises);

  if (context === undefined) {
    throw new Error(
      "useFlaggerExercises must be used within an FlaggerExercisesProvider",
    );
  }

  return context;
};

interface FlaggedExercisesProviderProps {
  children: ReactNode;
}

export const FlaggedExercisesProvider: React.FC<
  FlaggedExercisesProviderProps
> = ({ children }) => {
  const { user } = useCurrentUser();

  const [flaggedExercises, setFlaggedExercises] = useState<
    Tables<"suivi_flagged_exercises">[]
  >([]);

  const [flaggedExercisesList, setFlaggedExercisesList] = useState<
    Record<string, Tables<"suivi_flagged_exercises">>
  >({});

  const refreshFlaggedExercises = useCallback(
    async () =>
      supabase
        .from("suivi_flagged_exercises")
        .select("*")
        .eq("user_id", user.id)
        .then(({ data }) => {
          setFlaggedExercises(data ?? []);
          setFlaggedExercisesList(keyBy(data, "name"));
        }),
    [user.id],
  );

  useEffect(() => {
    refreshFlaggedExercises();
  }, [refreshFlaggedExercises]);

  return (
    <FlaggedExercises.Provider
      value={{
        flaggedExercises,
        flaggedExercisesList,
        refreshFlaggedExercises,
      }}
    >
      {children}
    </FlaggedExercises.Provider>
  );
};
