import { FC, useCallback, useEffect, useState } from "react";

import { AdminQuestion } from "~/routes/quizlet/AdminQuestion.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface AdminProps {}

export const Admin: FC<AdminProps> = () => {
  const [questions, setQuestions] = useState<
    Tables<"quizlet_questions">[] | null
  >(null);

  const refreshQuestions = useCallback(
    () =>
      supabase
        .from("quizlet_questions")
        .select()
        .order("created_at", { ascending: false })
        .then(({ data }) => {
          setQuestions(data ?? []);
        }),
    [],
  );

  useEffect(() => {
    refreshQuestions();
  }, [refreshQuestions]);

  if (questions === null) {
    return "Chargement…";
  }

  return (
    <section className="flex flex-col gap-4">
      <div className="flex gap-8 px-4">
        <div className="w-5/12 uppercase text-slate-500 tracking-wider text-sm">
          Questions ({questions.length})
        </div>
        <div className="w-5/12 uppercase text-slate-500 tracking-wider text-sm">
          Réponses
        </div>
        <div className="w-2/12 uppercase text-slate-500 tracking-wider text-sm">
          Auteur
        </div>
      </div>
      {questions.map((question) => (
        <AdminQuestion
          key={`question-${question.id}`}
          question={question}
          onRefreshQuestion={refreshQuestions}
        />
      ))}
    </section>
  );
};
