import { FC } from "react";

export interface InputProps {
  label: string;
  name: string;
  value: string;
  onChange: (updatedValue: string) => void;
  placeholder?: string;
  pattern?: string;
}

export const Input: FC<InputProps> = ({
  label,
  name,
  value,
  onChange,
  pattern,
  placeholder,
}) => {
  return (
    <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-slate-300 focus-within:ring-2 focus-within:ring-blue-600">
      <label
        htmlFor="name"
        className="block text-xs font-medium text-slate-900"
      >
        {label}
      </label>
      <input
        type="text"
        name={name}
        id={name}
        className="block w-full border-0 p-0 text-slate-900 placeholder:text-slate-400 focus:ring-0 sm:text-sm sm:leading-6"
        value={value}
        onChange={(evt) => onChange(evt.currentTarget.value)}
        placeholder={placeholder}
        pattern={pattern}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
      />
    </div>
  );
};
