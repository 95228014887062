import {
  CM1_TEMPLATES,
  Cm1Operation,
  Cm1Template,
} from "~/routes/cm1/templates.ts";

export const generateMathOperation = (template: string): Cm1Operation => {
  const getRandomNumber = (min: number, max: number) => {
    let num;
    do {
      num = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (num === 0 || num === 1); // Exclude 0 and 1
    return num;
  };

  const getRandomSignFromChoices = (choices: string) => {
    const signs = choices.split(""); // Split signs into an array
    return signs[Math.floor(Math.random() * signs.length)]; // Randomly select one of the signs
  };

  // Find all <NUM> placeholders in the template
  const numMatches = [...template.matchAll(/<NUM:(-?\d+)\|(-?\d+)(:RAN)?>/g)];

  // Separate numbers into randomized and fixed arrays based on :RAN
  const randomizedNums: string[] = [];
  const fixedNums: { index: number; value: string }[] = [];

  numMatches.forEach((match, index) => {
    const min = Number(match[1]);
    const max = Number(match[2]);
    const randomPos = match[3] === ":RAN"; // Check if :RAN flag is present
    const number = getRandomNumber(min, max).toString();

    if (randomPos) {
      randomizedNums.push(number); // Add to the randomized numbers list
    } else {
      fixedNums.push({ index, value: number }); // Keep track of fixed numbers with their original position
    }
  });

  // Shuffle the randomized numbers
  for (let i = randomizedNums.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [randomizedNums[i], randomizedNums[j]] = [
      randomizedNums[j],
      randomizedNums[i],
    ]; // Swap
  }

  let randomizedNumIndex = 0;
  let fixedNumIndex = 0;

  // Create an array that will represent the final set of numbers, in correct positions
  const finalNumbers: string[] = new Array(numMatches.length);

  // Place the fixed numbers in their original positions
  fixedNums.forEach(({ index, value }) => {
    finalNumbers[index] = value;
  });

  // Fill remaining positions with the shuffled randomized numbers
  numMatches.forEach((_match, index) => {
    if (!finalNumbers[index]) {
      finalNumbers[index] = randomizedNums[randomizedNumIndex++];
    }
  });

  // Replace the placeholders in the template with the final numbers
  let populatedTemplate = template.replace(
    /<NUM:(-?\d+)\|(-?\d+)(:RAN)?>/g,
    () => {
      return finalNumbers[fixedNumIndex++];
    },
  );

  // Replace each <SIGN:...> with a random sign from the given set of signs
  populatedTemplate = populatedTemplate.replace(
    /<SIGN:([+\-*/]+)>/g,
    (_, signs) => {
      return getRandomSignFromChoices(signs);
    },
  );

  // Safely evaluate the expression
  let result: number;
  try {
    result = eval(populatedTemplate); // Evaluate the mathematical expression
  } catch (error) {
    throw new Error("Error evaluating the expression.");
  }

  // Apply cosmetic replacements to the operation string
  const formattedOperation = populatedTemplate
    .replaceAll("+ -", "- ")
    .replaceAll("- -", "+ ")
    .replaceAll(" ** 2", "²")
    .replaceAll(" ** 3", "³")
    .replaceAll(" ** 4", "⁴")
    .replaceAll(" ** 5", "⁵")
    .replaceAll(" * ", " × ")
    .replace(/(-)(\d+)/g, "- $2");

  return { operation: formattedOperation, result: Math.round(result) };
};

export const getTemplateFromName = (templateName: string): Cm1Template => {
  const template = CM1_TEMPLATES.find((t) => t.name === templateName);

  if (!template) {
    return { name: "N/A", template: "" };
  }

  return template;
};
