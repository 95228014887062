import * as Sentry from "@sentry/browser";
import { Session } from "@supabase/supabase-js";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { supabase } from "~/supabase.ts";

interface SupabaseContextType {
  session: Session | null;
  sessionLoading: boolean;
  isAuthenticated: boolean;
}

const SupabaseContext = createContext<SupabaseContextType | undefined>(
  undefined,
);

export const useSupabase = () => {
  const context = useContext(SupabaseContext);

  if (context === undefined) {
    throw new Error("useSupabase must be used within an SupabaseProvider");
  }

  return context;
};

interface SupabaseProviderProps {
  children: ReactNode;
}

export const SupabaseProvider: React.FC<SupabaseProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [sessionLoading, setSessionLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);

  const isAuthenticated = useMemo(
    () => !sessionLoading && !!session,
    [session, sessionLoading],
  );

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        Sentry.setUser({ id: session?.user.id, email: session?.user.email });
        setSession(session);
      })
      .finally(() => setSessionLoading(false));

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      Sentry.setUser({ id: session?.user.id, email: session?.user.email });
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!sessionLoading && !session) {
      navigate("/login");
    }
  }, [navigate, session, sessionLoading]);

  const context = useMemo(
    () => ({
      session,
      sessionLoading,
      isAuthenticated,
    }),
    [isAuthenticated, session, sessionLoading],
  );

  return (
    <SupabaseContext.Provider value={context}>
      {children}
    </SupabaseContext.Provider>
  );
};
