import { WrenchIcon } from "lucide-react";
import { FC, useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { BoitesMotsSettings } from "~/routes/boites-mots/components/BoitesMotsSettings.tsx";
import { TrainingView } from "~/routes/boites-mots/TrainingView.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

export type BOITE_MOTS_APP_MODE = "FREE_TRAINING" | "TIMED_TRAINING";

export const BoitesMots: FC = () => {
  const { user } = useCurrentUser();

  const [mode, setMode] = useState<BOITE_MOTS_APP_MODE>("FREE_TRAINING");
  const [themes, setThemes] = useState<Tables<"boites_mots_themes">[] | null>(
    null,
  );
  const [editMode, setEditMode] = useState(false);

  const refreshThemes = useCallback(() => {
    supabase
      .from("boites_mots_themes")
      .select()
      .eq("user_id", user.id)
      .order("theme")
      .then(({ data }) => setThemes(data ?? []));
  }, [user.id]);

  useEffectOnce(refreshThemes);

  const handleOnChangeMode = (mode: string) => {
    setMode(mode as BOITE_MOTS_APP_MODE);
  };

  if (themes === null) {
    return <LayoutLoading />;
  }

  return (
    <Wrapper
      header={
        <div className="flex items-center justify-between">
          <h1 className="text-2xl sm:text-3xl font-bold underline">
            Boites à Mots
          </h1>
          <div className="flex items-center gap-3">
            {themes.length > 0 && (
              <Button
                className="hidden sm:flex"
                onClick={() => setEditMode(!editMode)}
                variant="ghost"
              >
                <WrenchIcon className="w-5 h-5" />
                <span className="ml-2.5">
                  {editMode ? "Masquer" : "Afficher"} mes thèmes
                </span>
              </Button>
            )}
            <ScoreButton
              label="Boites à Mots"
              exercise="boites-a-mots"
              variant="default"
              period="weekly"
              withTimings={false}
              filter={() => true}
            />
            <Select value={mode} onValueChange={handleOnChangeMode}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner un mode…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="FREE_TRAINING">Entrainement</SelectItem>
                <SelectItem value="TIMED_TRAINING">Temps limité</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      }
    >
      {themes.length === 0 || editMode ? (
        <BoitesMotsSettings themes={themes} onRefreshThemes={refreshThemes} />
      ) : (
        <TrainingView mode={mode} themes={themes} />
      )}
    </Wrapper>
  );
};
