import { shuffle, sortBy } from "lodash";

import {
  RectangleBottom,
  RectangleLeft,
  RectangleRight,
  RectangleTop,
  SquareEmpty,
  SquareFull,
  TriangleBottomLeft,
  TriangleBottomRight,
  TriangleTopLeft,
  TriangleTopRight,
} from "~/routes/tangram/Figures.tsx";

export const getRandomAlphabet = () =>
  shuffle("ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""));

const getAvailableFigures = (randomAlphabet: string[]) => {
  const figures = [
    { name: "squareEmpty", Figure: SquareEmpty },
    { name: "squareFull", Figure: SquareFull },
    { name: "rectangleTop", Figure: RectangleTop },
    { name: "rectangleRight", Figure: RectangleRight },
    { name: "rectangleBottom", Figure: RectangleBottom },
    { name: "rectangleLeft", Figure: RectangleLeft },
    { name: "triangleTopRight", Figure: TriangleTopRight },
    { name: "triangleBottomRight", Figure: TriangleBottomRight },
    { name: "triangleBottomLeft", Figure: TriangleBottomLeft },
    { name: "triangleTopLeft", Figure: TriangleTopLeft },
  ];

  return figures.map((figure, idx) => ({
    ...figure,
    letter: randomAlphabet[idx],
  }));
};

export const generateGrid = (size = 4, randomAlphabet: string[]) => {
  const availableFigures = getAvailableFigures(randomAlphabet);

  return {
    figures: sortBy(availableFigures, ["letter"]),
    items: [...Array(size * size).keys()].map(
      () =>
        availableFigures[Math.floor(Math.random() * availableFigures.length)],
    ),
  };
};
