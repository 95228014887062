import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";

export const useTabState = () => {
  const [tabStateCurrent, setTabStateCurrent] = useState("visible");

  const tabStatePrevious = usePrevious(tabStateCurrent);

  const handleVisibilityChange = useCallback(() => {
    setTabStateCurrent(document.visibilityState);
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return {
    tabStateCurrent,
    tabStatePrevious,
    isTabActive: tabStateCurrent === "visible",
  };
};
