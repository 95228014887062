import { SettingsIcon } from "lucide-react";
import { FC, useState } from "react";

import { ScoreButton } from "~/components/ScoreButton.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { Admin } from "~/routes/quizlet/Admin.tsx";
import { Questions } from "~/routes/quizlet/Questions.tsx";

export type QuizletFilter = "allQuestions" | "bookmarked" | "mine";

export const Quizlet: FC = () => {
  const { canAccess } = useCurrentUser();

  const [filter, setFilter] = useState<QuizletFilter>("allQuestions");

  const [adminMode, setAdminMode] = useState(false);

  // const handleOnUnlock = async (key: string) => {
  //   // Previous users can continue to use the legacy password…
  //   if (key === "Go!Psy02022") {
  //     // TODO: Update this logic…
  //     // return saveSettings({ quizlet_unlocked_at: new Date().toISOString() });
  //   }
  //
  //   const { data } = await supabase
  //     .from("quizlet_keys")
  //     .select("id")
  //     .eq("key", key)
  //     .is("redeemed_at", null)
  //     .single();
  //
  //   if (!data) {
  //     return alert("La clé d'accès saisie n'est pas reconnue !");
  //   }
  //
  //   // TODO: Update this logic…
  //   // saveSettings({ quizlet_unlocked_at: new Date().toISOString() }).then(() =>
  //   //   supabase
  //   //     .from("quizlet_keys")
  //   //     .update({ redeemed_at: new Date().toISOString() })
  //   //     .eq("id", data.id),
  //   // );
  // };

  return (
    <Wrapper
      requireRole={Role.Quizlet}
      extended={adminMode}
      header={
        <div className="flex items-center justify-between">
          <h1 className="w-full flex justify-between items-center">
            <span className="text-3xl font-bold underline">Quizlet</span>
            {true && (
              <div className="flex items-center justify-center gap-3">
                {canAccess(Role.AdminQuizlet) && (
                  <Button
                    variant="ghost-destructive"
                    onClick={() => setAdminMode(!adminMode)}
                  >
                    <SettingsIcon />
                    <span className="ml-2">Admin</span>
                  </Button>
                )}
                <ScoreButton
                  label="Quizlet"
                  exercise="quizlet"
                  variant={filter}
                  withTimings={false}
                  filter={() => true}
                />
                <Select
                  value={filter}
                  onValueChange={(filter) => setFilter(filter as QuizletFilter)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Sélectionner une catégorie…" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="allQuestions">
                      Toutes les questions
                    </SelectItem>
                    <SelectItem value="bookmarked">Mes favoris</SelectItem>
                    <SelectItem value="mine">Mes questions</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            )}
          </h1>
        </div>
      }
    >
      {canAccess(Role.AdminQuizlet) && adminMode ? (
        <Admin />
      ) : (
        <Questions filter={filter} />
      )}

      {/*{canAccess(Role.AdminQuizlet) && adminMode ? (*/}
      {/*  <Admin />*/}
      {/*) : settings?.quizlet_unlocked_at ? (*/}
      {/*  <Questions filter={filter} />*/}
      {/*) : (*/}
      {/*  <Password onUnlock={handleOnUnlock} />*/}
      {/*)}*/}
    </Wrapper>
  );
};
