import clsx from "clsx";
import { format } from "date-fns";
import { FC } from "react";
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { backgroundClassNameFromStanineClass } from "~/routes/suivi/utils/backgroundClassNameFromStanineClass.ts";
import { fillClassNameFromStanineClass } from "~/routes/suivi/utils/fillClassNameFromStanineClass.ts";

interface EplTestDetailsDrawerProps {
  attempts: ExerciseAttempt[];
}

export const EplTestDetailsDrawer: FC<EplTestDetailsDrawerProps> = ({
  attempts = [],
}) => {
  return (
    <div>
      <div className="w-full">
        <ResponsiveContainer width="100%" height={292}>
          <ScatterChart margin={{ top: 8, right: 16, bottom: 8, left: -32 }}>
            <CartesianGrid stroke="#e5e5e5" vertical={false} />
            <XAxis
              dataKey="at"
              tickFormatter={(time) => format(time, "d MMM yy")}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: "#888" }}
              dy={5}
            />
            <YAxis dataKey="stanineClass" domain={[1, 9]} />
            <YAxis
              width={18}
              ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
              domain={[1, 9]}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 14, fill: "#888" }}
              dx={-5}
            />
            <Scatter name="Scores" data={attempts} fill="black">
              {attempts.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  className={fillClassNameFromStanineClass(entry.stanineClass)}
                />
              ))}
            </Scatter>
            {/*<ReferenceLine*/}
            {/*  y={mean}*/}
            {/*  label={{*/}
            {/*    position: "top",*/}
            {/*    value: `Moyenne : ${mean.toFixed(2)}`,*/}
            {/*    fill: "red",*/}
            {/*    fontSize: 12,*/}
            {/*  }}*/}
            {/*  stroke="red"*/}
            {/*  strokeDasharray="3 3"*/}
            {/*/>*/}
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-6">
        <h2 className="font-medium text-xl mb-4">
          Détails ({attempts?.length} essais)
        </h2>
        <div className="mt-1 mb-6 flow-root">
          <table className="min-w-full">
            <tbody className="bg-white">
              {attempts
                ?.slice()
                .reverse()
                .map((attempt, idx) => (
                  <tr key={idx} className="odd:bg-slate-50">
                    <td className="whitespace-nowrap py-1.5 pl-2 text-xs text-slate-500">
                      {format(attempt.at, "eeee d MMM y à HH:mm:ss")}
                    </td>
                    <td className="whitespace-nowrap px-2 py-1 text-xs text-slate-900 tabular-nums flex justify-end items-center gap-4">
                      <span>{attempt.percentScore} %</span>
                      <span
                        className={clsx(
                          "px-4 py-1 rounded",
                          backgroundClassNameFromStanineClass(
                            attempt.stanineClass,
                          ),
                        )}
                      >
                        {attempt.stanineClass}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
