import { format, parse } from "date-fns";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { Enums } from "~/supabase.types.ts";

export const ChartsCustomTooltip = ({
  active,
  payload,
  label,
  view,
}: TooltipProps<ValueType, NameType> & { view: Enums<"graph_display"> }) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-1 bg-white px-3 py-2 rounded border shadow-sm">
      <p className="text-xs">
        {format(parse(label, "yy-MM-dd", new Date()), "d MMM y")}
      </p>
      <p className="text-sm">
        <strong>{view === "stanine" ? "Classe" : "Pourcentage"} : </strong>
        {payload[0].value} {view === "percentage" && " %"}
      </p>
    </div>
  );
};
