export const textClassNameFromPercentScore = (stanineClass: number): string => {
  if (stanineClass < 50) {
    return "text-red-500";
  } else if (stanineClass < 60) {
    return "text-orange-500";
  } else if (stanineClass < 70) {
    return "text-amber-500";
  } else if (stanineClass < 80) {
    return "text-yellow-500";
  } else if (stanineClass < 85) {
    return "text-lime-500";
  } else if (stanineClass < 90) {
    return "text-green-500";
  } else if (stanineClass < 95) {
    return "text-emerald-500";
  } else if (stanineClass < 97) {
    return "text-blue-500";
  } else if (stanineClass <= 100) {
    return "text-blue-800";
  } else {
    return "text-black";
  }
};
