export interface Cm1Operation {
  operation: string;
  result: number;
}

export interface Cm1Template {
  name: string;
  template: string;
}

export const CM1_TEMPLATES: Cm1Template[] = [
  { name: "Calculs simples", template: "<NUM:-19|19> <SIGN:+-> <NUM:-19|19>" },
  {
    name: "Additions avancées",
    template: "<NUM:9|99> <SIGN:+-> <NUM:9|99>",
  },
  { name: "Tables de multiplications", template: "<NUM:2|9> * <NUM:2|9>" },
  { name: "Multiplications", template: "<NUM:2|99> * <NUM:2|99>" },
  { name: "Carrés", template: "<NUM:2|30> ** 2" },
  { name: "Cubes", template: "<NUM:2|10> ** 3" },
  {
    name: "7 opérations",
    template:
      "<NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-299|299:RAN>",
  },
  {
    name: "8 opérations",
    template:
      "<NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-299|299:RAN>",
  },
  {
    name: "9 opérations (Cadets)",
    template:
      "<NUM:-999|999:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN>",
  },
  {
    name: "7 opérations (EPL)",
    template:
      "<NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:11|99> * <NUM:11|99>",
  },
  {
    name: "8 opérations (EPL)",
    template:
      "<NUM:-999|999:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:-99|99:RAN> <SIGN:+-> <NUM:11|99> * <NUM:11|99>",
  },
];
