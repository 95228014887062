import { useMemo } from "react";

import { useAttempts } from "~/routes/suivi/hooks/useAttempts.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { htmlDateToDate } from "~/routes/suivi/utils/htmlDateToDate.ts";
import { resultsByDate } from "~/routes/suivi/utils/resultsByDate.ts";

export const useAggregatedResultsFromDate = (fromDate: string = "") => {
  const { settings } = useSettings();
  const { attempts } = useAttempts();

  const attemptsFromDate = useMemo(() => {
    const date = htmlDateToDate(fromDate);

    return date ? attempts.filter((a) => a.at >= date) : attempts;
  }, [attempts, fromDate]);

  return useMemo(
    () =>
      resultsByDate(
        attemptsFromDate,
        fromDate,
        settings?.graph_compute ?? "max",
      ),
    [attemptsFromDate, settings?.graph_compute, fromDate],
  );
};
