import { FC, useState } from "react";

import { Input } from "~/components/Input.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import {
  getLettersToGuessFromUserInput,
  IndexedAlphabet,
} from "~/routes/alphabet/alphabet.ts";
import { LearningBaseView } from "~/routes/alphabet/LearningBaseView.tsx";
import { TrainingBaseView } from "~/routes/alphabet/TrainingBaseView.tsx";

type APP_MODE = "LEARN" | "FREE_TRAINING" | "TIMED_TRAINING";

export const Alphabet: FC = () => {
  const [mode, setMode] = useState<APP_MODE>("FREE_TRAINING");

  const [letters, setLetters] = useState<string>(
    "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z",
  );
  const [indexedAlphabet, setIndexedAlphabet] = useState<IndexedAlphabet[]>(
    getLettersToGuessFromUserInput(letters, 100),
  );

  const handleOnChangeMode = (mode: APP_MODE) => {
    setMode(mode as APP_MODE);
    setIndexedAlphabet(getLettersToGuessFromUserInput(letters, 100));
  };

  return (
    <Wrapper
      header={
        <div className="flex items-center justify-between">
          <h1 className="w-full flex justify-between items-center">
            <span className="text-3xl font-bold underline">Alphabet</span>
            <div className="flex items-center justify-center gap-3">
              <ScoreButton
                label="Alphabet"
                exercise="alphabet"
                variant="default"
                period="daily"
                withTimings={false}
                filter={() => true}
              />
              <Select value={mode} onValueChange={handleOnChangeMode}>
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner un mode…" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="LEARN">Apprentissage</SelectItem>
                  <SelectItem value="FREE_TRAINING">Entrainement</SelectItem>
                  <SelectItem value="TIMED_TRAINING">Temps limité</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </h1>
        </div>
      }
    >
      <>
        <div className="hidden mb-4 sm:mb-6">
          <Input
            name="letters"
            label="Lettres à utiliser"
            pattern="[A-Z]"
            value={letters}
            onChange={setLetters}
          />
        </div>
        {mode === "TIMED_TRAINING" ? (
          <TrainingBaseView
            letters={letters}
            alphabet={indexedAlphabet}
            withChrono={true}
          />
        ) : mode === "FREE_TRAINING" ? (
          <TrainingBaseView letters={letters} alphabet={indexedAlphabet} />
        ) : (
          <LearningBaseView letters={letters} />
        )}
      </>
    </Wrapper>
  );
};
