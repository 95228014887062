import clsx from "clsx";
import { format, parseISO } from "date-fns";
import { FC, useEffect, useState } from "react";
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { backgroundClassNameFromStanineClass } from "~/routes/suivi/utils/backgroundClassNameFromStanineClass.ts";
import { fillClassNameFromStanineClass } from "~/routes/suivi/utils/fillClassNameFromStanineClass.ts";
import { getExerciseNameAndSource } from "~/routes/suivi/utils/getExerciseNameAndSource.ts";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface PilotestDetailsDrawerProps {
  exercise: string;
}

export const PilotestDetailsDrawer: FC<PilotestDetailsDrawerProps> = ({
  exercise,
}) => {
  const { user } = useCurrentUser();

  const [attempts, setAttempts] = useState<
    Omit<Tables<"suivi_attempts">, "id" | "user_id">[] | null
  >(null);

  useEffect(() => {
    const { name } = getExerciseNameAndSource(exercise);

    supabase
      .from("suivi_attempts")
      .select("provider, exercise, stanine_class, percent_score, at")
      .eq("user_id", user.id)
      .eq("exercise", name)
      .order("at", { ascending: true })
      .then(({ data }) => setAttempts(data));
  }, [exercise, user.id]);

  const processedData =
    attempts?.map((item) => ({
      date: new Date(item.at).getTime(), // Convert date to timestamp
      percent_score: item.percent_score,
      stanine_class: item.stanine_class,
    })) ?? [];

  // const mean = meanBy(processedData, (d) => d.stanine_class);

  return (
    <div>
      <div className="w-full">
        <ResponsiveContainer width="100%" height={292}>
          <ScatterChart margin={{ top: 8, right: 16, bottom: 8, left: -32 }}>
            <CartesianGrid stroke="#e5e5e5" vertical={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(unixTime) =>
                format(new Date(unixTime), "d MMM yy")
              }
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: "#888" }}
              dy={5}
            />
            <YAxis dataKey="stanine_class" domain={[1, 9]} />
            <YAxis
              width={18}
              ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
              domain={[1, 9]}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 14, fill: "#888" }}
              dx={-5}
            />
            <Scatter name="Scores" data={processedData} fill="black">
              {processedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  className={fillClassNameFromStanineClass(entry.stanine_class)}
                />
              ))}
            </Scatter>
            {/*<ReferenceLine*/}
            {/*  y={mean}*/}
            {/*  label={{*/}
            {/*    position: "top",*/}
            {/*    value: `Moyenne : ${mean.toFixed(2)}`,*/}
            {/*    fill: "red",*/}
            {/*    fontSize: 12,*/}
            {/*  }}*/}
            {/*  stroke="red"*/}
            {/*  strokeDasharray="3 3"*/}
            {/*/>*/}
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-6">
        <h2 className="font-medium text-xl mb-4">
          Détails ({attempts?.length} essais)
        </h2>
        <div className="mt-1 mb-6 flow-root">
          <table className="min-w-full">
            <tbody className="bg-white">
              {attempts
                ?.slice()
                .reverse()
                .map((attempt, idx) => (
                  <tr key={idx} className="odd:bg-slate-50">
                    <td className="whitespace-nowrap py-1.5 pl-2 text-xs text-slate-500">
                      {format(parseISO(attempt.at!), "eeee d MMM y à HH:mm:ss")}
                    </td>
                    <td className="whitespace-nowrap px-2 py-1 text-xs text-slate-900 tabular-nums flex justify-end items-center gap-4">
                      <span>{attempt.percent_score} %</span>
                      <span
                        className={clsx(
                          "px-4 py-1 rounded",
                          backgroundClassNameFromStanineClass(
                            attempt.stanine_class,
                          ),
                        )}
                      >
                        {attempt.stanine_class}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
