export const backgroundClassNameFromStanineClass = (
  stanineClass: number,
): string => {
  if (stanineClass < 2) {
    return "bg-red-500 text-white";
  } else if (stanineClass < 3) {
    return "bg-orange-500 text-white";
  } else if (stanineClass < 4) {
    return "bg-amber-500 text-white";
  } else if (stanineClass < 5) {
    return "bg-yellow-500 text-white";
  } else if (stanineClass < 6) {
    return "bg-lime-500 text-white";
  } else if (stanineClass < 7) {
    return "bg-green-500 text-white";
  } else if (stanineClass < 8) {
    return "bg-emerald-500 text-white";
  } else if (stanineClass < 9) {
    return "bg-blue-500 text-white";
  } else if (stanineClass < 10) {
    return "bg-blue-800 text-white";
  } else {
    return "bg-white";
  }
};
