import clsx from "clsx";
import { FC, ReactNode } from "react";

export interface ButtonPillProps {
  onClick: () => void;
  children: ReactNode;
  className?: string;
  type?: HTMLButtonElement["type"];
}

export const ButtonPill: FC<ButtonPillProps> = ({
  children,
  onClick,
  className,
  type,
}) => (
  <button
    type={type ?? "button"}
    className={clsx(
      "text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
      className,
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
