import { useAtom } from "jotai";
import { FC, Fragment, useEffect, useState } from "react";

import { Button } from "~/components/Button.tsx";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import { ToggleShort } from "~/components/ToggleShort.tsx";
import { useClock } from "~/hooks/useClock.ts";
import { useScores } from "~/hooks/useScores.ts";
import {
  getLetterFromPosition,
  getLetterPosition,
  IndexedAlphabet,
} from "~/routes/alphabet/alphabet.ts";
import {
  alphabetShowSolutions,
  alphabetSwapQuestionAtom,
} from "~/routes/alphabet/atoms.ts";
import { GridLetters } from "~/routes/alphabet/GridLetters.tsx";

export interface TrainingBaseViewProps {
  letters: string;
  alphabet: IndexedAlphabet[];
  withChrono?: boolean;
}

export const TrainingBaseView: FC<TrainingBaseViewProps> = ({
  letters = "",
  alphabet = [],
  withChrono = false,
}) => {
  const { clock, restartClock, stopClock } = useClock({
    interval: withChrono ? 2 : 0,
    onRestart: () => handleClickAnswer(0),
  });
  const { saveScore } = useScores();

  const [swapQuestion, setSwapQuestion] = useAtom(alphabetSwapQuestionAtom);
  const [showSolutions, setShowSolutions] = useAtom(alphabetShowSolutions);

  const [currentLetter, setCurrentLetter] = useState<
    IndexedAlphabet | undefined
  >();
  const [pastLetters, setPastLetters] = useState<IndexedAlphabet[]>([]);

  useEffect(() => {
    if (alphabet.length) {
      setCurrentLetter(alphabet[0]);
    }

    setPastLetters([]);
  }, [alphabet]);

  const handleClickAnswer = (position: number) => {
    if (!currentLetter) {
      stopClock();
      return;
    }

    setPastLetters((prev) => [
      ...prev,
      {
        ...currentLetter,
        status:
          getLetterPosition(currentLetter.letter) === position ? "won" : "lost",
      },
    ]);

    saveScore("alphabet", "default", {
      success: getLetterPosition(currentLetter.letter) === position,
      timing: 0,
    });

    setCurrentLetter(alphabet[currentLetter.index + 1]);

    restartClock();
  };

  return (
    <Fragment>
      {currentLetter && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <TitleLabel className="mb-1">
              {swapQuestion ? "Position" : "Lettre"}
            </TitleLabel>
            <div className="overflow-hidden rounded-lg bg-slate-50 h-24 sm:h-36">
              <div className="px-3 py-3 h-full flex justify-center items-center text-5xl sm:text-8xl font-semibold">
                {swapQuestion
                  ? getLetterPosition(currentLetter.letter)
                  : currentLetter.letter}
              </div>
            </div>
          </div>
          <div>
            <h2 className="mb-1 flex justify-between">
              <TitleLabel>{swapQuestion ? "Lettre" : "Position"}</TitleLabel>
              {clock !== 0 && (
                <span
                  className="text-slate-500 tabular-nums cursor-pointer"
                  title="Désactiver le chronomètre"
                  onClick={() => stopClock()}
                >
                  ⌛ {clock}
                </span>
              )}
            </h2>
            <div className="overflow-hidden rounded-lg bg-slate-50">
              <div className="grid grid-cols-5 gap-2 px-3 py-2">
                {[...Array(26).keys()].map((idx) => (
                  <Button
                    key={`letter-${idx}`}
                    onClick={() => handleClickAnswer(idx + 1)}
                  >
                    {swapQuestion ? getLetterFromPosition(idx + 1) : idx + 1}
                  </Button>
                ))}
              </div>
            </div>
            <div className="mt-2 flex flex-col space-y-1">
              <ToggleShort
                label="Deviner la lettre d'une position"
                toggled={swapQuestion}
                onToggle={setSwapQuestion}
              />
              <ToggleShort
                label="Afficher les solutions"
                toggled={showSolutions}
                onToggle={setShowSolutions}
              />
            </div>
          </div>
        </div>
      )}
      <div className="col-span-2 mt-8">
        {showSolutions && (
          <GridLetters
            letters={letters}
            selectedLetter={currentLetter?.letter}
          />
        )}
      </div>
      {pastLetters.length > 0 && (
        <div className="col-span-2 mt-12">
          <h2 className="mb-2 uppercase font-semibold flex justify-between">
            <span>Résultats</span>
            <span>
              {pastLetters.filter((c) => c.status === "won").length}{" "}
              ✅&nbsp;&nbsp;/&nbsp;&nbsp;
              {pastLetters.filter((c) => c.status === "lost").length} ❌
            </span>
          </h2>
          <div className="grid grid-cols-4 sm:grid-cols-8 gap-2 mb-8">
            {[...pastLetters].reverse().map((w, idx) => (
              <div
                key={`past-${idx}`}
                className={`flex items-center space-x-2 px-2 py-2 rounded ${
                  w.status === "won" ? "bg-green-100" : "bg-red-100"
                }`}
              >
                {w.letter}
                &nbsp;:&nbsp;
                <span className="font-semibold">
                  {getLetterPosition(w.letter)}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};
