import { sample } from "lodash";

export interface IndexedAlphabet {
  letter: string;
  index: number;
  status?: "won" | "lost";
}

export const getLettersToGuess = (limit = 20) => {
  const alphabet: IndexedAlphabet[] = [];

  for (let l = 0; l < limit; l++) {
    alphabet.push({
      index: l,
      letter: String.fromCharCode(65 + Math.floor(Math.random() * 26)),
    });
  }

  return alphabet;
};

export const getLettersToGuessFromUserInput = (letters = "", limit = 20) => {
  const alphabet: IndexedAlphabet[] = [];
  const deduplicatedLetters = [...new Set(letters.split(","))];

  for (let l = 0; l < limit; l++) {
    alphabet.push({
      index: l,
      letter: sample(deduplicatedLetters) ?? "",
    });
  }

  return alphabet;
};

export const getLetterPosition = (letter: string): number => {
  const DIFFERENCE_CHARCODE_AND_LETTERS = 96;

  // @ts-expect-error It's OK…
  return letter.toLowerCase().charCodeAt() - DIFFERENCE_CHARCODE_AND_LETTERS;
};

export const getLetterFromPosition = (position: number): string => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase();

  return alphabet[position - 1];
};
