import { addSeconds } from "date-fns";
import { FC } from "react";
import { useTimer } from "react-timer-hook";

import { ButtonPill, ButtonPillProps } from "~/components/ButtonPill.tsx";

interface ButtonPillCountdownProps extends ButtonPillProps {
  duration: number;
  onExpire: () => void;
}

export const ButtonPillCountdown: FC<ButtonPillCountdownProps> = ({
  children,
  duration,
  onExpire,
  onClick,
  className,
  type,
}) => {
  const { totalSeconds } = useTimer({
    autoStart: duration !== -1,
    expiryTimestamp: addSeconds(new Date(), duration),
    onExpire,
  });

  if (duration === -1) {
    return (
      <ButtonPill onClick={onClick} className={className} type={type}>
        <span>{children}</span>
      </ButtonPill>
    );
  }

  return (
    <ButtonPill onClick={onClick} className={className} type={type}>
      <span>{children}</span>
      <span className="text-xs ml-1.5 text-slate-200 font-light tabular-nums">
        ({totalSeconds})
      </span>
    </ButtonPill>
  );
};
