import { atomWithStorage } from "jotai/utils";

export const alphabetSwapQuestionAtom = atomWithStorage(
  "alphabet:swapQuestion",
  false,
);

export const alphabetShowSolutions = atomWithStorage(
  "alphabet:showSolution",
  false,
);
