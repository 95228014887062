import { keyBy, mapValues } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface RenamingsType {
  renamings: Tables<"suivi_renamings">[];
  renamingsList: Record<string, string>;
  refreshRenamings: () => void;
}

const Renamings = createContext<RenamingsType | undefined>(undefined);

export const useRenamings = () => {
  const context = useContext(Renamings);

  if (context === undefined) {
    throw new Error("useRenamings must be used within a RenamingsProvider");
  }

  return context;
};

interface RenamingsProviderProps {
  children: ReactNode;
}

export const RenamingsProvider: React.FC<RenamingsProviderProps> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  const [renamings, setRenamings] = useState<Tables<"suivi_renamings">[]>([]);

  const [renamingsList, setRenamingsList] = useState<Record<string, string>>(
    {},
  );

  const refreshRenamings = useCallback(
    async () =>
      supabase
        .from("suivi_renamings")
        .select("*")
        .eq("user_id", user.id)
        .then(({ data }) => {
          setRenamings(data ?? []);
          setRenamingsList(
            mapValues(
              keyBy(data ?? [], "exercise_original"),
              "exercise_renamed",
            ),
          );
        }),
    [user.id],
  );

  useEffect(() => {
    refreshRenamings();
  }, [refreshRenamings]);

  return (
    <Renamings.Provider
      value={{
        renamings,
        renamingsList,
        refreshRenamings,
      }}
    >
      {children}
    </Renamings.Provider>
  );
};
