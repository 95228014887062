import { sample, sampleSize, shuffle, xor } from "lodash";

import { Tables } from "~/supabase.types.ts";

export interface GuessWordWithThemes {
  theme: string;
  word: string;
  success?: boolean;
}

export const getAllWords = (themes: Tables<"boites_mots_themes">[]) => {
  const allWords: GuessWordWithThemes[] = [];

  themes.forEach((theme) =>
    theme.words.forEach((word) => allWords.push({ theme: theme.theme, word })),
  );

  return allWords;
};

export const getGuessWordWithThemes = (
  themes: Tables<"boites_mots_themes">[],
) => {
  const allThemes = themes.map((theme) => theme.theme);
  const allWords = shuffle(getAllWords(themes));
  const randomWord = sample(allWords);

  if (!randomWord) {
    return null;
  }

  return {
    guess: randomWord,
    themes: [
      randomWord.theme,
      ...sampleSize(xor([randomWord.theme], allThemes), 5),
    ].sort(),
  };
};
