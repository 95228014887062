import { FC, useState } from "react";

import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Role } from "~/role.ts";
import { DistanceView } from "~/routes/cm2/DistanceView.tsx";

export type CM2_APP_MODE = "distance" | "distanceModulo";

export const Cm2: FC = () => {
  const [mode, setMode] = useState<CM2_APP_MODE>("distance");

  return (
    <Wrapper
      requireRole={Role.CM2}
      header={
        <div className="flex items-center justify-between">
          <h1 className="w-full flex justify-between items-center">
            <span className="text-3xl font-bold underline">CM2</span>
            <div className="flex items-center justify-center gap-3">
              <ScoreButton
                label="CM2"
                exercise="cm2"
                variant={mode}
                filter={() => true}
              />
              <Select
                value={mode}
                onValueChange={(val) => setMode(val as CM2_APP_MODE)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner un mode…" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="distance">Distance</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </h1>
        </div>
      }
    >
      <DistanceView mode={mode} />
    </Wrapper>
  );
};
