import { FC } from "react";

import { Wrapper } from "~/components/Wrapper.tsx";

export const Privacy: FC = () => {
  return (
    <Wrapper
      header={
        <h1 className="text-2xl sm:text-3xl font-bold underline">
          Politique de confidentialité
        </h1>
      }
    >
      <div className="flex flex-col gap-8 leading-8">
        <p>
          Nous accordons une grande importance à la confidentialité de vos
          données et nous nous engageons à les protéger conformément aux lois
          applicables.
        </p>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">Collecte des données</h2>
          <p>
            Nous recueillons uniquement les données nécessaires pour vous
            fournir nos services, à savoir les scores que vous soumettez lors de
            l'utilisation de nos outils, vos préférences d'affichage et données
            de compte utilisateur.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">Utilisation des données</h2>
          <p>
            Les données que vous soumettez sont utilisés exclusivement pour
            générer des tableaux et graphiques personnalisés, qui vous
            permettent de suivre vos performances et votre progression.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">Partage des données</h2>
          <p>
            Nous ne partageons en aucun cas vos données avec des tiers. Vos
            informations sont strictement confidentielles et ne sont utilisées
            que dans le cadre des services que nous proposons.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">Conservation des données</h2>
          <p>
            Vos données sont conservées aussi longtemps que nécessaire pour vous
            fournir nos services. Si vous souhaitez que vos données soient
            supprimées, vous pouvez en faire la demande à tout moment en nous
            contactant.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">Sécurité des données</h2>
          <p>
            Nous mettons en œuvre des mesures techniques et organisationnelles
            appropriées pour protéger vos données contre toute perte,
            utilisation abusive, ou accès non autorisé.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">Droits des utilisateurs</h2>
          <p>
            Vous disposez d'un droit d'accès, de rectification, et de
            suppression de vos données personnelles. Pour exercer ces droits,
            veuillez nous contacter via{" "}
            <a
              href="https://forms.fillout.com/t/vJdHp6jTEqus"
              className="underline hover:no-underline"
              target="_blank"
            >
              ce lien
            </a>
            .
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">
            Modifications de la politique de confidentialité
          </h2>
          <p>
            Nous nous réservons le droit de modifier cette politique de
            confidentialité à tout moment. Toute modification sera publiée sur
            cette page. Nous vous encourageons à consulter régulièrement cette
            page pour être informé de tout changement.
          </p>
        </div>

        <p>Dernière mise à jour : 16/10/2024.</p>
      </div>
    </Wrapper>
  );
};
