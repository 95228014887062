import { FC } from "react";

import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

export const DimensionHeader: FC = () => {
  return (
    <div className="-mt-2 flex items-center gap-2">
      <div className="w-2/12" />
      <div className="grow flex justify-evenly text-xs text-slate-400 tabular-nums font-medium opacity-60">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((stanine) => (
          <p
            key={`dim-${stanine}`}
            className={textClassNameFromStanineClass(stanine)}
          >
            {stanine}
          </p>
        ))}
      </div>
      <div className="pl-2 tabular-nums opacity-0">0.0</div>
    </div>
  );
};
