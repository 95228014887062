import { differenceInDays } from "date-fns";
import { last, maxBy, meanBy, minBy } from "lodash";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { ExerciseResult } from "~/routes/suivi/types/ExerciseResult.ts";

export const getResultFromExerciseAttempts = (
  results: ExerciseAttempt[],
): ExerciseResult => ({
  averageStanineClass: results.length ? meanBy(results, "stanineClass") : 0,
  averagePercentScore: results.length
    ? Math.round(meanBy(results, "percentScore"))
    : 0,
  min: minBy(results, "stanineClass")?.stanineClass ?? 0,
  max: maxBy(results, "stanineClass")?.stanineClass ?? 0,
  count: results.length,
  distance: last(results)
    ? differenceInDays(new Date(), last(results)!.at)
    : undefined,
});
