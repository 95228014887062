export type StatsColumn =
  | "distance"
  | "general"
  | "7days"
  | "3days"
  | "today"
  | "lastAttempt";

export const DEFAULT_STATS_COLUMNS: StatsColumn[] = [
  "distance",
  "general",
  "7days",
  "3days",
  "lastAttempt",
];
