import { FC } from "react";

import { getDailyRandomItem } from "~/routes/root/utils.ts";

const quotes = [
  "Le ciel est la limite, vise plus haut.",
  "Laisse tes rêves prendre leur envol.",
  "Chaque échec est une étape vers le succès.",
  "N'abandonne jamais, les grandes choses prennent du temps.",
  "Le succès est le résultat de la persévérance et du travail acharné.",
  "Les turbulences ne durent pas éternellement, garde le cap.",
  "Crois en toi, tu es plus près que tu ne le penses.",
  "Les défis sont des opportunités déguisées.",
  "Ta détermination est ta plus grande force.",
  "Chaque jour de préparation te rapproche de ton rêve.",
  "Le vol le plus difficile est souvent celui qui mène à la destination la plus belle.",
  "Travaille dur en silence, laisse le succès faire du bruit.",
  "La seule limite à notre réalisation de demain est nos doutes d'aujourd'hui.",
  "Courage, c’est de faire ce qui est difficile malgré la peur.",
  "Les étoiles ne peuvent briller sans obscurité.",
  "La persévérance surmonte les obstacles.",
  "Chaque pas que tu fais est un pas de plus vers ton rêve.",
  "Ne compte pas les jours, fais que les jours comptent.",
  "Le seul endroit où le succès vient avant le travail est dans le dictionnaire.",
  "Ton futur est créé par ce que tu fais aujourd'hui, pas demain.",
  "Ce qui est important, ce n'est, ni d'être optimiste, ni pessimiste, mais d'être déterminé.",
];

export const Footer: FC = () => (
  <footer className="pb-8 w-10/12 sm:w-full">
    <div className="font-serif text-2xl text-slate-300 text-center font-normal italic space-y-2 ">
      <p>"{getDailyRandomItem(quotes)}"</p>
    </div>
  </footer>
);
