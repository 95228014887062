export const textClassNameFromStanineClass = (stanineClass: number): string => {
  if (stanineClass < 2) {
    return "text-red-500";
  } else if (stanineClass < 3) {
    return "text-orange-500";
  } else if (stanineClass < 4) {
    return "text-amber-500";
  } else if (stanineClass < 5) {
    return "text-yellow-500";
  } else if (stanineClass < 6) {
    return "text-lime-500";
  } else if (stanineClass < 7) {
    return "text-green-500";
  } else if (stanineClass < 8) {
    return "text-emerald-500";
  } else if (stanineClass < 9) {
    return "text-blue-500";
  } else if (stanineClass < 10) {
    return "text-blue-800";
  } else {
    return "text-black";
  }
};
