export const sortExerciseNames = (
  allExerciseNames: string[],
  exerciseNames: string[],
  useCustomSort: boolean = false,
) => {
  if (exerciseNames.length === 0) {
    return allExerciseNames.sort();
  }

  const filteredExercises = allExerciseNames.filter((r) =>
    exerciseNames.includes(r),
  );

  if (!useCustomSort) {
    return filteredExercises.sort();
  }

  const nameOrderMap = new Map(
    exerciseNames.map((name, index) => [name, index]),
  );

  return filteredExercises.sort((a, b) => {
    const indexA = nameOrderMap.get(a) ?? exerciseNames.length;
    const indexB = nameOrderMap.get(b) ?? exerciseNames.length;

    return indexA - indexB;
  });
};
