import { HistoryIcon } from "lucide-react";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";

export const ExercisesResultsHeaderEmpty: FC = () => {
  return (
    <div className="w-[64px] sm:flex flex-col hidden last-of-type:flex items-end pr-3 justify-center">
      <Tooltip delayDuration={0}>
        <TooltipTrigger className="cursor-default">
          <HistoryIcon className="h-5 w-5" strokeWidth={1.5} />
        </TooltipTrigger>
        <TooltipContent>Dernier résultat</TooltipContent>
      </Tooltip>
    </div>
  );
};
