import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import clsx from "clsx";
import { MoveIcon } from "lucide-react";
import { FC, useEffect, useState } from "react";

import { ExercisesResultsDetails } from "~/routes/suivi/components/ExercisesResultsDetails.tsx";
import { ExercisesResultsFlagExercisePopover } from "~/routes/suivi/components/ExercisesResultsFlagExercisePopover.tsx";
import { ExercisesResultsLastAttempt } from "~/routes/suivi/components/ExercisesResultsLastAttempt.tsx";
import { ExercisesResultsPopover } from "~/routes/suivi/components/ExercisesResultsPopover.tsx";
import { ExercisesResultsRenameExercise } from "~/routes/suivi/components/ExercisesResultsRenameExercise.tsx";
import { ExercisesResultsResults } from "~/routes/suivi/components/ExercisesResultsResults.tsx";
import { ExercisesResultsStatus } from "~/routes/suivi/components/ExercisesResultsStatus.tsx";
import { useAggregatedResultsFromDate } from "~/routes/suivi/hooks/useAggregatedResultsFromDate.ts";
import { useFlaggedExercises } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { StatsColumn } from "~/routes/suivi/types/StatsColumn.ts";
import { supabase } from "~/supabase.ts";

interface ExercisesResultsRowsProps {
  exercises: ExerciseResults[];
  hasColumn: (columnName: StatsColumn) => boolean;
  withDrag: boolean;
  group: string;
}

export const ExercisesResultsRows: FC<ExercisesResultsRowsProps> = ({
  exercises = [],
  hasColumn,
  withDrag,
  group,
}) => {
  const { settings } = useSettings();
  const { renamingsList } = useRenamings();
  const { groups, refreshGroups } = useGroups();
  const { flaggedExercisesList } = useFlaggedExercises();

  const aggregatedResultsFromDate = useAggregatedResultsFromDate();

  const [items, setItems] = useState(exercises);

  useEffect(() => {
    setItems(exercises);
  }, [exercises]);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = Array.from(exercises);
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    const existingGroup = groups.find((g) => g.name === group);
    if (existingGroup) {
      supabase
        .from("suivi_groups")
        .update({
          exercises: updatedItems.map((i) => i.name),
          use_custom_order: true,
        })
        .eq("id", existingGroup.id)
        .then(refreshGroups);
    }

    setItems(updatedItems);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="items">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="flex flex-col"
          >
            {items.map((exercise, index) => (
              <Draggable
                key={exercise.name}
                index={index}
                draggableId={exercise.name}
                isDragDisabled={!withDrag}
              >
                {(provided, snapshot) => (
                  <div
                    key={exercise.name}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={clsx(
                      "group",
                      snapshot.isDragging
                        ? "bg-sky-200 rounded border border-sky-300"
                        : index % 2 === 0
                          ? "bg-slate-50 hover:bg-sky-50"
                          : "hover:bg-sky-50",
                    )}
                  >
                    <div
                      className={clsx(
                        "flex items-center justify-between pl-2 sm:pl-3 pr-2 py-1.5",
                      )}
                      style={
                        flaggedExercisesList[exercise.name]
                          ? {
                              backgroundColor:
                                flaggedExercisesList[exercise.name].color ?? "",
                            }
                          : {}
                      }
                    >
                      <div className="flex grow min-w-56 items-center gap-2.5 text-sm">
                        {hasColumn("distance") && (
                          <ExercisesResultsStatus
                            distance={exercise.results.all.distance}
                          />
                        )}
                        <ExercisesResultsPopover
                          exerciseName={exercise.name}
                          results={aggregatedResultsFromDate.filter((r) =>
                            Object.prototype.hasOwnProperty.call(
                              r,
                              exercise.name,
                            ),
                          )}
                        />
                        <div />
                        <ExercisesResultsDetails
                          exerciseName={exercise.name}
                          customName={renamingsList[exercise.name]}
                        />
                        <div />
                        <ExercisesResultsRenameExercise
                          exerciseName={exercise.name}
                        />
                        <ExercisesResultsFlagExercisePopover
                          exerciseName={exercise.name}
                        />
                        {withDrag && (
                          <div
                            {...provided.dragHandleProps}
                            className="hidden sm:group-hover:block cursor-move"
                          >
                            <MoveIcon className="w-4 h-4 text-slate-300 hover:text-slate-500" />
                          </div>
                        )}
                      </div>
                      <div className="flex flex-none shrink-0 items-center justify-end">
                        {hasColumn("general") && (
                          <ExercisesResultsResults
                            result={exercise.results.all}
                            display={settings?.table_display ?? "stanine"}
                          />
                        )}
                        {hasColumn("7days") && (
                          <ExercisesResultsResults
                            result={exercise.results.last7Days}
                            display={settings?.table_display ?? "stanine"}
                          />
                        )}
                        {hasColumn("3days") && (
                          <ExercisesResultsResults
                            result={exercise.results.last3Days}
                            display={settings?.table_display ?? "stanine"}
                          />
                        )}
                        {hasColumn("today") && (
                          <ExercisesResultsResults
                            result={exercise.results.last0Days}
                            display={settings?.table_display ?? "stanine"}
                          />
                        )}
                        {hasColumn("lastAttempt") && (
                          <ExercisesResultsLastAttempt
                            lastAttempt={exercise.lastAttempt}
                            display={settings?.table_display ?? "stanine"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ExercisesResultsRows;
