import { User } from "@supabase/supabase-js";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
} from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useSupabase } from "~/providers/SupabaseProvider.tsx";
import { Role } from "~/role.ts";

interface CurrentUserContextType {
  user: User;
  accessToken: string;
  canAccess: (role: Role) => boolean;
}

const CurrentUserContext = createContext<CurrentUserContextType | undefined>(
  undefined,
);

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);

  if (context === undefined) {
    throw new Error("useCurrentUser must be used within a CurrentUserProvider");
  }

  return context;
};

interface CurrentUserProviderProps {
  children: ReactNode;
}

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  children,
}) => {
  const { session } = useSupabase();

  const canAccess = useCallback(
    (role: Role): boolean => {
      const roles = session?.user.app_metadata.roles;

      if (!roles || roles.length === 0) {
        return false;
      }

      if (roles.includes(Role.Admin)) {
        return true;
      }

      return roles.includes(role);
    },
    [session?.user.app_metadata],
  );

  if (!session?.user) {
    return <LayoutLoading />;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        user: session.user,
        accessToken: session.access_token,
        canAccess,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
