import { FC } from "react";

import { TitleStatusEplTest } from "~/routes/suivi/components/TitleStatusEplTest.tsx";
import { TitleStatusPilotest } from "~/routes/suivi/components/TitleStatusPilotest.tsx";
import { UploadCsvBox } from "~/routes/suivi/components/UploadCsvBox.tsx";
import { usePilotestAttempts } from "~/routes/suivi/hooks/usePilotestAttempts.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const Title: FC = () => {
  const { settings } = useSettings();
  const { savePilotestAttempts } = usePilotestAttempts();
  const { hasPilotestAutoSyncEnabled } = usePilotestCredentials();

  if (!settings) {
    return (
      <header className="flex items-center justify-between mb-2">
        <div>
          <h1 className="text-3xl space-x-3 font-bold underline">Suivi</h1>
        </div>
      </header>
    );
  }

  return (
    <header className="flex items-center justify-between mb-4">
      <div>
        <h1 className="text-3xl space-x-3 font-bold underline">Suivi</h1>
        <div className="mt-3 flex flex-col space-y-1.5">
          <TitleStatusPilotest />
          <TitleStatusEplTest />
        </div>
      </div>
      {!hasPilotestAutoSyncEnabled && (
        <UploadCsvBox
          onResults={async (source, results) => {
            if (!results) {
              return;
            }
            await savePilotestAttempts(source, results);
          }}
        />
      )}
    </header>
  );
};
