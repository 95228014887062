import { random, sample } from "lodash";

export interface IndexedElement {
  element: string;
  isCorrect: boolean;
  index: number;
  status?: "won" | "lost";
}

const PRIME_NUMBERS = [
  2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71,
  73, 79, 83, 89, 97,
];

export const getElements = (): IndexedElement[] =>
  PRIME_NUMBERS.map((primeNumber, index) => ({
    element: primeNumber.toString(),
    isCorrect: true,
    index,
  }));

export const getElementsFromList = (list: string): IndexedElement[] =>
  list
    .split(",")
    .filter((el) => !!el)
    .map((primeNumber, index) => ({
      element: primeNumber,
      isCorrect: true,
      index,
    }));

export const isPrime = (num: number) => {
  for (let i = 2, s = Math.sqrt(num); i <= s; i++) {
    if (num % i === 0) return false;
  }

  return num > 1;
};

export const getElementsToGuess = (limit = 30, elementsList: string) => {
  const list = elementsList.split(",");
  const elements: IndexedElement[] = [];

  for (let index = 0; index < limit; index++) {
    const number =
      random(0, 100, false) <= 50
        ? (sample(list) ?? "")
        : random(0, 100, false).toString();

    elements.push({
      index,
      element: number,
      isCorrect: isPrime(Number(number)),
    });
  }

  return elements;
};
