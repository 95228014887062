import { FC } from "react";

import { Wrapper } from "~/components/Wrapper.tsx";

const cultureLinks = [
  {
    link: "https://www.airfleets.fr/flottecie/Air%20France.htm",
    description: "Flotte Air France",
  },
  {
    link: "https://corporate.airfrance.com/a-propos-air-france/chiffres-cles",
    description: "Chiffres clés Air France",
  },
  {
    link: "https://www.flightconnections.com/fr/vols-air-france-af",
    description: "Connexions Air France",
  },
  {
    link: "https://www.flightconnections.com/fr/vols-transavia-to",
    description: "Connexions Transavia",
  },
  {
    link: "https://blog.pilotest.com/wp-content/uploads/2019/11/test_culture_aeronautique_cadets_air_france_mars_2018-1.pdf",
    description: "Annales Cadets Air France 2018",
  },
  {
    link: "https://blog.pilotest.com/wp-content/uploads/2019/11/test_culture_aeronautique_cadets_air_france_janvier_2019.pdf",
    description: "Annales Cadets Air France 2019",
  },
  {
    link: "https://discord.gg/krsNR6NQtt",
    description: "Discord Cadets Air France",
  },
];

export const Liens: FC = () => {
  return (
    <Wrapper header={<h1 className="text-3xl font-bold underline">Liens</h1>}>
      <section>
        <ul className="list-disc space-y-4 max-w-xl">
          {cultureLinks.map((link) => (
            <li key={link.link}>
              <p className="font-medium">{link.description}</p>
              <p className="text-sm underline hover:no-underline text-blue-600 mt-1 truncate">
                <a href={link.link} target="_blank" rel="noreferrer">
                  {link.link}
                </a>
              </p>
            </li>
          ))}
        </ul>
      </section>
    </Wrapper>
  );
};
