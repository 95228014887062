import { FC } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

function errorMessage(error: unknown): string {
  if (isRouteErrorResponse(error)) {
    return `${error.status} ${error.statusText}`;
  } else if (error instanceof Error) {
    return error.message;
  } else if (typeof error === "string") {
    return error;
  } else {
    console.error(error);
    return "Erreur inconnue";
  }
}

export const ErrorPage: FC = () => {
  const error = useRouteError();

  console.error(error);

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center space-y-4">
      <h1 className="text-5xl font-semibold">Oops ! 😵‍💫</h1>
      <p>{errorMessage(error)}</p>
      <p className="pt-4">
        <a href="/" className="underline hover:no-underline">
          Accueil
        </a>
      </p>
    </div>
  );
};
