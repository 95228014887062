import { isBefore, isSameDay, sub } from "date-fns";
import { groupBy, last } from "lodash";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { ExerciseResult } from "~/routes/suivi/types/ExerciseResult.ts";
import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { getResultFromExerciseAttempts } from "~/routes/suivi/utils/getResultFromExerciseAttempts.ts";
import { htmlDateToDate } from "~/routes/suivi/utils/htmlDateToDate.ts";

const calculateResultsForTimeframe = (
  attempts: ExerciseAttempt[],
  days: number,
) => {
  const dateLimit = sub(new Date(), { days });

  return getResultFromExerciseAttempts(
    attempts.filter((res) =>
      days > 0 ? isBefore(dateLimit, res.at) : isSameDay(new Date(), res.at),
    ),
  );
};

export const getExerciseResultsFromExerciseAttempts = (
  attempts: ExerciseAttempt[],
  fromDate: string,
  timeframes: number[] = [0, 3, 7], // Default timeframes to 3 and 7 days if not provided
): ExerciseResults[] => {
  const attemptsGroupedByExercise = groupBy(attempts, "name");

  // Sort attempts within each exercise group by date
  Object.values(attemptsGroupedByExercise).forEach((group) => {
    group.sort((a, b) => a.at.getTime() - b.at.getTime());
  });

  const attemptsFromAllTimes = { ...attemptsGroupedByExercise };

  // Filter attempts by the provided fromDate
  const fromDateTest = htmlDateToDate(fromDate);
  if (fromDateTest) {
    Object.keys(attemptsGroupedByExercise).forEach((exercise) => {
      attemptsGroupedByExercise[exercise] = attemptsGroupedByExercise[
        exercise
      ].filter((r) => r.at.getTime() >= fromDateTest.getTime());
    });
  }

  return Object.keys(attemptsGroupedByExercise).map((test) => {
    const allResults = getResultFromExerciseAttempts(
      attemptsGroupedByExercise[test],
    );

    const timeframeResults = timeframes.reduce(
      (acc, days) => {
        return {
          ...acc,
          [`last${days}Days`]: calculateResultsForTimeframe(
            attemptsGroupedByExercise[test],
            days,
          ),
        };
      },
      {} as Record<string, ExerciseResult>,
    );

    const lastAttempt = last(attemptsFromAllTimes[test]);

    return {
      name: test,
      ...(lastAttempt
        ? {
            lastAttempt: {
              stanineClass: lastAttempt.stanineClass,
              percentScore: lastAttempt.percentScore,
            },
          }
        : {}),
      results: {
        all: allResults,
        ...timeframeResults,
      },
    };
  });
};
