import { FC, useMemo, useState } from "react";

import { Input } from "~/components/ui/input.tsx";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs.tsx";
import { GroupsDrawer } from "~/routes/suivi/components/GroupsDrawer.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const SUIVI_DEFAULT_GROUP = "INTERNAL|NO_GROUP";

interface NavigationProps {
  selectedGroup: string;
  onSelectGroup: (group: string) => void;
}

export const Navigation: FC<NavigationProps> = ({
  selectedGroup,
  onSelectGroup,
}) => {
  const { settings, saveSettings } = useSettings();
  const { groups } = useGroups();

  const [editGroups, setEditGroups] = useState(false);

  const tabs = useMemo(
    () => [SUIVI_DEFAULT_GROUP, ...(groups?.map((g) => g.name) ?? [])],
    [groups],
  );

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <Tabs value={selectedGroup} onValueChange={onSelectGroup}>
            <TabsList>
              {tabs.map((tab) => (
                <TabsTrigger value={tab} key={`suivi-${tab}`} className="px-6">
                  {tab === SUIVI_DEFAULT_GROUP ? "Tous" : tab}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <button
            className="hidden sm:flex items-center text-xs text-slate-500 underline hover:no-underline space-x-2 px-4"
            onClick={() => setEditGroups(!editGroups)}
          >
            <span>
              {groups.length === 0
                ? "Ajouter un groupe"
                : "Modifier les groupes"}
            </span>
          </button>
        </div>
        <div className="hidden sm:flex items-center space-x-2">
          <label className="text-slate-500 text-sm">Depuis :</label>
          <Input
            type="date"
            id="display-date"
            name="display-date"
            className="w-[148px]"
            value={settings?.display_from_date ?? ""}
            onFocus={(evt) => evt.currentTarget.showPicker()}
            onChange={(e) =>
              saveSettings({
                display_from_date:
                  e.currentTarget.value !== "" ? e.currentTarget.value : null,
              })
            }
          />
        </div>
      </div>
      <GroupsDrawer open={editGroups} onDismiss={() => setEditGroups(false)} />
    </div>
  );
};
