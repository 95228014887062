import { randomWithExclusions } from "~/utils/randomWithExclusions.ts";

export interface FormulaValues {
  min: number;
  max: number;
}

export interface FormulaArgs {
  template: string;
  values: Record<string, FormulaValues>;
}

export class Formula {
  template: string;
  formula: string;
  result: number;

  constructor({ template, values }: FormulaArgs) {
    this.template = template;
    this.formula = template;

    // Replace x, y, z values with random numbers within bounds…
    Object.keys(values).forEach((value) => {
      this.formula = this.formula.replace(
        value,
        String(
          randomWithExclusions(values[value].min, values[value].max, [0, 1]),
        ),
      );
    });

    this.result = eval(this.formula);

    // For display purposes only, we want to tweak a bit the original formula…
    this.formula = this.formula
      .replaceAll("+ -", "- ")
      .replaceAll("- -", "+ ")
      .replaceAll(" ** 2", "²")
      .replaceAll(" ** 3", "³")
      .replaceAll(" ** 4", "⁴")
      .replaceAll(" ** 5", "⁵")
      .replaceAll(" * ", " × ");
  }
}
