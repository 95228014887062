import * as Sentry from "@sentry/react";
import { setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "~/index.css";
import router from "~/router.tsx";

setDefaultOptions({ locale: fr });

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://5e75ee8e807083fd9d17fdc1685a8f8f@o4508278809559040.ingest.de.sentry.io/4508278811656272",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/api-kd\.valentin\.xyz/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />,
);
