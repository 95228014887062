import { FC, useState } from "react";

import { TitleLabel } from "~/components/TitleLabel.tsx";
import { isResultInteger } from "~/routes/mathematiques/utils.ts";

export const IntegerDivision: FC = () => {
  const [expression, setExpression] = useState("");
  const [showResult, setShowResult] = useState(false);

  return (
    <div>
      <h3 className="text-xl font-semibold">Division entière</h3>
      <p>Détermine si une division tombe juste.</p>
      <div className="grid grid-cols-1 gap-4 mt-4">
        <div>
          <TitleLabel className="mb-1">Division</TitleLabel>
          <div className="flex items-center gap-4">
            <input
              type="string"
              className="border-2 p-2 transition-colors duration-300 ease-in-out w-full rounded text-center text-2xl font-mono tracking-widest"
              placeholder="120/6"
              onChange={(e) => {
                if (showResult) {
                  setShowResult(false);
                }

                setExpression(e.currentTarget.value);
              }}
              value={expression}
            />
            <span className="text-3xl w-10" title="Voir le résultat">
              {isResultInteger(expression) === true
                ? "✅"
                : isResultInteger(expression) === false
                  ? "❌"
                  : ""}
            </span>
          </div>
          <button
            className="text-xs mt-1 underline hover:no-underline cursor-pointer"
            onClick={() => setShowResult(true)}
          >
            Voir le résultat
          </button>
          {showResult && (
            <>
              <h4 className="mb-2 flex justify-between mt-4">
                <span className="uppercase font-semibold text-sm">
                  Résultat
                </span>
              </h4>
              <div className="overflow-hidden rounded-lg bg-slate-50 h-14">
                <div className="px-3 py-3 h-full flex justify-center items-center text-2xl tabular-nums">
                  {new Intl.NumberFormat("fr-FR", {
                    maximumFractionDigits: 10,
                  }).format(eval(expression))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
