import { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Title } from "~/routes/suivi/components/Title.tsx";
import { FlaggedExercisesProvider } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { GroupsProvider } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { ImportsProvider } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { PilotestCredentialsProvider } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { RenamingsProvider } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { SettingsProvider } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const Suivi: FC = () => (
  <SettingsProvider>
    <PilotestCredentialsProvider>
      <ImportsProvider>
        <GroupsProvider>
          <FlaggedExercisesProvider>
            <RenamingsProvider>
              <div className="flex flex-col min-h-screen max-w-7xl mx-auto px-4 sm:px-6 py-4 sm:py-8">
                <Title />
                <main className="flex-1 mt-4">
                  <Suspense
                    fallback={
                      <div className="flex items-center justify-center mt-16">
                        <div className="dots-loader" />
                      </div>
                    }
                  >
                    <Outlet />
                  </Suspense>
                </main>
              </div>
            </RenamingsProvider>
          </FlaggedExercisesProvider>
        </GroupsProvider>
      </ImportsProvider>
    </PilotestCredentialsProvider>
  </SettingsProvider>
);
