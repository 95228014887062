import { Switch } from "@headlessui/react";
import { FC } from "react";

interface ToggleShortProps {
  label: string;
  toggled: boolean;
  onToggle: (updatedValue: boolean) => void;
}

export const ToggleShort: FC<ToggleShortProps> = ({
  label,
  toggled,
  onToggle,
}) => (
  <div className="flex items-center justify-between">
    <span className="text-sm font-medium leading-6 text-slate-900">
      {label}
    </span>
    <Switch
      checked={toggled}
      onChange={onToggle}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
    >
      <span className="sr-only">{label}</span>
      <span
        aria-hidden="true"
        className="pointer-events-none absolute h-full w-full rounded-md bg-white"
      />
      <span
        aria-hidden="true"
        className="pointer-events-none absolute mx-auto h-4 w-9 rounded-full bg-slate-200 transition-colors duration-200 ease-in-out group-data-[checked]:bg-blue-600"
      />
      <span
        aria-hidden="true"
        className="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-slate-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
      />
    </Switch>
  </div>
);
