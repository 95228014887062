import { FC, useEffect, useState } from "react";

export const NumberDisplay: FC<{
  numbers: number[];
  onComplete: () => void;
  speed: number;
}> = ({ numbers, speed, onComplete }) => {
  const [currentNumberIndex, setCurrentNumberIndex] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (currentNumberIndex < numbers.length) {
      const timer = setTimeout(() => {
        setCurrentNumberIndex((prevIndex) => prevIndex + 1);
      }, speed * 1000);

      return () => clearTimeout(timer);
    } else {
      setIsCompleted(true);
      onComplete();
    }
  }, [currentNumberIndex, numbers.length, onComplete, speed]);

  return (
    <div className="flex justify-center items-center h-20">
      {isCompleted ? null : (
        <span
          key={`${currentNumberIndex}-${numbers[currentNumberIndex]}`}
          className="text-5xl font-semibold tabular-nums fade-in"
        >
          {numbers[currentNumberIndex]}
        </span>
      )}
    </div>
  );
};
