import clsx from "clsx";
import { FC, ReactNode } from "react";

interface FigureCommonsProps {
  highlight?: boolean;
  withCaption?: string;
  onClick?: () => void;
  size?: "xs" | "sm" | "md" | "lg";
}

interface FigureWrapperProps extends FigureCommonsProps {
  className?: string;
  children: ReactNode;
}

const FigureWrapper: FC<FigureWrapperProps> = ({
  children,
  className,
  withCaption,
  onClick,
  size = "md",
}) => {
  const sizeClassName =
    size === "xs"
      ? "w-12 h-12 sm:w-16 sm:h-16"
      : size === "sm"
        ? "w-20 h-20"
        : size === "md"
          ? "w-20 h-20 sm:w-24 sm:h-24"
          : size === "lg"
            ? "w-20 h-20 sm:w-32 sm:h-32"
            : "";

  return (
    <div
      className={clsx("relative", onClick && "cursor-pointer")}
      onClick={onClick}
    >
      <div className={clsx(sizeClassName, className)}>{children}</div>
      {withCaption && (
        <div className="select-none absolute flex items-center justify-center top-0 text-5xl font-bold text-blue-400 w-full h-full">
          {withCaption}
        </div>
      )}
    </div>
  );
};

export interface SquareProps extends FigureCommonsProps {
  empty?: boolean;
}

const Square: FC<SquareProps> = ({
  empty = false,
  highlight,
  withCaption,
  onClick,
  size,
}) => (
  <FigureWrapper withCaption={withCaption} onClick={onClick} size={size}>
    <div
      className={clsx(
        "w-full h-full",
        highlight && "bg-green-400",
        !empty && "bg-black",
      )}
    />
  </FigureWrapper>
);

export interface RectangleProps extends FigureCommonsProps {
  orientation?: "top" | "right" | "bottom" | "left";
}

export const Rectangle: FC<RectangleProps> = ({
  orientation = "left",
  highlight,
  withCaption,
  onClick,
  size,
}) => {
  const wrapperClassName =
    orientation === "right"
      ? "flex justify-end"
      : orientation === "bottom"
        ? "flex items-end"
        : "";

  const className =
    orientation === "top"
      ? "w-full h-1/2"
      : orientation === "right"
        ? "w-1/2 h-full"
        : orientation === "bottom"
          ? "w-full h-1/2"
          : orientation === "left"
            ? "w-1/2 h-full"
            : "";

  return (
    <FigureWrapper
      className={wrapperClassName}
      withCaption={withCaption}
      onClick={onClick}
      size={size}
    >
      <div
        className={clsx(className, highlight ? "bg-green-400" : "bg-black")}
      />
    </FigureWrapper>
  );
};

export interface TriangleProps extends FigureCommonsProps {
  orientation?: "topRight" | "bottomRight" | "bottomLeft" | "topLeft";
}

const Triangle: FC<TriangleProps> = ({
  orientation = "topRight",
  highlight,
  withCaption,
  onClick,
  size,
}) => {
  const className =
    orientation === "topRight"
      ? "-rotate-90"
      : orientation === "bottomRight"
        ? ""
        : orientation === "bottomLeft"
          ? "rotate-90"
          : orientation === "topLeft"
            ? "rotate-180"
            : "";

  const sizeClassName =
    size === "xs"
      ? "border-t-[48px] border-r-[48px] sm:border-t-[64px] sm:border-r-[64px]"
      : size === "sm"
        ? "border-t-[80px] border-r-[80px]"
        : size === "md"
          ? "border-t-[80px] border-r-[80px] sm:border-t-[96px] sm:border-r-[96px]"
          : size === "lg"
            ? "border-t-[80px] border-r-[80px] sm:border-t-[128px] sm:border-r-[128px]"
            : "";

  return (
    <FigureWrapper
      className="flex items-center justify-center"
      withCaption={withCaption}
      onClick={onClick}
      size={size}
    >
      <div
        className={clsx(
          "w-0 h-0 border-t-transparent",
          sizeClassName,
          className,
          highlight ? "border-r-green-400" : "border-r-black",
        )}
      />
    </FigureWrapper>
  );
};

export const RectangleTop = (props: RectangleProps) => (
  <Rectangle orientation="top" {...props} />
);

export const RectangleRight = (props: RectangleProps) => (
  <Rectangle orientation="right" {...props} />
);

export const RectangleBottom = (props: RectangleProps) => (
  <Rectangle orientation="bottom" {...props} />
);

export const RectangleLeft = (props: RectangleProps) => (
  <Rectangle orientation="left" {...props} />
);

export const TriangleTopRight = (props: TriangleProps) => (
  <Triangle orientation="topRight" {...props} />
);

export const TriangleBottomRight = (props: TriangleProps) => (
  <Triangle orientation="bottomRight" {...props} />
);

export const TriangleBottomLeft = (props: TriangleProps) => (
  <Triangle orientation="bottomLeft" {...props} />
);

export const TriangleTopLeft = (props: TriangleProps) => (
  <Triangle orientation="topLeft" {...props} />
);

export const SquareEmpty = (props: SquareProps) => <Square empty {...props} />;

export const SquareFull = (props: SquareProps) => <Square {...props} />;
