import { EditIcon, TrashIcon } from "lucide-react";
import { FC, useState } from "react";

import { Badge } from "~/components/Badge.tsx";
import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import { Textarea } from "~/components/ui/textarea.tsx";
import { Tables } from "~/supabase.types.ts";

interface BoitesMotsThemeProps {
  theme: Tables<"boites_mots_themes">;
  onUpdateTheme: (
    id: Tables<"boites_mots_themes">["id"],
    theme: string,
    words: string[],
  ) => void;
  onDeleteTheme: (id: Tables<"boites_mots_themes">["id"]) => void;
}

export const BoitesMotsTheme: FC<BoitesMotsThemeProps> = ({
  theme,
  onUpdateTheme,
  onDeleteTheme,
}) => {
  const [editMode, setEditMode] = useState(false);

  const [themeDraft, setThemeDraft] = useState(theme.theme);
  const [wordsDraft, setWordsDraft] = useState(theme.words.join(", "));

  return (
    <div className="relative border rounded px-4 pt-3 pb-3 flex flex-col gap-3">
      <div className="flex gap-3">
        <div className="w-1/3">
          <label className="block text-xs uppercase text-slate-400 font-medium tracking-wider mb-1">
            Thème :
          </label>
          {editMode ? (
            <Input
              value={themeDraft}
              onChange={(evt) => setThemeDraft(evt.currentTarget.value)}
            />
          ) : (
            <p>{theme.theme}</p>
          )}
        </div>
        <div className="w-2/3">
          <label className="block text-xs uppercase text-slate-400 font-medium tracking-wider mb-1">
            Mots :
          </label>
          {editMode ? (
            <>
              <Textarea
                value={wordsDraft}
                onChange={(evt) => setWordsDraft(evt.currentTarget.value)}
              />
              <small className="text-xs text-slate-500">
                Lister tous les mots de ce thème, séparés par une virgule.
              </small>
            </>
          ) : (
            <div className="flex mt-2 flex-wrap gap-2">
              {theme.words.map((word) => (
                <Badge key={`word-${theme.theme}-${word}`} size="lg">
                  {word}
                </Badge>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="absolute top-0 -right-8 flex flex-col gap-2">
        <Button
          size="icon-sm"
          variant="outline"
          onClick={() => {
            if (editMode) {
              onUpdateTheme(
                theme.id,
                themeDraft,
                wordsDraft.split(",").map((word) => word.trim()),
              );
            }
            setEditMode(!editMode);
          }}
        >
          <EditIcon className="w-3 h-3" />
        </Button>
        <Button
          size="icon-sm"
          variant="outline"
          onClick={() => {
            if (
              confirm(
                `Souhaitez-vous vraiment supprimer le thème "${theme.theme}"`,
              )
            ) {
              onDeleteTheme(theme.id);
            }
          }}
        >
          <TrashIcon className="w-3 h-3 text-red-500" />
        </Button>
      </div>
    </div>
  );
};
