import { sortBy } from "lodash";

import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";

export const sortExerciseResults = (
  exerciseResults: ExerciseResults[],
  exerciseNames: string[],
  useCustomSort: boolean = false,
) => {
  if (exerciseNames.length === 0) {
    return sortBy(exerciseResults, "name");
  }

  const filteredExercises = exerciseResults.filter((r) =>
    exerciseNames.includes(r.name),
  );

  if (!useCustomSort) {
    return sortBy(filteredExercises, "name");
  }

  const nameOrderMap = new Map(
    exerciseNames.map((name, index) => [name, index]),
  );

  return filteredExercises.sort((a, b) => {
    const indexA = nameOrderMap.get(a.name) ?? exerciseNames.length;
    const indexB = nameOrderMap.get(b.name) ?? exerciseNames.length;

    return indexA - indexB;
  });
};
