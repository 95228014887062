import { useAtom } from "jotai";
import { useCallback } from "react";
import toast from "react-hot-toast";

import { suiviEplTestAttempts } from "~/routes/suivi/atoms.ts";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { sanitizeEplAttempt } from "~/routes/suivi/utils.ts";

export const useEplTestAttempts = () => {
  const { settings } = useSettings();

  const [attempts, setAttempts] = useAtom(suiviEplTestAttempts);

  const syncEplTestFetch = useCallback(() => {
    if (!settings?.epl_api_key) {
      return Promise.resolve([]);
    }

    return fetch(
      `https://epltest.fr/public_api/instances?locale=fr&public_api_key=${encodeURIComponent(
        settings.epl_api_key,
      )}`,
    )
      .then((res) => res.json())
      .then((res) => res.map(sanitizeEplAttempt))
      .catch(() => []);
  }, [settings?.epl_api_key]);

  const syncEplTest = useCallback(() => {
    if (!settings?.epl_api_key) {
      return Promise.resolve([]);
    }

    const promise = syncEplTestFetch().then(setAttempts);

    toast.promise(
      promise,
      {
        loading: <p className="text-sm">Import EPLtest en cours…</p>,
        success: <p className="text-sm">Import EPLtest terminé !</p>,
        error: <p className="text-sm">Erreur lors de l'import EPLtest.</p>,
      },
      { success: { duration: 1500 } },
    );

    return promise;
  }, [setAttempts, settings?.epl_api_key, syncEplTestFetch]);

  const checkEplTestConnectivity = useCallback(
    (apiKey: string) =>
      fetch(
        `https://epltest.fr/public_api/instances?locale=fr&public_api_key=${encodeURIComponent(
          apiKey,
        )}`,
      ).then((res) =>
        res.ok ? Promise.resolve() : Promise.reject("sync:could_not_fetch"),
      ),
    [],
  );

  const deleteAttempts = useCallback(() => setAttempts([]), [setAttempts]);

  return { attempts, syncEplTest, deleteAttempts, checkEplTestConnectivity };
};
