import clsx from "clsx";
import { FC, useEffect } from "react";
import { Updater } from "use-immer";

import { Input } from "~/components/ui/input.tsx";
import { Label } from "~/components/ui/label.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Switch } from "~/components/ui/switch.tsx";
import IconPilotest from "~/routes/suivi/imgs/pt-icon.png";
import { OnboardingSettings } from "~/routes/suivi/OnboardingPage.tsx";
import { handleCsvUpload } from "~/routes/suivi/utils/handleCsvUpload.ts";

interface OnboardingPilotestProps {
  settings: OnboardingSettings;
  onChangeSettings: Updater<OnboardingSettings>;
}

export const OnboardingPilotest: FC<OnboardingPilotestProps> = ({
  settings,
  onChangeSettings,
}) => {
  useEffect(() => {
    if (settings.pilotest.enabled) {
      return;
    }

    onChangeSettings((draft) => {
      delete draft.pilotest.mode;
      delete draft.pilotest.attempts;
      delete draft.pilotest.email;
      delete draft.pilotest.password;
    });
  }, [onChangeSettings, settings.pilotest.enabled]);

  return (
    <div
      className={clsx(
        "text-sm border rounded px-4 py-3",
        settings.pilotest.enabled && "shadow-md shadow-blue-100",
      )}
    >
      <h2 className="flex items-center justify-between font-semibold text-xl">
        <p className="space-x-2">
          <img src={IconPilotest} className="w-6 h-6 inline mr-1 -mt-1" />
          <span>Pilotest</span>
        </p>
        <Switch
          checked={settings.pilotest.enabled}
          onCheckedChange={(value) =>
            onChangeSettings((draft) => {
              draft.pilotest.enabled = value;
            })
          }
        />
      </h2>
      {settings.pilotest.enabled && (
        <div className="mt-4 pb-2">
          <p>
            Pilotest ne propose pas d'interface pour récupérer vos résultats ;
            deux choix s'offrent à vous :
          </p>
          <ol className="mt-2 space-y-2">
            <li>
              -{" "}
              <strong className="font-medium">
                Enregistrer vos identifiants Pilotest sur KD Tools
              </strong>{" "}
              pour récupérer vos résultats automatiquement (le plus simple)
            </li>
            <li>
              -{" "}
              <strong className="font-medium">
                Réaliser un import/export .csv manuel de vos résultats
              </strong>{" "}
              à chaque visite sur KD Tools.
            </li>
          </ol>
          <div className="mt-4">
            <Select
              value={settings.pilotest.mode}
              onValueChange={(option) =>
                onChangeSettings((draft) => {
                  // @ts-expect-error Typing is OK…
                  draft.pilotest.mode = option;
                })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Choisir une option…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="auto">
                  Import automatique (enregistrer mes identifiants Pilotest)
                </SelectItem>
                <SelectItem value="manual">
                  Import manuel (avec import/export de fichier .csv)
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          {settings.pilotest.mode === "manual" && (
            <>
              <p className="mt-6">
                1.{" "}
                <span className="font-semibold">Téléchargez l'export CSV</span>{" "}
                de Pilotest depuis{" "}
                <a
                  href="https://www.pilotest.com/fr/results.csv"
                  rel="noreferrer"
                  className="underline hover:no-underline"
                  target="_blank"
                >
                  https://www.pilotest.com/fr/results.csv
                </a>
                .
              </p>
              <div className="flex flex-col space-y-3 mt-3">
                <label htmlFor="csvFile" className="font-semibold">
                  2. Sélectionnez l'export CSV :
                </label>
                <input
                  id="csvFile"
                  type="file"
                  name="csvFile"
                  accept=".csv"
                  onChange={(evt) =>
                    handleCsvUpload(evt, (attempts) =>
                      onChangeSettings((draft) => {
                        draft.pilotest.attempts = attempts;
                      }),
                    )
                  }
                />
              </div>
            </>
          )}
          {settings.pilotest.mode === "auto" && (
            <>
              <div className="mt-6 space-y-2">
                <p>
                  Vos identifiants Pilotest seront stockés de façon sécurisée
                  dans votre navigateur. Si vous utilisez KD Tools depuis
                  différents appareils, vous devrez les saisir à nouveau.
                </p>
              </div>
              <div className="mt-4 flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <Label htmlFor="pt-usr">Adresse e-mail Pilotest :</Label>
                  <Input
                    name="pt-usr"
                    autoComplete="none"
                    value={settings.pilotest.email ?? ""}
                    onChange={(evt) =>
                      onChangeSettings((draft) => {
                        draft.pilotest.email = evt.currentTarget.value;
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 mt-2">
                  <Label htmlFor="pt-pwd">Mot de passe Pilotest :</Label>
                  <Input
                    name="pt-pwd"
                    type="password"
                    autoComplete="none"
                    value={settings.pilotest.password ?? ""}
                    onChange={(evt) =>
                      onChangeSettings((draft) => {
                        draft.pilotest.password = evt.currentTarget.value;
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
