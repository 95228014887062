import { HeartIcon } from "lucide-react";
import { FC, useEffect } from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";

export const Donate: FC = () => {
  const { user } = useCurrentUser();

  useEffect(() => {
    Promise.resolve(supabase.from("donations").insert({ user_id: user.id }));
  }, [user.id]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p>
        <HeartIcon className="text-blue-600" size={72} />
      </p>
      <h2 className="mt-4 text-2xl font-medium">Merci beaucoup !</h2>
      <p className="mt-2 max-w-md text-center">
        N'hésitez pas à transmettre vos idées, retours
        <br />
        et pistes d'amélioration via{" "}
        <a
          href="https://forms.fillout.com/t/vJdHp6jTEqus"
          target="_blank"
          className="underline hover:no-underline"
        >
          le formulaire de contact
        </a>{" "}
        :)
      </p>
    </div>
  );
};
