export const borderClassNameFromStanineClass = (
  stanineClass: number,
): string => {
  if (stanineClass < 2) {
    return "border-red-500";
  } else if (stanineClass < 3) {
    return "border-orange-500";
  } else if (stanineClass < 4) {
    return "border-amber-500";
  } else if (stanineClass < 5) {
    return "border-yellow-500";
  } else if (stanineClass < 6) {
    return "border-lime-500";
  } else if (stanineClass < 7) {
    return "border-green-500";
  } else if (stanineClass < 8) {
    return "border-emerald-500";
  } else if (stanineClass < 9) {
    return "border-blue-500";
  } else if (stanineClass < 10) {
    return "border-blue-800";
  } else {
    return "border-black";
  }
};
