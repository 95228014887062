import { FC } from "react";

import { Wrapper } from "~/components/Wrapper.tsx";
import { IntegerDivision } from "~/routes/mathematiques/IntegerDivision.tsx";
import { Pgcd } from "~/routes/mathematiques/Pgcd.tsx";
import { Ppcm } from "~/routes/mathematiques/Ppcm.tsx";

export const Mathematiques: FC = () => (
  <Wrapper
    header={
      <div className="flex items-center justify-between">
        <h1 className="w-full flex justify-between items-center">
          <span className="text-3xl font-bold underline">Mathématiques</span>
        </h1>
      </div>
    }
  >
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-20 gap-y-12">
      <Pgcd />
      <Ppcm />
      <IntegerDivision />
    </div>
  </Wrapper>
);
