import { ChartNoAxesCombinedIcon } from "lucide-react";
import { FC } from "react";

import {
  ScoreChartDrawer,
  ScoreChartProps,
} from "~/components/ScoreChartDrawer.tsx";
import { Button } from "~/components/ui/button.tsx";
import { useDrawer } from "~/providers/DrawerProvider.tsx";

interface ScoreButtonProps extends ScoreChartProps {
  exercise: string;
  variant: string;
  label: string;
}

export const ScoreButton: FC<ScoreButtonProps> = ({ label, ...chartProps }) => {
  const { openDrawer } = useDrawer();

  return (
    <Button
      className="hidden sm:flex"
      onClick={() =>
        openDrawer(
          `Résultats pour ${label}`,
          <ScoreChartDrawer
            filter={(s) => s.timing > 1 && s.timing <= 60}
            {...chartProps}
          />,
        )
      }
      variant="ghost"
      data-umami-event="scores:showScores"
      data-umami-event-exercise={chartProps.exercise}
      data-umami-event-variant={chartProps.variant}
    >
      <ChartNoAxesCombinedIcon className="w-5 h-5" strokeWidth={1.5} />
      <span className="ml-2.5">Afficher mes scores</span>
    </Button>
  );
};
