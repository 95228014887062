import { useEffect, useState } from "react";

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1;

export const useSpeak = (enabled = false) => {
  const [voice, setVoice] = useState<SpeechSynthesisVoice | undefined>();

  useEffect(() => {
    const checkVoicesInterval = setInterval(() => {
      const voices = window.speechSynthesis.getVoices();

      const voiceFrench = voices.find((voice) =>
        (isSafari
          ? ["com.apple.voice.super-compact.fr-FR.Thomas"]
          : ["Google français"]
        ).includes(voice.voiceURI),
      );

      if (voiceFrench) {
        setVoice(voiceFrench);
        return clearInterval(checkVoicesInterval);
      }
    }, 1000);
  }, []);

  return {
    canSpeak: !!voice,
    speak: (sentence: string) => {
      if (!enabled || !voice) {
        return;
      }

      const toSpeak = new SpeechSynthesisUtterance(sentence);
      toSpeak.voice = voice;

      speechSynthesis.cancel();
      speechSynthesis.speak(toSpeak);
    },
  };
};
