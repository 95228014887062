import { FC, Fragment, useEffect, useState } from "react";

import { BigButton } from "~/components/BigButton.tsx";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import { useClock } from "~/hooks/useClock.ts";
import { IndexedElement } from "~/routes/calculs/elements.ts";

export interface TrainingBaseViewProps {
  indexedElements: IndexedElement[];
  withChrono?: boolean;
}

export interface TrainingBaseViewActions {
  displayResult: boolean;
  displayNext: boolean;
}

export const TrainingBaseView: FC<TrainingBaseViewProps> = ({
  indexedElements = [],
  withChrono = false,
}) => {
  const { clock, isPaused, restartClock, stopClock, pause, resume } = useClock({
    interval: withChrono ? 4 : 0,
    onRestart: () => handleOnClickDisplayResult(),
  });

  const [currentElement, setCurrentElement] = useState<
    IndexedElement | undefined
  >();

  const [actions, setActions] = useState<TrainingBaseViewActions>({
    displayResult: true,
    displayNext: false,
  });

  useEffect(() => {
    if (indexedElements.length) {
      setCurrentElement(indexedElements[0]);
      setActions({ displayResult: true, displayNext: false });
    }
  }, [indexedElements]);

  const handleOnClickDisplayResult = (fromClick = false) => {
    if (withChrono) {
      stopClock();
    }

    setActions({ displayResult: false, displayNext: true });

    if (withChrono && !fromClick) {
      setTimeout(() => {
        handleOnClickNext();
      }, 2000);
    }
  };

  const handleOnClickNext = () => {
    if (!currentElement) {
      stopClock();
      return;
    }

    setActions({ displayResult: true, displayNext: false });
    setCurrentElement(indexedElements[currentElement.index + 1]);
    restartClock();
  };

  return (
    <Fragment>
      {currentElement && (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h2 className="mb-1 flex justify-between">
              <TitleLabel>Calcul</TitleLabel>
              {clock !== 0 && actions.displayResult && (
                <span
                  className="text-slate-500 tabular-nums cursor-pointer text-sm"
                  title="Désactiver le chronomètre"
                  onClick={() => stopClock()}
                >
                  ⌛ {clock}
                </span>
              )}
              {clock === 0 && (
                <span className="text-sm">
                  {currentElement.index + 1}/{indexedElements.length}
                </span>
              )}
            </h2>
            <div className="overflow-hidden rounded-lg bg-slate-50 h-40">
              <div className="px-3 py-3 h-full flex justify-center items-center text-3xl font-semibold tabular-nums">
                {currentElement.element.formula} =&nbsp;
                <span className="border-b-2 border-black border-dashed">
                  <span className={!actions.displayResult ? "" : "invisible"}>
                    {currentElement.element.result}
                  </span>
                </span>
              </div>
            </div>
          </div>

          {withChrono ? (
            <div className={actions.displayNext ? "invisible" : undefined}>
              {isPaused ? (
                <BigButton onClick={() => resume()}>Continuer</BigButton>
              ) : (
                <BigButton onClick={() => pause()}>Pause</BigButton>
              )}
            </div>
          ) : (
            <div>
              {actions.displayResult && (
                <BigButton onClick={() => handleOnClickDisplayResult(true)}>
                  Résultat
                </BigButton>
              )}
              {actions.displayNext && (
                <BigButton onClick={handleOnClickNext}>Suivant</BigButton>
              )}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};
