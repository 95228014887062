import clsx from "clsx";
import { FC } from "react";

interface ProgressStatusProps {
  distance: number | null;
}

export const ProgressStatus: FC<ProgressStatusProps> = ({ distance }) => {
  if (distance === null) {
    return (
      <span className="relative flex w-1.5 h-1.5">
        <span
          className={clsx(
            "absolute inline-flex h-full w-full rounded-full opacity-75 border border-black",
          )}
        />
      </span>
    );
  }

  const primaryClassName =
    distance < 3
      ? "bg-green-400"
      : distance < 5
        ? "bg-yellow-400"
        : distance < 7
          ? "bg-orange-400"
          : distance < 12
            ? "bg-rose-400"
            : distance < 15
              ? "bg-red-400"
              : "bg-black";

  const secondaryClassName =
    distance < 3
      ? "bg-green-300"
      : distance < 5
        ? "bg-yellow-300"
        : distance < 7
          ? "bg-orange-300"
          : distance < 12
            ? "bg-rose-300"
            : distance < 15
              ? "bg-red-300"
              : "bg-slate-500";

  return (
    <span className="relative flex w-1.5 h-1.5">
      <span
        className={clsx(
          "absolute inline-flex h-full w-full rounded-full opacity-75",
          secondaryClassName,
          distance >= 5 && distance < 15 && "animate-ping",
        )}
      />
      <span
        className={clsx(
          "relative inline-flex rounded-full w-1.5 h-1.5",
          primaryClassName,
        )}
      />
    </span>
  );
};
