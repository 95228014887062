import { reverse } from "lodash";
import { ZoomInIcon } from "lucide-react";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { useDrawer } from "~/providers/DrawerProvider.tsx";
import { ExercisesResultsDetailsDrawer } from "~/routes/suivi/components/ExercisesResultsDetailsDrawer.tsx";
import { useEplTestAttempts } from "~/routes/suivi/hooks/useEplTestAttempts.tsx";
import { getExerciseNameAndSource } from "~/routes/suivi/utils/getExerciseNameAndSource.ts";

interface ExercisesResultsDetailsProps {
  exerciseName: string;
  customName?: string;
}

export const ExercisesResultsDetails: FC<ExercisesResultsDetailsProps> = ({
  exerciseName,
  customName,
}) => {
  const { openDrawer } = useDrawer();
  const { attempts: eplTestAttempts } = useEplTestAttempts();

  const { name } = getExerciseNameAndSource(exerciseName);

  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger
        className="text-slate-300 hover:text-slate-500 cursor-pointer"
        data-umami-event="suivi:seeDetails"
        onClick={() =>
          openDrawer(
            `Résultats pour ${customName ?? name}`,
            <ExercisesResultsDetailsDrawer
              exerciseName={exerciseName}
              attempts={reverse(
                eplTestAttempts.filter((a) => a.name === exerciseName),
              )}
            />,
          )
        }
        asChild
      >
        <ZoomInIcon className="hidden sm:group-hover:block w-4 h-4" />
      </TooltipTrigger>
      <TooltipContent>Voir le détails</TooltipContent>
    </Tooltip>
  );
};
