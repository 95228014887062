import clsx from "clsx";
import { FC, ReactNode } from "react";

interface BadgeProps {
  children: ReactNode;
  type?: "success" | "error";
  className?: string;
  size?: "md" | "lg";
}

export const Badge: FC<BadgeProps> = ({
  children,
  type,
  size = "md",
  className,
}) => (
  <p
    className={clsx(
      "inline-flex items-center rounded-md font-medium",
      size === "md" ? "px-1.5 py-0.5 text-xs" : "px-2 py-0.5",
      !type && !className && "text-slate-600 bg-slate-100",
      type === "success" && "text-green-500 bg-green-100",
      type === "error" && "text-red-500 bg-red-100",
      className,
    )}
  >
    {children}
  </p>
);
