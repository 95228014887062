import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { Chart } from "~/routes/suivi/components/Chart.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { AggregatedResultByDate } from "~/routes/suivi/utils/resultsByDate.ts";

interface ExercisesResultsPopoverProps {
  exerciseName: string;
  results: AggregatedResultByDate[];
}

export const ExercisesResultsPopover: FC<ExercisesResultsPopoverProps> = ({
  exerciseName,
  results,
}) => {
  const { settings } = useSettings();
  const { renamingsList } = useRenamings();

  if (results.length === 0) {
    return null;
  }

  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger className="inline-flex items-center gap-2 cursor-default truncate">
        <ExerciseWithIcon
          exerciseName={exerciseName}
          customName={renamingsList[exerciseName]}
        />
      </TooltipTrigger>
      <TooltipContent className="w-[396px] ml-4" side="right">
        <Chart
          display={settings?.table_display ?? "stanine"}
          exercise={exerciseName}
          results={results}
          withAnimation={false}
          withLegend={false}
          size={240}
        />
      </TooltipContent>
    </Tooltip>
  );
};
