import { FC } from "react";

interface OnboardingCheckStatusProps {
  name: string;
  status: "success" | "error" | "idle";
}

export const OnboardingCheckStatus: FC<OnboardingCheckStatusProps> = ({
  name,
  status,
}) => {
  return (
    <div className="flex items-center justify-between">
      <p>{name}</p>
      <p>{status === "success" ? "✅" : status === "error" ? "❌" : "⏳"}</p>
    </div>
  );
};
