import clsx from "clsx";
import groupBy from "lodash/groupBy";
import { ChartNoAxesCombinedIcon, SettingsIcon } from "lucide-react";
import { FC, useRef } from "react";

import { Button } from "~/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu.tsx";
import { Chart } from "~/routes/suivi/components/Chart.tsx";
import { SUIVI_DEFAULT_GROUP } from "~/routes/suivi/components/Navigation.tsx";
import { useAggregatedResultsFromDate } from "~/routes/suivi/hooks/useAggregatedResultsFromDate.ts";
import { useAttempts } from "~/routes/suivi/hooks/useAttempts.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { sortExerciseNames } from "~/routes/suivi/utils/sortExerciseNames.ts";
import { Enums } from "~/supabase.types.ts";

interface ChartsProps {
  group: string;
}

export const Charts: FC<ChartsProps> = ({ group }) => {
  const { settings, saveSettings } = useSettings();
  const { attempts } = useAttempts();
  const { groups } = useGroups();

  const ref = useRef<HTMLDivElement | null>(null);

  const allResultsByDate = useAggregatedResultsFromDate(
    settings?.display_from_date ?? "",
  );

  const exercisesDisplayed = sortExerciseNames(
    Object.keys(groupBy(attempts, (attempt) => attempt.name)),
    group === SUIVI_DEFAULT_GROUP
      ? []
      : (groups.find((g) => g.name === group)?.exercises ?? []),
    groups.find((g) => g.name === group)?.use_custom_order ?? false,
  );

  if (exercisesDisplayed.length === 0) {
    return null;
  }

  return (
    <div className="border rounded" ref={ref}>
      <div className="mb-1 flex items-center justify-between border-b px-3 py-1.5 bg-slate-200">
        <p className="font-medium text-2xl inline-flex items-center gap-2 pl-1">
          <ChartNoAxesCombinedIcon className="text-slate-600" />
          <span>Progression</span>
        </p>
        <div className="hidden sm:flex space-x-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <SettingsIcon className="w-4 h-4" />
                <span className="ml-2">Paramètres</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Mesure</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup
                value={settings?.graph_display ?? "stanine"}
                onValueChange={(display) =>
                  saveSettings({
                    graph_display: display as Enums<"graph_display">,
                  })
                }
              >
                <DropdownMenuRadioItem
                  value="stanine"
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Stanine
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  value="percentage"
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Pourcentage
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
              <DropdownMenuLabel>Calcul</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuRadioGroup
                value={settings?.graph_compute ?? "compute"}
                onValueChange={(compute) =>
                  saveSettings({
                    graph_compute: compute as Enums<"graph_compute">,
                  })
                }
              >
                <DropdownMenuRadioItem
                  value="max"
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Meilleur score
                </DropdownMenuRadioItem>
                <DropdownMenuRadioItem
                  value="mean"
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Moyenne
                </DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div
        className={clsx(
          "grid grid-cols-1 gap-8 sm:grid-cols-3 sm:px-4 sm:py-2",
          exercisesDisplayed.length >= 4 ? "sm:grid-cols-4" : "sm:grid-cols-3",
        )}
      >
        {exercisesDisplayed.map((exercise) => (
          <Chart
            key={exercise}
            display={settings?.graph_display ?? "stanine"}
            exercise={exercise}
            results={allResultsByDate.filter((r) =>
              Object.prototype.hasOwnProperty.call(r, exercise),
            )}
          />
        ))}
      </div>
    </div>
  );
};
