import { FC, useEffect, useState } from "react";

import { BigButton } from "~/components/BigButton.tsx";
import { Toggle } from "~/components/Toggle.tsx";
import { useClock } from "~/hooks/useClock.ts";
import { useSpeak } from "~/hooks/useSpeak.ts";
import {
  getLetterPosition,
  IndexedAlphabet,
} from "~/routes/alphabet/alphabet.ts";
import { GridLetters } from "~/routes/alphabet/GridLetters.tsx";
import { randomWithExclusions } from "~/utils/randomWithExclusions.ts";

export interface LearningBaseViewProps {
  letters: string;
}

export const LearningBaseView: FC<LearningBaseViewProps> = ({ letters }) => {
  const [shouldSpeak, setShouldSpeak] = useState(false);
  const { canSpeak, speak } = useSpeak(shouldSpeak);

  const [useAuto, setUseAuto] = useState(false);
  const [useAutoRandom, setUseAutoRandom] = useState(false);

  const { clock } = useClock({
    interval: useAuto || useAutoRandom ? (shouldSpeak ? 3 : 1) : 0,
    onRestart: () => (useAuto ? handleClickNext() : handleClickRandom()),
  });

  const [showPosition, setShowPosition] = useState(true);
  const [showPositions, setShowPositions] = useState(true);

  const [loop, setLoop] = useState<IndexedAlphabet[]>([]);
  const [currentLetter, setCurrentLetter] = useState<
    IndexedAlphabet | undefined
  >();

  useEffect(() => {
    setLoop(letters.split(",").map((letter, index) => ({ index, letter })));
  }, [letters]);

  // When the `loop` is updated, we reset the `currentLetter` to the beginning…
  useEffect(() => {
    if (loop.length) {
      setCurrentLetter(loop[0]);
    }
  }, [loop]);

  // Speech-to-text useEffect()…
  useEffect(() => {
    if (!currentLetter) {
      return;
    }

    speak(
      `"${currentLetter.letter}";${getLetterPosition(currentLetter.letter)}`,
    );
  }, [currentLetter]);

  useEffect(() => {
    if (currentLetter && shouldSpeak) {
      speak(
        `"${currentLetter.letter}";${getLetterPosition(currentLetter.letter)}`,
      );
    }
  }, [shouldSpeak]);

  const handleClickNext = () => {
    if (!currentLetter) {
      return;
    }

    setCurrentLetter(
      loop[
        currentLetter.index === loop.length - 1 ? 0 : currentLetter.index + 1
      ],
    );
  };

  const handleClickRandom = () => {
    if (!currentLetter) {
      return;
    }

    setCurrentLetter(
      loop[randomWithExclusions(0, loop.length - 1, [currentLetter.index])],
    );
  };

  return (
    <div>
      <div className="mb-8 grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="col-span-2">
          <h2 className="mb-2 flex items-center justify-between">
            <span className="uppercase font-semibold">Lettre</span>
          </h2>
          <div className="overflow-hidden rounded-lg bg-slate-50 h-24 sm:h-64">
            <div className="px-3 py-3 h-full flex justify-center items-center text-5xl sm:text-9xl space-x-4 slashed-zero tabular-num font-mono">
              <span>{currentLetter?.letter}</span>
              <span
                className="font-semibold underline decoration-2 decoration-dashed underline-offset-8 cursor-pointer"
                onClick={() => setShowPosition(!showPosition)}
              >
                {showPosition ? (
                  getLetterPosition(currentLetter?.letter ?? "")
                ) : (
                  <>&nbsp;</>
                )}
              </span>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2 flex justify-between">
            <span className="uppercase font-semibold">Contrôles</span>
            {clock !== 0 && (
              <span className="text-slate-500 tabular-nums cursor-pointer">
                ⌛ {clock}
              </span>
            )}
          </h2>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <BigButton
                onClick={handleClickNext}
                disabled={useAuto || useAutoRandom}
              >
                Suivant
              </BigButton>
              <BigButton
                onClick={handleClickRandom}
                disabled={useAuto || useAutoRandom}
              >
                Aléatoire
              </BigButton>
            </div>
            <Toggle
              label="Auto Suivant ⏭️"
              description="Défilement automatique"
              toggled={useAuto}
              onToggle={(value) => {
                setUseAuto(value);
                setUseAutoRandom(false);
              }}
            />
            <Toggle
              label="Auto Aléatoire 🔀"
              description="Défilement automatique aléatoire"
              toggled={useAutoRandom}
              onToggle={(value) => {
                setUseAutoRandom(value);
                setUseAuto(false);
              }}
            />
            {canSpeak && (
              <Toggle
                label="Annonce Vocale 🔉"
                description="Annonce vocale des lettres"
                toggled={shouldSpeak}
                onToggle={setShouldSpeak}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <h2
          className="mb-2 uppercase font-semibold cursor-pointer"
          onClick={() => setShowPositions(!showPositions)}
        >
          Positions (Afficher/Cacher)
        </h2>
        {showPositions && (
          <GridLetters
            letters={letters}
            selectedLetter={currentLetter?.letter}
          />
        )}
      </div>
    </div>
  );
};
