import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface DrawerContextProps {
  isOpen: boolean;
  openDrawer: (title: string, content: ReactNode) => void;
  closeDrawer: () => void;
  drawerTitle: string | null;
  drawerContent: ReactNode | null;
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState<string | null>(null);
  const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);

  const openDrawer = useCallback((title: string, content: ReactNode) => {
    setDrawerTitle(title);
    setDrawerContent(content);
    setIsOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => setDrawerContent(null), 500);
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        openDrawer,
        closeDrawer,
        drawerTitle,
        drawerContent,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error("useDrawer must be used within a DrawerProvider");
  }

  return context;
};

export { DrawerProvider, useDrawer };
