import { meanBy, orderBy, sumBy, take } from "lodash";

import { Dimension } from "~/routes/suivi/types/Dimension.ts";
import { DimensionAverages } from "~/routes/suivi/types/DimensionAverages.ts";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { htmlDateToDate } from "~/routes/suivi/utils/htmlDateToDate.ts";
import { Enums } from "~/supabase.types.ts";

export const computeDimensionAverages = (
  dimensions: Dimension[],
  attempts: ExerciseAttempt[],
  excludedExercises: string[] = [],
  computeOn?: Enums<"dimension_compute"> | null,
  fromDate = "",
): DimensionAverages[] =>
  dimensions.map((dimension) => {
    // For each dimension, calculate averages for each exercise
    const exercises = dimension.exerciseNames.map((exerciseName) => {
      const fromDateDate = htmlDateToDate(fromDate);

      // Filter attempts by exercise name
      const exerciseAttempts = attempts.filter(
        (attempt) =>
          attempt.name === exerciseName &&
          !excludedExercises.includes(attempt.name) &&
          (!fromDateDate || attempt.at >= fromDateDate),
      );

      // Sort attempts by date (newest first)
      const sortedAttempts = orderBy(exerciseAttempts, ["at"], ["desc"]);
      // Take the last X attempts
      const lastAttempts = take(
        sortedAttempts,
        computeOn === "last_attempt"
          ? 1
          : computeOn === "average_3_attempts"
            ? 3
            : computeOn === "average_5_attempts"
              ? 5
              : computeOn === "average_10_attempts"
                ? 10
                : 5,
      );

      // Compute average percentScore and stanineClass
      const averagePercentScore = lastAttempts.length
        ? meanBy(lastAttempts, "percentScore")
        : 0;
      const averageStanineClass = lastAttempts.length
        ? meanBy(lastAttempts, "stanineClass")
        : 0;

      return {
        exerciseName,
        averagePercentScore,
        averageStanineClass,
      };
    });

    // Filter out exercises with no attempts (averagePercentScore and averageStanineClass of 0)
    const validExercises = exercises.filter(
      (ex) => ex.averagePercentScore > 0 || ex.averageStanineClass > 0,
    );

    // Calculate the dimension-level averages (averaging across valid exercises)
    const totalPercentScore = sumBy(validExercises, "averagePercentScore");
    const totalStanineClass = sumBy(validExercises, "averageStanineClass");

    const averagePercentScore =
      validExercises.length > 0 ? totalPercentScore / validExercises.length : 0;
    const averageStanineClass =
      validExercises.length > 0 ? totalStanineClass / validExercises.length : 0;

    // Return the dimension-level averages and per-exercise details
    return {
      dimensionName: dimension.name,
      dimensionIcon: dimension.icon,
      averagePercentScore,
      averageStanineClass,
      exercises: validExercises,
      excludedExercises: dimension.exerciseNames.filter((e) =>
        excludedExercises.includes(e),
      ),
    };
  });
