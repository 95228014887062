import { format } from "date-fns";
import { EyeIcon, EyeOffIcon, GroupIcon, SettingsIcon } from "lucide-react";
import { FC } from "react";

import { Button } from "~/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu.tsx";
import { DimensionAverage } from "~/routes/suivi/components/DimensionAverage.tsx";
import { DimensionHeader } from "~/routes/suivi/components/DimensionHeader.tsx";
import { DimensionsDimension } from "~/routes/suivi/components/DimensionsDimension.tsx";
import { useAttempts } from "~/routes/suivi/hooks/useAttempts.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { computeDimensionAverages } from "~/routes/suivi/utils/computeDimensionAverages.ts";
import { dimensions } from "~/routes/suivi/utils/dimensions.tsx";
import { Enums } from "~/supabase.types.ts";

export const Dimensions: FC = () => {
  const { settings, saveSettings } = useSettings();
  const { attempts } = useAttempts();

  const dimensionAverages = settings?.hide_dimensions
    ? []
    : computeDimensionAverages(
        dimensions,
        attempts,
        settings?.exercises_excluded_from_dimensions ?? [],
        settings?.dimension_compute,
        settings?.display_from_date ?? "",
      );

  return (
    <div className="hidden sm:block border rounded">
      <div className="flex items-center justify-between border-b px-3 py-1.5 bg-slate-200">
        <p className="font-medium text-2xl inline-flex items-center gap-2.5 pl-1">
          <GroupIcon className="text-slate-600" />
          <span>Dimensions</span>
        </p>
        <div className="hidden sm:flex sm:items-center sm:gap-2">
          {!settings?.hide_dimensions && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <SettingsIcon className="w-4 h-4" />
                  <span className="ml-2">Paramètres</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Calcul</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={settings?.dimension_compute ?? "average_5_attempts"}
                  onValueChange={(compute) =>
                    saveSettings({
                      dimension_compute: compute as Enums<"dimension_compute">,
                    })
                  }
                >
                  <DropdownMenuRadioItem
                    value="last_attempt"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Derniers résultats
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="average_3_attempts"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Moy. 3 derniers résultats
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="average_5_attempts"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Moy. 5 derniers résultats
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="average_10_attempts"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Moy. 10 derniers résultats
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          {settings?.hide_dimensions ? (
            <Button
              variant="outline"
              onClick={() => saveSettings({ hide_dimensions: false })}
            >
              <EyeIcon className="w-4 h-4" />
              <span className="ml-2">Afficher</span>
            </Button>
          ) : (
            <Button
              variant="outline"
              onClick={() => saveSettings({ hide_dimensions: true })}
            >
              <EyeOffIcon className="w-4 h-4" />
              <span className="ml-2">Masquer</span>
            </Button>
          )}
        </div>
      </div>

      {!settings?.hide_dimensions && (
        <>
          <div className="px-4 pt-3 pb-1 flex flex-col gap-1">
            <DimensionHeader />
            <DimensionAverage dimensions={dimensionAverages} />
            {dimensionAverages.map((dim) => (
              <DimensionsDimension key={dim.dimensionName} dimension={dim} />
            ))}
          </div>
          <div className="text-xs px-3 py-2 text-slate-400 leading-5">
            {!settings?.dimension_compute ||
              (settings.dimension_compute.includes("average") ? (
                <p>
                  La moyenne par dimension est calculée à partir des{" "}
                  <strong className="font-semibold underline">
                    {settings.dimension_compute.includes("3")
                      ? 3
                      : settings.dimension_compute.includes("5")
                        ? 5
                        : 10}{" "}
                    derniers essais
                  </strong>{" "}
                  de chaque exercice de la dimension{" "}
                  <strong className="font-semibold underline">
                    {settings.display_from_date
                      ? `depuis le ${format(settings.display_from_date, "dd/MM/y")}`
                      : ""}
                  </strong>{" "}
                  (liste des exercices accessible au survol d'une dimension).
                </p>
              ) : (
                <p>
                  La moyenne par dimension est calculée à partir du{" "}
                  <strong className="font-semibold underline">
                    dernier essai
                  </strong>{" "}
                  de chaque exercice de la dimension{" "}
                  <strong className="font-semibold underline">
                    {settings.display_from_date
                      ? `depuis le ${format(settings.display_from_date, "dd/MM/y")}`
                      : ""}
                  </strong>{" "}
                  (liste accessible au survol d'une dimension).
                </p>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
