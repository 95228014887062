// const RES = Object.keys(PT).reduce((all, curr) => {
//   all[PT[curr]['fr']['content'] + "##PT"] = `https://www.pilotest.com/fr/tests/${PT[curr]['fr']['code']}/play`;
//   return all;
// }, {})

const EXERCISE_NAME_TO_LINK = {
  "Test de Repérage d'Objets##PT": "https://www.pilotest.com/fr/tests/tro/play",
  "Direction##PT": "https://www.pilotest.com/fr/tests/direction/play",
  "Figures identiques##PT": "https://www.pilotest.com/fr/tests/figures_id/play",
  "TPRD##PT": "https://www.pilotest.com/fr/tests/tprd/play",
  "Tangram##PT": "https://www.pilotest.com/fr/tests/tangram/play",
  "Mathématiques##PT": "https://www.pilotest.com/fr/tests/mathematiques/play",
  "Tableaux de Bord##PT": "https://www.pilotest.com/fr/tests/tdb/play",
  "Synonymes##PT": "https://www.pilotest.com/fr/tests/synonymes/play",
  "Angles##PT": "https://www.pilotest.com/fr/tests/angles/play",
  "Intrus##PT": "https://www.pilotest.com/fr/tests/intrus/play",
  "Matrices de Raven##PT": "https://www.pilotest.com/fr/tests/apm/play",
  "Lecture de textes##PT": "https://www.pilotest.com/fr/tests/francais/play",
  "Test de Mémoire##PT": "https://www.pilotest.com/fr/tests/memoire/play",
  "Cercles et Croix##PT":
    "https://www.pilotest.com/fr/tests/cercles_et_croix/play",
  "Matrice Quatre-Lettres##PT":
    "https://www.pilotest.com/fr/tests/matriceql/play",
  "TEAI##PT": "https://www.pilotest.com/fr/tests/teai/play",
  "Jauges##PT": "https://www.pilotest.com/fr/tests/jauges/play",
  "BLS IV##PT": "https://www.pilotest.com/fr/tests/bls/play",
  "Barres à comparer##PT": "https://www.pilotest.com/fr/tests/barres/play",
  "Patrons##PT": "https://www.pilotest.com/fr/tests/datsp/play",
  "Rotations##PT": "https://www.pilotest.com/fr/tests/rotations/play",
  "EFG##PT": "https://www.pilotest.com/fr/tests/efg/play",
  "Voitures (séquentiel)##PT":
    "https://www.pilotest.com/fr/tests/voitures/play",
  "Calcul mental 1##PT":
    "https://www.pilotest.com/fr/tests/calcul_mental1/play",
  "Pliage (NV5R)##PT": "https://www.pilotest.com/fr/tests/pliage/play",
  "Calcul mental 2##PT":
    "https://www.pilotest.com/fr/tests/calcul_mental2/play",
  "Calcul mental 3##PT":
    "https://www.pilotest.com/fr/tests/calcul_mental3/play",
  "Attention 3##PT": "https://www.pilotest.com/fr/tests/attention3/play",
  "Attention 1##PT": "https://www.pilotest.com/fr/tests/attention1/play",
  "Attention 2##PT": "https://www.pilotest.com/fr/tests/attention2/play",
  "Datme##PT": "https://www.pilotest.com/fr/tests/datme/play",
  "DECADI##PT": "https://www.pilotest.com/fr/tests/decadi/play",
  "Psychomoteur ENAC##PT": "https://www.pilotest.com/fr/tests/psychomot/play",
  "Lecture de courbes##PT": "https://www.pilotest.com/fr/tests/abaque/play",
  "TMRL 1##PT": "https://www.pilotest.com/fr/tests/tmrl1/play",
  "Cadrans##PT": "https://www.pilotest.com/fr/tests/cadrans/play",
  "TMRL 2##PT": "https://www.pilotest.com/fr/tests/tmrl2/play",
  "Test des compteurs##PT": "https://www.pilotest.com/fr/tests/compteurs/play",
  "Memory 2 Back##PT": "https://www.pilotest.com/fr/tests/m2back/play",
  "Memory 3 Back##PT": "https://www.pilotest.com/fr/tests/m3back/play",
  "Memory 4 Back##PT": "https://www.pilotest.com/fr/tests/m4back/play",
  "Camionneur 1##PT": "https://www.pilotest.com/fr/tests/camionneur1/play",
  "Camionneur 2##PT": "https://www.pilotest.com/fr/tests/camionneur2/play",
  "Camionneur 3##PT": "https://www.pilotest.com/fr/tests/camionneur3/play",
  "Comptage##PT": "https://www.pilotest.com/fr/tests/comptage/play",
  "Anglais présélection cadets Air France##PT":
    "https://www.pilotest.com/fr/tests/english/play",
  "DLR - PPT - Cubes 3D##PT":
    "https://www.pilotest.com/fr/tests/cubefolding/play",
  "Séries alphanumériques##PT": "https://www.pilotest.com/fr/tests/series/play",
  "Calcul mental 4##PT":
    "https://www.pilotest.com/fr/tests/calcul_mental4/play",
  "Virages##PT": "https://www.pilotest.com/fr/tests/virages/play",
  "Un mot sur deux##PT":
    "https://www.pilotest.com/fr/tests/un_mot_sur_deux/play",
  "Pair ou impair##PT": "https://www.pilotest.com/fr/tests/pair_ou_impair/play",
  "Grilles de calculs##PT":
    "https://www.pilotest.com/fr/tests/grille_calculs/play",
  "Trouvez l'intrus @Air France##PT":
    "https://www.pilotest.com/fr/tests/intrus_air_france/play",
  "M2 Back numérique##PT":
    "https://www.pilotest.com/fr/tests/m2back_numerique/play",
  "Empilements##PT": "https://www.pilotest.com/fr/tests/empilements/play",
  "Objets 3D##PT": "https://www.pilotest.com/fr/tests/objets3d/play",
  "Billes##PT": "https://www.pilotest.com/fr/tests/billes/play",
  "Formes et couleurs##PT": "https://www.pilotest.com/fr/tests/formes/play",
  "Airways##PT": "https://www.pilotest.com/fr/tests/airways/play",
  "Dominos##PT": "https://www.pilotest.com/fr/tests/dominos/play",
  "Boîtes à mots##PT": "https://www.pilotest.com/fr/tests/boxes/play",
  "Psychomoteur psy0 AF cadet##PT":
    "https://www.pilotest.com/fr/tests/psychomot0/play",
  "Séries logiques##PT":
    "https://www.pilotest.com/fr/tests/series_psy0_af/play",
  "COMPASS - Orientation spatiale##PT":
    "https://www.pilotest.com/fr/tests/bearing/play",
  "CUT-E - RBI##PT": "https://www.pilotest.com/fr/tests/rbi/play",
  "CUT-E - Balles en mouvements##PT":
    "https://www.pilotest.com/fr/tests/movingdots/play",
  "SAAIR - Lianes entremêlées##PT":
    "https://www.pilotest.com/fr/tests/jumbledpipes/play",
  "Formes à comparer##PT":
    "https://www.pilotest.com/fr/tests/shapecomparison/play",
  "CUT-E - Eviter les obstacles##PT":
    "https://www.pilotest.com/fr/tests/tubeflight/play",
  "DLR - VFF - Formes dissimulées##PT":
    "https://www.pilotest.com/fr/tests/hiddenshape/play",
  "DLR - ROT - Cubes##PT":
    "https://www.pilotest.com/fr/tests/cuberotation/play",
  "Couleurs identiques##PT":
    "https://www.pilotest.com/fr/tests/dotmatching/play",
  "Données de vol##PT": "https://www.pilotest.com/fr/tests/flightdata/play",
  "SAAIR - Géolocalisation##PT":
    "https://www.pilotest.com/fr/tests/maplocation/play",
  "Formes identiques##PT":
    "https://www.pilotest.com/fr/tests/matchingshape/play",
  "Grilles identiques##PT":
    "https://www.pilotest.com/fr/tests/gridscanning/play",
  "Test de stroop##PT": "https://www.pilotest.com/fr/tests/stroop/play",
  "Compter les avions##PT":
    "https://www.pilotest.com/fr/tests/aircraftcounting/play",
  "Lettres identiques##PT":
    "https://www.pilotest.com/fr/tests/matchingletters/play",
  "Perspectives##PT": "https://www.pilotest.com/fr/tests/perspectives/play",
  "Formes transformées##PT":
    "https://www.pilotest.com/fr/tests/mirroredshapes/play",
  "CUT-E - Comparaison de matrices##PT":
    "https://www.pilotest.com/fr/tests/matrixcomparison/play",
  "Puzzle##PT": "https://www.pilotest.com/fr/tests/puzzle/play",
  "CUT-E - Atterrissage multi-tâches##PT":
    "https://www.pilotest.com/fr/tests/runway/play",
  "PILAPT® - Multi-tâches##PT": "https://www.pilotest.com/fr/tests/total/play",
  "PILAPT® - Suivi ILS##PT": "https://www.pilotest.com/fr/tests/flight/play",
  "Opposition##PT": "https://www.pilotest.com/fr/tests/opposite/play",
  "PILAPT® - Pilotage ILS##PT": "https://www.pilotest.com/fr/tests/cross/play",
  "PILAPT® - Damier##PT": "https://www.pilotest.com/fr/tests/grid/play",
  "Série de nombres##PT": "https://www.pilotest.com/fr/tests/numseries/play",
  "PILAPT® - Cercles et carrés##PT":
    "https://www.pilotest.com/fr/tests/squarecircle/play",
  "Ami ou ennemi##PT": "https://www.pilotest.com/fr/tests/friend_or_foe/play",
  "Concentration sélective##PT":
    "https://www.pilotest.com/fr/tests/selconcentration/play",
  "PILAPT® - Structures##PT": "https://www.pilotest.com/fr/tests/frame/play",
  "Soustraction##PT": "https://www.pilotest.com/fr/tests/substraction/play",
  "SAAIR - Sons et couleurs##PT":
    "https://www.pilotest.com/fr/tests/colors/play",
  "Cercles##PT": "https://www.pilotest.com/fr/tests/circle/play",
  "Vérification##PT": "https://www.pilotest.com/fr/tests/checking/play",
  "SAAIR - Comparaison de cubes##PT":
    "https://www.pilotest.com/fr/tests/cubecompare/play",
  "Radar##PT": "https://www.pilotest.com/fr/tests/radar/play",
  "Diagrammes##PT": "https://www.pilotest.com/fr/tests/diagramming/play",
  "Addition de symboles##PT":
    "https://www.pilotest.com/fr/tests/symboladdition/play",
  "Radar inversé##PT":
    "https://www.pilotest.com/fr/tests/radarbacktracking/play",
  "CUT-E - Temps de réaction##PT":
    "https://www.pilotest.com/fr/tests/reactionrate/play",
  "CUT-E - Pot de fleur##PT":
    "https://www.pilotest.com/fr/tests/flowerpot/play",
  "CUT-E - Triangle##PT": "https://www.pilotest.com/fr/tests/triangle/play",
  "CUT-E - Mémoire##PT": "https://www.pilotest.com/fr/tests/memory/play",
  "CUT-E - Calculs à trous##PT":
    "https://www.pilotest.com/fr/tests/numeracy/play",
  "CUT-E - Logique inductive##PT":
    "https://www.pilotest.com/fr/tests/inductive_thinking/play",
  "COMPASS - Coordination (basique)##PT":
    "https://www.pilotest.com/fr/tests/basic_coordination/play",
  "COMPASS - Coordination##PT":
    "https://www.pilotest.com/fr/tests/coordination/play",
  "SAAIR - Carrés##PT": "https://www.pilotest.com/fr/tests/squares/play",
  "SAAIR - Vigilance##PT": "https://www.pilotest.com/fr/tests/vigilance/play",
  "FMS - Mémoire##PT": "https://www.pilotest.com/fr/tests/fms_memory/play",
  "FMS - Multi tâches##PT":
    "https://www.pilotest.com/fr/tests/fms_multitask/play",
  "COMPASS - Slalom##PT": "https://www.pilotest.com/fr/tests/slalom/play",
  "CUT-E - Forme manquante##PT":
    "https://www.pilotest.com/fr/tests/shapes/play",
  "Cercles et Points##PT":
    "https://www.pilotest.com/fr/tests/cercles_et_points/play",
  "Calculs et mémorisation##PT":
    "https://www.pilotest.com/fr/tests/calcul_memorisation/play",
  "Point mouvant##PT": "https://www.pilotest.com/fr/tests/reddot/play",
  "Mathématiques (en anglais)##PT":
    "https://www.pilotest.com/fr/tests/mathematics/play",
  "COMPASS - Mémoire à court terme##PT":
    "https://www.pilotest.com/fr/tests/short_memory/play",
  "COMPASS - Multi tâches##PT":
    "https://www.pilotest.com/fr/tests/data_multitask/play",
  "DLR - SKT - Triangles##PT":
    "https://www.pilotest.com/fr/tests/dots_and_colors/play",
  "DLR - OWT - Cadrans##PT": "https://www.pilotest.com/fr/tests/dials/play",
  "DLR - MEK - Mémoire visuelle##PT":
    "https://www.pilotest.com/fr/tests/visual_memory/play",
  "DLR - RMS - Mémoire suivie##PT":
    "https://www.pilotest.com/fr/tests/running_memory/play",
  "DLR - MIC - Pilotage aux instruments##PT":
    "https://www.pilotest.com/fr/tests/instruments/play",
  "DLR - DRT - Traitement de données##PT":
    "https://www.pilotest.com/fr/tests/squares_and_colors/play",
  "DLR/AF Cadets psy1 - VLR - Orientation spatiale##PT":
    "https://www.pilotest.com/fr/tests/spatial_orientation/play",
  "DLR - VMC - Mémoire visuelle##PT":
    "https://www.pilotest.com/fr/tests/mnback/play",
  "DLR - SFT - Tube dans un cube##PT":
    "https://www.pilotest.com/fr/tests/tube/play",
  "CUT-E - Un E et trois points##PT":
    "https://www.pilotest.com/fr/tests/e_with_three_dots/play",
  "Multitâche global##PT":
    "https://www.pilotest.com/fr/tests/multitasking/play",
  "CUT-E - Navigation et sens de l'orientation##PT":
    "https://www.pilotest.com/fr/tests/sense_of_direction/play",
  "INTERPERSONAL - PFD##PT": "https://www.pilotest.com/fr/tests/pfd/play",
  "INTERPERSONAL - Blocs de nombres##PT":
    "https://www.pilotest.com/fr/tests/number_blocks/play",
  "INTERPERSONAL - Balances##PT":
    "https://www.pilotest.com/fr/tests/weighing_scales/play",
  "INTERPERSONAL - Code à mémoriser##PT":
    "https://www.pilotest.com/fr/tests/code_memory/play",
  "INTERPERSONAL - Formes à mémoriser##PT":
    "https://www.pilotest.com/fr/tests/shapes_memory/play",
  "INTERPERSONAL - Test multitâche avec cadrans##PT":
    "https://www.pilotest.com/fr/tests/multitasking_with_instruments/play",
  "INTERPERSONAL - Combinaisons de caractères##PT":
    "https://www.pilotest.com/fr/tests/character_combination/play",
  "INTERPERSONAL - Multitâche et réservoirs colorés##PT":
    "https://www.pilotest.com/fr/tests/multitasking_with_color_tanks/play",
  "MOLLYMAWK - Labyrinthes##PT": "https://www.pilotest.com/fr/tests/maze/play",
  "INTERPERSONAL - Cubes animés##PT":
    "https://www.pilotest.com/fr/tests/cuberotating/play",
  "INTERPERSONAL - Empilements##PT":
    "https://www.pilotest.com/fr/tests/stack/play",
  "Voitures (basique)##PT":
    "https://www.pilotest.com/fr/tests/voitures_basic/play",
  "MOLLYMAWK - Mémoire immédiate##PT":
    "https://www.pilotest.com/fr/tests/mollymawk_memory/play",
  "INTERPERSONAL - Calcul de temps##PT":
    "https://www.pilotest.com/fr/tests/time_calculation/play",
  "Camionneur 123##PT": "https://www.pilotest.com/fr/tests/camionneur123/play",
  "Camionneur 123 (random)##PT":
    "https://www.pilotest.com/fr/tests/camionneur123_rdm/play",
  "Formes glissées##PT":
    "https://www.pilotest.com/fr/tests/formes_glissees/play",
  "Cubes 2D/3D - psy0 Air France##PT":
    "https://www.pilotest.com/fr/tests/cubes_psy0/play",
  "Memory 5 Back##PT": "https://www.pilotest.com/fr/tests/m5back/play",
  "Patrons de cubes - psy1 ENAC - pilotes pros##PT":
    "https://www.pilotest.com/fr/tests/cubes_psy1_pro/play",
  "Formes glissées - II##PT":
    "https://www.pilotest.com/fr/tests/formes_glissees2/play",
  "Mots en étoile##PT": "https://www.pilotest.com/fr/tests/mots_en_etoile/play",
  "Patrons de cubes - psy1 - Cadets AF##PT":
    "https://www.pilotest.com/fr/tests/cubes_psy1_cadets/play",
  "Attention 2 + Tâches Perturbatrices##PT":
    "https://www.pilotest.com/fr/tests/attention2_hard/play",
};
export const exerciseNameToLink = (exerciseName: string): string | null => {
  return (EXERCISE_NAME_TO_LINK as never)[exerciseName] ?? null;
};
