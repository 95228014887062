import { FC } from "react";

import { getLetterPosition } from "~/routes/alphabet/alphabet.ts";

export interface GridLettersProps {
  letters: string;
  selectedLetter?: string;
}

export const GridLetters: FC<GridLettersProps> = ({
  letters,
  selectedLetter = "",
}) => {
  return (
    <div className="grid grid-cols-3 md:grid-cols-10 gap-2 mb-8">
      {letters.split(",").map((letter, idx) => (
        <p
          key={`solution-${letter}-${idx}`}
          className={`text-2xl rounded-lg text-center border border-2  ${
            selectedLetter === letter
              ? "bg-amber-200 border-amber-500 text-amber-800"
              : getLetterPosition(letter) % 5 === 0
                ? "bg-slate-200"
                : "bg-slate-50"
          } `}
        >
          {letter}&nbsp;
          <span className="font-bold">{getLetterPosition(letter)}</span>
        </p>
      ))}
    </div>
  );
};
