import clsx from "clsx";
import { FC } from "react";
import colors from "tailwindcss/colors";

interface DimensionsDimensionGradientProps {
  stanineClass: number;
  size?: "md" | "lg";
}

export const DimensionsDimensionGradient: FC<
  DimensionsDimensionGradientProps
> = ({ stanineClass, size = "md" }) => (
  <>
    <div
      className={clsx("rounded-lg", size === "md" ? "h-6" : "h-8")}
      style={{
        backgroundImage: `linear-gradient(to right, ${colors.red[300]}, ${colors.orange[300]}, ${colors.amber[300]}, ${colors.yellow[300]}, ${colors.lime[300]}, ${colors.green[300]}, ${colors.emerald[300]}, ${colors.sky[300]}, ${colors.blue[300]})`,
      }}
    />
    <div
      className="absolute top-0 right-0 bg-white h-full opacity-90"
      style={{
        width: `${100 - (100 * stanineClass) / 9}%`,
      }}
    />
    <div className="absolute top-0 right-0 w-full h-full flex justify-between">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((stanineClass) => (
        <div
          key={`stanine-${stanineClass}`}
          className="[&:not(:first-child):not(:last-child)]:border-l-4 border-white"
        />
      ))}
    </div>
  </>
);
