import clsx from "clsx";
import { FC } from "react";

import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { backgroundClassNameFromStanineClass } from "~/routes/suivi/utils/backgroundClassNameFromStanineClass.ts";
import { borderClassNameFromStanineClass } from "~/routes/suivi/utils/borderClassNameFromStanineClass.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

interface ExercisesResultsMinMaxAvgProps {
  min: number;
  max: number;
  avg: number;
}

export const ExercisesResultsMinMaxAvg: FC<ExercisesResultsMinMaxAvgProps> = ({
  min,
  max,
  avg,
}) => {
  const { settings } = useSettings();

  const avgValue =
    settings?.table_display === "stanine_with_decimals"
      ? Math.trunc(avg)
      : Math.round(avg);

  return (
    <div className="flex items-center">
      <p
        className={clsx(
          "-mr-2 pr-2 w-8 h-6 flex items-center justify-center border rounded text-xs opacity-70 bg-white tabular-nums",
          textClassNameFromStanineClass(min),
          borderClassNameFromStanineClass(min),
        )}
      >
        {min}
      </p>
      <p
        className={clsx(
          "font-mono w-10 text-base text-center rounded z-10 tabular-nums flex items-baseline justify-center",
          backgroundClassNameFromStanineClass(avgValue),
        )}
      >
        <span className="font-medium">{avgValue}</span>
        {settings?.table_display === "stanine_with_decimals" && (
          <span className="text-xs">.{Math.round((avg % 1) * 10)}</span>
        )}
      </p>
      <p
        className={clsx(
          "-ml-2 pl-2 w-8 h-6 flex items-center justify-center border rounded text-xs opacity-70 bg-white tabular-nums",
          textClassNameFromStanineClass(max),
          borderClassNameFromStanineClass(max),
        )}
      >
        {max}
      </p>
    </div>
  );
};
