import { FC, useState } from "react";

import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Role } from "~/role.ts";
import { TrainingBaseView } from "~/routes/tangram/TrainingBaseView.tsx";

type APP_MODE = "FREE_TRAINING" | "TIMED_TRAINING";

export const Tangram: FC = () => {
  const [mode, setMode] = useState<APP_MODE>("FREE_TRAINING");

  const handleOnChangeMode = (mode: string) => {
    setMode(mode as APP_MODE);
  };

  return (
    <Wrapper
      requireRole={Role.Tangram}
      extended={true}
      header={
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold underline">Tangram</h1>
          <div className="flex items-center justify-center gap-3">
            <ScoreButton
              label="Tangram"
              exercise="tangram"
              variant="guess-all"
            />
            <Select value={mode} onValueChange={handleOnChangeMode}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner un mode…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="FREE_TRAINING">Entrainement</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      }
    >
      <TrainingBaseView withChrono={mode === "TIMED_TRAINING"} />
    </Wrapper>
  );
};
