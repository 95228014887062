interface PopupWindowOptionsArgs {
  windowName?: string;
  win?: Window;
  w?: number;
  h?: number;
  hideCurrentPage?: boolean;
  callback?: () => void;
  centerVertically?: boolean;
}

const defaultOptions: PopupWindowOptionsArgs = {
  windowName: "name",
  win: window,
  w: 960,
  h: 680,
  hideCurrentPage: false,
  centerVertically: true,
};

export const popupWindow = (url: string, options?: PopupWindowOptionsArgs) => {
  const { windowName, win, w, h, hideCurrentPage, callback, centerVertically } =
    {
      ...defaultOptions,
      ...options,
    };

  const y = centerVertically
    ? win!.top!.outerHeight / 2 + win!.top!.screenY - h! / 2
    : 128;
  const x = win!.top!.outerWidth / 2 + win!.top!.screenX - w! / 2;

  if (hideCurrentPage) {
    // Reducing opacity to concentrate on popup…
    document.body.classList.add("opacity-10");

    window.addEventListener("focus", function restoreOpacity() {
      callback?.();
      document.body.classList.remove("opacity-10");
      window.removeEventListener("focus", restoreOpacity);
    });
  }

  return win!.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
  );
};
