import { FC, ReactNode } from "react";

export interface BigButtonProps {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export const BigButton: FC<BigButtonProps> = ({
  children,
  onClick,
  disabled = false,
}) => (
  <button
    type="button"
    className="rounded-md bg-sky-50 w-full px-6 py-4 text-lg font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);
