import clsx from "clsx";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { FC, ReactNode } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { ExerciseWithIcon } from "~/routes/suivi/components/ExerciseWithIcon.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { DimensionAverages } from "~/routes/suivi/types/DimensionAverages.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

interface DimensionsDimensionTooltipProps {
  children: ReactNode;
  className?: string;
  dimension: DimensionAverages;
}

export const DimensionsDimensionTooltip: FC<
  DimensionsDimensionTooltipProps
> = ({ dimension, children, className }) => {
  const { settings, saveSettings } = useSettings();
  const { renamingsList } = useRenamings();

  return (
    <Tooltip delayDuration={250}>
      <TooltipTrigger
        className={clsx(
          "font-medium flex items-center gap-2 cursor-default",
          className,
        )}
      >
        {children}
      </TooltipTrigger>
      <TooltipContent>
        <div className="w-64 pb-1">
          <p className="text-sm font-medium border-b border-slate-600 pt-1 pb-1">
            {dimension.dimensionName}
          </p>
          <div className="flex flex-col gap-1 mt-2">
            {dimension.exercises.length === 0 && (
              <p>Aucun exercice de cette dimension réalisé.</p>
            )}
            {dimension.exercises.map((ex) => (
              <div
                key={ex.exerciseName}
                className="flex items-center justify-between gap-4"
              >
                <p className="inline-flex items-center gap-1 truncate">
                  <ExerciseWithIcon
                    exerciseName={ex.exerciseName}
                    customName={renamingsList[ex.exerciseName]}
                    withLink={false}
                  />
                </p>
                <p
                  className={clsx(
                    "tabular-nums font-semibold inline-flex items-center",
                    textClassNameFromStanineClass(ex.averageStanineClass),
                  )}
                >
                  <span>{ex.averageStanineClass.toFixed(1)}</span>
                  <EyeOffIcon
                    className="ml-2 w-3 h-3 shrink-0 text-slate-400 hover:text-slate-600 cursor-pointer"
                    onClick={() =>
                      saveSettings({
                        exercises_excluded_from_dimensions: [
                          ...(settings?.exercises_excluded_from_dimensions ??
                            []),
                          ex.exerciseName,
                        ],
                      })
                    }
                  />
                </p>
              </div>
            ))}
            <div />
            {dimension.excludedExercises.map((ex) => (
              <div key={ex} className="flex items-center justify-between gap-4">
                <p className="inline-flex items-center gap-1 truncate text-slate-400">
                  <ExerciseWithIcon
                    exerciseName={ex}
                    customName={renamingsList[ex]}
                    withLink={false}
                  />
                </p>
                <EyeIcon
                  onClick={() =>
                    saveSettings({
                      exercises_excluded_from_dimensions:
                        settings?.exercises_excluded_from_dimensions?.filter(
                          (e) => e !== ex,
                        ),
                    })
                  }
                  className="ml-1 w-3 h-3 shrink-0 text-slate-400 hover:text-slate-600 cursor-pointer"
                />
              </div>
            ))}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
