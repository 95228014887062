import { FC, useState } from "react";

import { TitleLabel } from "~/components/TitleLabel.tsx";
import { getPgcdFromString } from "~/routes/mathematiques/utils.ts";

export const Pgcd: FC = () => {
  const [expression, setExpression] = useState("");
  const [showResult, setShowResult] = useState(false);

  return (
    <div>
      <h3 className="text-xl font-semibold">PGCD</h3>
      <p>Détermine le PGCD entre plusieurs nombres.</p>
      <div className="grid grid-cols-1 gap-4 mt-4">
        <div>
          <TitleLabel className="mb-1">Nombres</TitleLabel>
          <div className="flex items-center gap-4">
            <input
              type="string"
              className="border-2 p-2 transition-colors duration-300 ease-in-out w-full rounded text-center text-2xl font-mono tracking-widest"
              placeholder="12 8 4 5"
              onChange={(e) => {
                if (showResult) {
                  setShowResult(false);
                }

                setExpression(e.currentTarget.value);
              }}
              value={expression}
            />
          </div>
          <TitleLabel className="mt-4 mb-1">PGCD</TitleLabel>

          <div className="overflow-hidden rounded-lg bg-slate-50 h-14">
            <div className="px-3 py-3 h-full flex justify-center items-center text-2xl tabular-nums">
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 10,
              }).format(getPgcdFromString(expression))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
