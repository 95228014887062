export const isResultInteger = (expression: string): boolean | null => {
  if (expression.trim() === "" || !expression.includes("/")) {
    return null;
  }

  try {
    // Remplacer les caractères division et multiplication par les opérateurs JavaScript correspondants
    const sanitizedExpression = expression.replace(
      /(\d+)(\/|\*)(\d+)/g,
      (_, left, operator, right) => {
        if (operator === "/") {
          return `${left} / ${right}`;
        }

        return _;
      },
    );

    const result = eval(sanitizedExpression);

    return Number.isInteger(result);
  } catch (error) {
    return null;
  }
};

function gcd(a: number, b: number): number {
  while (b !== 0) {
    const temp = b;
    b = a % b;
    a = temp;
  }

  return a;
}

function lcm(a: number, b: number): number {
  return (a * b) / gcd(a, b);
}

function lcmOfArray(numbers: number[]): number {
  return numbers.reduce((acc, curr) => lcm(acc, curr), 1);
}

function gcdOfArray(numbers: number[]): number {
  return numbers.reduce((acc, curr) => gcd(acc, curr));
}

export const getPgcdFromString = (input: string): number => {
  const numbers = input.split(" ").map(Number);

  return gcdOfArray(numbers);
};

export const getPpcmFromString = (input: string): number => {
  const numbers = input.split(" ").map(Number);

  return lcmOfArray(numbers);
};
