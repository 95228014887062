import clsx from "clsx";
import { FC } from "react";

import { ExerciseResults } from "~/routes/suivi/types/ExerciseResults.ts";
import { borderClassNameFromStanineClass } from "~/routes/suivi/utils/borderClassNameFromStanineClass.ts";
import { textClassNameFromPercentScore } from "~/routes/suivi/utils/textClassNameFromPercentScore.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";
import { Enums } from "~/supabase.types.ts";

interface ExercisesResultsLastAttemptProps {
  lastAttempt: ExerciseResults["lastAttempt"];
  display?: Enums<"graph_display">;
  className?: string;
}

export const ExercisesResultsLastAttempt: FC<
  ExercisesResultsLastAttemptProps
> = ({ lastAttempt, display, className }) => (
  <div
    className={clsx(
      className,
      "w-[64px] h-6 items-center justify-end hidden last-of-type:flex sm:flex",
    )}
  >
    {lastAttempt &&
      (display?.includes("stanine") ? (
        <div className="flex items-center justify-end">
          <p
            className={clsx(
              "inline-flex items-center justify-center font-mono w-10 h-6 text-sm sm:text-xs text-center rounded tabular-nums bg-white border",
              textClassNameFromStanineClass(lastAttempt.stanineClass),
              borderClassNameFromStanineClass(lastAttempt.stanineClass),
            )}
          >
            <span className="font-medium">{lastAttempt.stanineClass}</span>
          </p>
        </div>
      ) : (
        <p className="flex items-center justify-between tabular-nums text-xs">
          <span
            className={textClassNameFromPercentScore(lastAttempt.percentScore)}
          >
            {lastAttempt.percentScore} %
          </span>
        </p>
      ))}
  </div>
);
