import { FC, useEffect, useState } from "react";

import { BigButton } from "~/components/BigButton.tsx";
import { Toggle } from "~/components/Toggle.tsx";
import { useClock } from "~/hooks/useClock.ts";
import { useSpeak } from "~/hooks/useSpeak.ts";
import { GridElements } from "~/routes/nombres-premiers/GridElements.tsx";
import {
  getElementsFromList,
  IndexedElement,
} from "~/routes/nombres-premiers/numbers.ts";

export interface LearningBaseViewProps {
  elements: string;
}

export const LearningBaseView: FC<LearningBaseViewProps> = ({ elements }) => {
  const [shouldSpeak, setShouldSpeak] = useState(false);
  const { canSpeak, speak } = useSpeak(shouldSpeak);

  const [useAuto, setUseAuto] = useState(false);

  const { clock } = useClock({
    interval: useAuto ? (shouldSpeak ? 2 : 1) : 0,
    onRestart: () => handleClickNext(),
  });

  const [showElements, setShowElements] = useState(true);

  const [loop] = useState<IndexedElement[]>(getElementsFromList(elements));
  const [currentElement, setCurrentElement] = useState<
    IndexedElement | undefined
  >();

  useEffect(() => {
    if (loop.length) {
      setCurrentElement(loop[0]);
    }
  }, [loop]);

  // Speech-to-text useEffect()…
  useEffect(() => {
    if (!currentElement) {
      return;
    }

    speak(`${currentElement.element}`);
  }, [currentElement]);

  useEffect(() => {
    if (currentElement && shouldSpeak) {
      speak(`${currentElement.element}`);
    }
  }, [shouldSpeak]);

  const handleClickNext = () => {
    if (!currentElement) {
      return;
    }

    setCurrentElement(
      loop[
        currentElement.index === loop.length - 1 ? 0 : currentElement.index + 1
      ],
    );
  };

  return (
    <div>
      <div className="mb-8 grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="col-span-2">
          <h2 className="mb-2 uppercase font-semibold flex justify-between">
            <span>Nombre</span>
            {canSpeak && (
              <span
                className="cursor-pointer"
                title="Activer la lecture des lettres"
                onClick={() => setShouldSpeak(!shouldSpeak)}
              >
                {shouldSpeak ? <>🔔</> : <>🔕</>}
              </span>
            )}
          </h2>
          <div className="overflow-hidden rounded-lg bg-slate-50 h-64">
            <div className="px-3 py-3 h-full flex justify-center items-center text-9xl space-x-4 slashed-zero tabular-num font-mono">
              <span>{currentElement?.element}</span>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2 flex justify-between">
            <span className="uppercase font-semibold">Contrôles</span>
            {clock !== 0 && (
              <span className="text-slate-500 tabular-nums cursor-pointer">
                ⌛ {clock}
              </span>
            )}
          </h2>
          <div className="flex flex-col gap-4">
            <div className="mb-4">
              <BigButton onClick={handleClickNext}>Suivant</BigButton>
            </div>
            <Toggle
              label="Auto Suivant"
              description="Défilement automatique"
              toggled={useAuto}
              onToggle={setUseAuto}
            />
          </div>
        </div>
      </div>
      <div>
        <h2
          className="mb-2 uppercase font-semibold cursor-pointer"
          onClick={() => setShowElements(!showElements)}
        >
          Nombres (Afficher/Cacher)
        </h2>
        {showElements && (
          <GridElements
            elements={elements.split(",").filter((el) => !!el)}
            selectedElement={currentElement?.element.toString()}
          />
        )}
      </div>
    </div>
  );
};
