export const randomWithExclusions = (
  min: number,
  max: number,
  exclude: number[] = [],
) => {
  let random;

  while (!random) {
    const x = Math.floor(Math.random() * (max - min + 1)) + min;
    if (exclude.indexOf(x) === -1) random = x;
  }

  return random;
};
