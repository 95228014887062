import {
  BoxIcon,
  BrainIcon,
  JoystickIcon,
  MessageSquareIcon,
  ScanEyeIcon,
  SigmaIcon,
} from "lucide-react";

import { Dimension } from "~/routes/suivi/types/Dimension.ts";

export const dimensions: Dimension[] = [
  {
    name: "Attention",
    icon: <ScanEyeIcon className="w-5 h-5 text-slate-500" />,
    exerciseNames: [
      "Attention 1##PT",
      "Attention soutenue (Cadrans et réponses fixes)##EPL",
      "Attention soutenue (Cadrans et réponses mélangés)##EPL",
      "Attention 2##PT",
      "Comptage de symbole I##EPL",
      "Attention 3##PT",
      "Comptage de symbole II##EPL",
    ],
  },
  {
    name: "Numérique",
    icon: <SigmaIcon className="w-5 h-5 text-slate-500" />,
    exerciseNames: [
      "Mathématiques##PT",
      "Problèmes mathématiques##EPL",
      "Calcul mental 1##PT",
      "Calcul mental (1, 2, 3, 4) (Niveau 1 – Réponse libre)##EPL",
      "Calcul mental 2##PT",
      "Calcul mental (1, 2, 3, 4) (Niveau 3 – Plus petit intervalle)##EPL",
      "Calcul mental 3##PT",
      "Calcul mental (1, 2, 3, 4) (Niveau 2 – Équations)##EPL",
      "Calcul mental 4##PT",
      "Calcul mental (1, 2, 3, 4) (Niveau 4 – Tous les intervalles)##EPL",
    ],
  },
  {
    name: "Raisonnement",
    icon: <BrainIcon className="w-5 h-5 text-slate-500" />,
    exerciseNames: [
      "Test des compteurs##PT",
      "Lecture de compteurs##EPL",
      "Matrices de Raven##PT",
      "Matrices progressives de Raven™ (Mélange de niveau progressif)##EPL",
      "EFG##PT",
      "Efficience générale##EPL",
    ],
  },
  {
    name: "Spatial",
    icon: <BoxIcon className="w-5 h-5 text-slate-500" />,
    exerciseNames: [
      "Tangram##PT",
      "Tangram (12 minutes, 4x4)##EPL",
      "Tangram (10 minutes, 6x6)##EPL",
      "Angles##PT",
      "Angles – Montres##EPL",
      "Patrons de cubes - psy1 - Cadets AF##PT",
      "Dépliage de cube (Niveau 1 – 3 cubes, alphabet latin)##EPL",
      "Dépliage de cube (Niveau 2 – 2 cubes, alphabet runique)##EPL",
    ],
  },
  {
    name: "Multitâches",
    icon: <JoystickIcon className="w-5 h-5 text-slate-500" />,
    exerciseNames: [
      "Psychomoteur ENAC##PT",
      "Psychomoteur (Entraînement normal : Cadets AF/ENAC EPL)##EPL",
      "Psychomoteur (Examen : (6 phases, 18 minutes))##EPL",
    ],
  },
  {
    name: "Verbal",
    icon: <MessageSquareIcon className="w-5 h-5 text-slate-500" />,
    exerciseNames: ["Lecture de textes##PT", "Compréhension de lecture##EPL"],
  },
];
