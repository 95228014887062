import { FC, useRef } from "react";
import Keyboard, {
  KeyboardOptions,
  SimpleKeyboard,
} from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface VirtualNumericKeyboardProps {
  onChange: KeyboardOptions["onChange"];
  onEnterPress: () => void;
}

export const VirtualNumericKeyboard: FC<VirtualNumericKeyboardProps> = ({
  onChange,
  onEnterPress,
}) => {
  const keyboardRef = useRef<SimpleKeyboard | null>(null);

  return (
    <div className="fixed left-0 w-full bottom-0 lg:left-72 lg:w-[calc(100%-18rem)]">
      <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        onChange={onChange}
        onKeyPress={(btn) => {
          if (btn === "{enter}") {
            keyboardRef?.current?.clearInput();
            onEnterPress();
          }
        }}
        layout={{
          default: ["1 2 3", "4 5 6", "7 8 9", "[- {bksp}] 0 {enter}"],
        }}
        display={{
          "{bksp}": "Supp.",
          "{space}": " ",
          "{enter}": "Valider",
        }}
        theme="hg-theme-default hg-layout-numeric numeric-theme"
      />
    </div>
  );
};
