import { format, startOfDay } from "date-fns";
import { groupBy, sortBy } from "lodash";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { htmlDateToDate } from "~/routes/suivi/utils/htmlDateToDate.ts";
import { Enums } from "~/supabase.types.ts";

export interface AggregatedResultByDate {
  date: string;
  [key: string]: string | number | { stanine: number; percentage: number };
}

export const resultsByDate = (
  attempts: ExerciseAttempt[] = [],
  fromDate: string,
  mode: Enums<"graph_compute">,
): AggregatedResultByDate[] => {
  const fromDateCheck = htmlDateToDate(fromDate);

  const attemptsByDate = groupBy(
    attempts.filter((attempt) => {
      if (!fromDateCheck) {
        return true;
      }

      return attempt.at.getTime() >= fromDateCheck.getTime();
    }),
    (attempt) => format(startOfDay(attempt.at), "yy-MM-dd"),
  );

  const calculateValue = (
    currentValue: number | null,
    newValue: number,
  ): number => {
    if (currentValue === null) {
      return newValue;
    }

    if (mode === "mean") {
      return (currentValue + newValue) / 2;
    }

    return Math.max(currentValue, newValue);
  };

  const results: AggregatedResultByDate[] = Object.keys(attemptsByDate).reduce(
    (acc: AggregatedResultByDate[], curr: string) => {
      const dateResults = attemptsByDate[curr];

      const aggregatedResults = dateResults.reduce(
        (
          accResults: {
            [key: string]: { stanine: number; percentage: number };
          },
          currResult: ExerciseAttempt,
        ) => {
          const stanineValue = currResult.stanineClass;
          const percentValue = currResult.percentScore;

          const currentValues = accResults[currResult.name] || {
            stanine: null,
            percentage: null,
          };

          return {
            ...accResults,
            [currResult.name]: {
              stanine: calculateValue(currentValues.stanine, stanineValue),
              percentage: calculateValue(
                currentValues.percentage,
                percentValue,
              ),
            },
          };
        },
        {},
      );

      return [
        ...acc,
        {
          date: curr,
          ...Object.keys(aggregatedResults).reduce(
            (resultAcc, key) => {
              resultAcc[key] = aggregatedResults[key];
              return resultAcc;
            },
            {} as { [key: string]: { stanine: number; percentage: number } },
          ),
        },
      ];
    },
    [],
  );

  return sortBy(results, "date");
};
