import { random } from "lodash";

import { IntervalWithDistance } from "~/routes/cm2/types.ts";

export const createRandomIntervalWithDistance = (
  min: number = -999,
  max: number = 999,
): IntervalWithDistance => {
  const randomMin = random(min, max);
  const randomMax = random(min, max);

  // Ensure randomMin is less than or equal to randomMax
  const intervalMin = Math.min(randomMin, randomMax);
  const intervalMax = Math.max(randomMin, randomMax);

  const interval = `[${intervalMin} ; ${intervalMax}]`;
  const distance = intervalMax - intervalMin;
  const distanceModulo = (intervalMax - intervalMin) % 100;

  return { interval, distance, distanceModulo };
};
