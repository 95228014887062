import { FC } from "react";

interface AngleProps {
  angle: number;
  rotation?: number;
  withText?: boolean;
  className?: string;
}

export const Angle: FC<AngleProps> = ({
  angle,
  rotation = 0,
  withText = true,
  className,
}) => {
  const startX = 50;
  const startY = 50;
  const length = 30;

  // Calculate the end points for the two lines
  const endX1 =
    startX + length * Math.cos(Math.PI / 2 - (angle / 2) * (Math.PI / 180));
  const endY1 =
    startY - length * Math.sin(Math.PI / 2 - (angle / 2) * (Math.PI / 180));

  const endX2 =
    startX + length * Math.cos(Math.PI / 2 + (angle / 2) * (Math.PI / 180));
  const endY2 =
    startY - length * Math.sin(Math.PI / 2 + (angle / 2) * (Math.PI / 180));

  // Calculate the text positions
  const textOffset = 10;
  const textX1 =
    endX1 + textOffset * Math.cos(Math.PI / 2 - (angle / 2) * (Math.PI / 180));
  const textY1 =
    endY1 - textOffset * Math.sin(Math.PI / 2 - (angle / 2) * (Math.PI / 180));

  const textX2 =
    endX2 + textOffset * Math.cos(Math.PI / 2 + (angle / 2) * (Math.PI / 180));
  const textY2 =
    endY2 - textOffset * Math.sin(Math.PI / 2 + (angle / 2) * (Math.PI / 180));

  return (
    <div className={className}>
      <svg
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 100 100"
        className="w-full h-full"
      >
        <g transform={`rotate(${rotation}, ${startX}, ${startY})`}>
          <line
            x1={startX}
            y1={startY}
            x2={endX1}
            y2={endY1}
            stroke="black"
            strokeWidth="1"
          />
          <line
            x1={startX}
            y1={startY}
            x2={endX2}
            y2={endY2}
            stroke="black"
            strokeWidth="1"
          />
          {withText && (
            <>
              <text
                dominantBaseline="middle"
                textAnchor="middle"
                x={textX1}
                y={textY1}
                transform={`rotate(${-rotation}, ${textX1}, ${textY1})`}
                fontSize={12}
              >
                A
              </text>
              <text
                dominantBaseline="middle"
                textAnchor="middle"
                x={textX2}
                y={textY2}
                transform={`rotate(${-rotation}, ${textX2}, ${textY2})`}
                fontSize={12}
              >
                O
              </text>
            </>
          )}
        </g>
      </svg>
    </div>
  );
};
