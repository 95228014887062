import clsx from "clsx";
import { FC } from "react";

import { getAllAngles, getComplementaryAngle } from "~/routes/angles/utils.ts";

interface ComplementaryAnglesHintProps {
  wrapperClassName?: string;
  highlightAngle?: number;
}

export const ComplementaryAnglesHint: FC<ComplementaryAnglesHintProps> = ({
  wrapperClassName = "grid grid-cols-3 sm:grid-cols-6 gap-2 mt-6",
  highlightAngle,
}) => (
  <div className={wrapperClassName}>
    {getAllAngles().map((angle) => {
      const shouldHighlightAngle =
        highlightAngle !== undefined &&
        (angle === highlightAngle ||
          angle === getComplementaryAngle(highlightAngle));

      return (
        <p
          className={clsx(
            "tabular-nums rounded space-x-2 text-center py-2",
            shouldHighlightAngle
              ? "ring-2 ring-amber-400 bg-amber-100 ring-offset-2"
              : "bg-slate-100",
          )}
        >
          <span>{angle}°</span>
          <span>→</span>
          <span className="font-semibold">{getComplementaryAngle(angle)}°</span>
        </p>
      );
    })}
  </div>
);
