import clsx from "clsx";
import { FC } from "react";

import { DimensionsDimensionGradient } from "~/routes/suivi/components/DimensionsDimensionGradient.tsx";
import { DimensionsDimensionTooltip } from "~/routes/suivi/components/DimensionsDimensionTooltip.tsx";
import { DimensionAverages } from "~/routes/suivi/types/DimensionAverages.ts";
import { textClassNameFromStanineClass } from "~/routes/suivi/utils/textClassNameFromStanineClass.ts";

interface DimensionsDimensionProps {
  dimension: DimensionAverages;
}

export const DimensionsDimension: FC<DimensionsDimensionProps> = ({
  dimension,
}) => (
  <div className="flex items-center gap-2">
    <div className="w-2/12">
      <DimensionsDimensionTooltip dimension={dimension} className="text-sm">
        {dimension.dimensionIcon} {dimension.dimensionName}
      </DimensionsDimensionTooltip>
    </div>
    <div className="grow relative">
      {/*<DimensionsDimensionPlain*/}
      {/*  stanineClass={dimension.averageStanineClass}*/}
      {/*/>*/}
      <DimensionsDimensionGradient
        stanineClass={dimension.averageStanineClass}
      />
    </div>
    <div>
      <DimensionsDimensionTooltip
        dimension={dimension}
        className={clsx(
          "pl-2 tabular-nums",
          textClassNameFromStanineClass(dimension.averageStanineClass),
        )}
      >
        {dimension.averageStanineClass.toFixed(1)}
      </DimensionsDimensionTooltip>
    </div>
  </div>
);
