export const htmlDateToDate = (htmlDate: string): Date | null => {
  if (htmlDate === "") {
    return null;
  }

  const [fromYear, fromMonth, fromDay] = htmlDate.split("-");

  return new Date(
    parseInt(fromYear),
    parseInt(fromMonth) - 1,
    parseInt(fromDay)
  );
};
