import { BoxesIcon } from "lucide-react";
import { FC } from "react";

import { Button } from "~/components/ui/button.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { BoitesMotsTheme } from "~/routes/boites-mots/components/BoitesMotsTheme.tsx";
import { getDefaultThemes } from "~/routes/boites-mots/vocabulary.ts";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface BoitesMotsSettingsProps {
  themes: Tables<"boites_mots_themes">[];
  onRefreshThemes: () => void;
}

export const BoitesMotsSettings: FC<BoitesMotsSettingsProps> = ({
  themes,
  onRefreshThemes,
}) => {
  const { user } = useCurrentUser();

  const handleUseDefaultThemes = async () => {
    const defaultThemes = getDefaultThemes();

    await supabase.from("boites_mots_themes").insert(
      defaultThemes.map(({ theme, words }) => ({
        user_id: user.id,
        theme,
        words,
      })),
    );

    onRefreshThemes();
  };

  const handleOnAddTheme = async () => {
    await supabase
      .from("boites_mots_themes")
      .insert({
        user_id: user.id,
        theme: "(0) nom du nouveau thème",
        words: ["Exemple A", "Exemple B"],
      })
      .select()
      .single();

    onRefreshThemes();
  };

  const handleOnUpdateTheme = async (
    id: Tables<"boites_mots_themes">["id"],
    theme: string,
    words: string[],
  ) => {
    await supabase
      .from("boites_mots_themes")
      .update({ theme, words })
      .eq("id", id);

    onRefreshThemes();
  };

  const handleOnDeleteTheme = async (
    id: Tables<"boites_mots_themes">["id"],
  ) => {
    await supabase.from("boites_mots_themes").delete().eq("id", id);

    onRefreshThemes();
  };

  const handleOnDeleteAllThemes = async () => {
    if (
      confirm(
        "Souhaitez-vous vraiment supprimer TOUS vos thèmes et mots associés ? Cette action est irréversible.",
      )
    ) {
      await supabase.from("boites_mots_themes").delete().eq("user_id", user.id);

      onRefreshThemes();
    }
  };

  return (
    <div>
      {themes.length > 0 && (
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-2xl font-medium">Vos thèmes</h3>
          <Button size="sm" onClick={handleOnAddTheme}>
            Ajouter un thème
          </Button>
        </div>
      )}

      {themes.length === 0 ? (
        <div className="mt-16 flex flex-col items-center">
          <p className="mx-auto">
            <BoxesIcon className="block w-20 h-20 text-blue-600" />
          </p>
          <p className="mt-6 text-lg font-medium">
            Vous ne disposez d'aucun thème !
          </p>
          <p className="max-w-md text-center text-slate-500 text-sm mt-1">
            Vous pouvez utiliser les thèmes par défaut proposés par l'auteur du
            site ou créer votre premier thème directement.
          </p>
          <div className="flex items-center gap-3 mt-6">
            <Button onClick={handleUseDefaultThemes}>
              Utiliser les thèmes par défaut
            </Button>
            <Button size="sm" variant="outline" onClick={handleOnAddTheme}>
              Créer mon premier thème
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-3">
            {themes.map((theme) => (
              <BoitesMotsTheme
                key={`theme-${theme.theme}`}
                theme={theme}
                onUpdateTheme={handleOnUpdateTheme}
                onDeleteTheme={handleOnDeleteTheme}
              />
            ))}
          </div>
          <div className="mt-4">
            <Button
              size="sm"
              variant="destructive"
              onClick={handleOnDeleteAllThemes}
            >
              Supprimer tous les thèmes
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
