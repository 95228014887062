import { sample } from "lodash";

import { Formula, FormulaArgs } from "~/routes/calculs/Formula.ts";

export interface IndexedElement {
  element: Formula;
  index: number;
  isCorrect: boolean;
  status?: "won" | "lost";
}

const FORMULA_TEMPLATES: FormulaArgs[] = [
  {
    template: "x * y",
    values: { x: { min: 0, max: 10 }, y: { min: 0, max: 10 } },
  },
  {
    template: "x + y",
    values: { x: { min: -100, max: 100 }, y: { min: -100, max: 100 } },
  },
  {
    template: "y ** 2",
    values: { y: { min: 2, max: 20 } },
  },
  {
    template: "x + y ** 2",
    values: { x: { min: 2, max: 50 }, y: { min: 2, max: 20 } },
  },
  {
    template: "x + y + z",
    values: {
      x: { min: -50, max: 50 },
      y: { min: -50, max: 50 },
      z: { min: -50, max: 50 },
    },
  },
  {
    template: "x + (y + z)",
    values: {
      x: { min: -50, max: 50 },
      y: { min: 2, max: 50 },
      z: { min: -50, max: 50 },
    },
  },
  {
    template: "x - (y + z)",
    values: {
      x: { min: -50, max: 50 },
      y: { min: 2, max: 50 },
      z: { min: -50, max: 50 },
    },
  },
  {
    template: "x * (y + z)",
    values: {
      x: { min: -20, max: 20 },
      y: { min: 2, max: 50 },
      z: { min: -50, max: 50 },
    },
  },
  {
    template: "x - y * z",
    values: {
      x: { min: -100, max: 100 },
      y: { min: 2, max: 10 },
      z: { min: 2, max: 20 },
    },
  },
  {
    template: "x - y * z",
    values: {
      x: { min: -100, max: 100 },
      y: { min: 2, max: 20 },
      z: { min: 2, max: 10 },
    },
  },
  {
    template: "x ** 2",
    values: {
      x: { min: 0, max: 10 },
    },
  },
  {
    template: "x ** 3",
    values: {
      x: { min: 0, max: 7 },
    },
  },
  {
    template: "x ** 5",
    values: {
      x: { min: 0, max: 5 },
    },
  },
];

export const getElementsToGuess = (limit = 100) => {
  const elements: IndexedElement[] = [];

  for (let index = 0; index < limit; index++) {
    const element = sample(FORMULA_TEMPLATES);

    if (!element) {
      continue;
    }

    elements.push({
      index,
      element: new Formula(element),
      isCorrect: Math.random() < 0.75,
    });
  }

  return elements;
};
