import { FC } from "react";
import { useEffectOnce } from "react-use";

import { nuke } from "~/routes/suivi/utils/nuke.ts";

export const ResetPage: FC = () => {
  useEffectOnce(() => {
    nuke();
  });

  return <p>Réinitialisation en cours…</p>;
};
