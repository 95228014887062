import { random } from "lodash";
import { useState } from "react";
import { useStopwatch } from "react-timer-hook";

import { Button } from "~/components/Button.tsx";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import { ToggleShort } from "~/components/ToggleShort.tsx";
import { BasicScore, useScores } from "~/hooks/useScores.ts";
import { Angle } from "~/routes/angles/Angle.tsx";
import { ComplementaryAnglesHint } from "~/routes/angles/ComplementaryAnglesHint.tsx";
import {
  getAllAngles,
  getComplementaryAngle,
  getRandomAngle,
} from "~/routes/angles/utils.ts";

interface ComplementaryAnglesAnswer extends BasicScore {
  angle: number;
}

export const ComplementaryAnglesView = () => {
  const { saveScore } = useScores();
  const { totalSeconds, reset: resetStopwatch } = useStopwatch({
    autoStart: true,
  });

  const [currentAngle, setCurrentAngle] = useState(getRandomAngle(false));
  const [rotation, setRotation] = useState(random(0, 359));
  const [showSolutions, setShowSolutions] = useState(false);
  const [answers, setAnswers] = useState<ComplementaryAnglesAnswer[]>([]);

  const handleOnClickAnswer = (angle: number) => {
    const answer: ComplementaryAnglesAnswer = {
      angle: currentAngle,
      success: angle === getComplementaryAngle(currentAngle),
      timing: totalSeconds,
    };

    setAnswers([answer, ...answers]);

    saveScore("angles", "complementary_angles", {
      success: answer.success,
      timing: answer.timing,
    });

    setCurrentAngle(getRandomAngle(true));
    setRotation(random(0, 359));
    resetStopwatch();
  };

  return (
    <div>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <TitleLabel className="mb-1">Angle</TitleLabel>
          <div className="relative flex justify-center bg-slate-100 rounded">
            <div className="w-56">
              <Angle
                angle={currentAngle}
                rotation={rotation}
                withText={false}
              />
            </div>
            <div className="absolute bottom-0 right-0 text-3xl sm:text-5xl font-semibold px-4 py-4">
              {currentAngle}°
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-1 flex justify-between">
            <TitleLabel>Angle complémentaire</TitleLabel>
          </h2>
          <div className="overflow-hidden rounded-lg bg-slate-50">
            <div className="grid grid-cols-12 gap-2 px-3 py-2">
              {getAllAngles(false).map((angle) => (
                <Button
                  key={`angle-${angle}`}
                  onClick={() => handleOnClickAnswer(angle)}
                >
                  {angle}
                </Button>
              ))}
            </div>
          </div>
          <div className="mt-2 flex flex-col space-y-1">
            <ToggleShort
              label="Afficher les solutions"
              toggled={showSolutions}
              onToggle={setShowSolutions}
            />
          </div>
        </div>
      </div>
      {showSolutions && (
        <ComplementaryAnglesHint highlightAngle={currentAngle} />
      )}
      {answers.length > 0 && (
        <div className="mt-6 mb-6">
          <TitleLabel className="mb-1">Résultats</TitleLabel>
          <ul className="gap-1 grid grid-cols-2 sm:grid-cols-5">
            {answers.map((score, idx) => (
              <li
                key={`answer-${idx}`}
                className={`rounded text-center py-1 text-sm ${
                  score.success
                    ? "bg-green-100 text-green-800 "
                    : "bg-red-100 text-red-800"
                }`}
              >
                {score.angle}° → {getComplementaryAngle(score.angle)}°{" "}
                <span className="text-xs">({score.timing} sec)</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
