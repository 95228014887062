import { useCallback, useEffect, useState } from "react";

interface UseClockArgs {
  interval: number;
  onRestart: () => void;
}

export const useClock = ({ interval = 5, onRestart }: UseClockArgs) => {
  const [clock, setClock] = useState(interval);

  const [pause, togglePause] = useState(false);

  const updateClock = useCallback(() => {
    if (pause || clock === 0) {
      return;
    }

    if (clock === 1) {
      onRestart();
      return setClock(interval);
    }

    setClock((prev) => prev - 1);
  }, [clock, interval, onRestart]);

  useEffect(() => {
    const clockInterval = setInterval(() => updateClock(), 1000);

    return () => clearInterval(clockInterval);
  }, [updateClock]);

  useEffect(() => {
    setClock(interval);
  }, [interval]);

  const handleRestart = () => {
    setClock(interval);
  };

  const handleStop = () => {
    setClock(0);
  };

  return {
    clock,
    isPaused: pause,
    restartClock: handleRestart,
    stopClock: handleStop,
    pause: () => togglePause(true),
    resume: () => togglePause(false),
  };
};
