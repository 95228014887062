import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { SUIVI_DEFAULT_GROUP } from "~/routes/suivi/components/Navigation.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";

export const suiviCurrentGroupAtom = atomWithStorage(
  "suivi:currentGroup",
  SUIVI_DEFAULT_GROUP,
);

export const suiviHideThankYouBanner = atomWithStorage(
  "suiviHideThankYouBanner",
  false,
);

export const suiviPilotestAttempts = atom<ExerciseAttempt[]>([]);

export const suiviEplTestAttempts = atom<ExerciseAttempt[]>([]);

export const suiviAllAttempts = atom((get) => [
  ...get(suiviPilotestAttempts),
  ...get(suiviEplTestAttempts),
]);
