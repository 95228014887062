import { RemoveFormattingIcon, TextCursorIcon } from "lucide-react";
import { FC } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { useRenamings } from "~/routes/suivi/providers/RenamingsProvider.tsx";
import { supabase } from "~/supabase.ts";

interface ExercisesResultsRenameExerciseProps {
  exerciseName: string;
}

export const ExercisesResultsRenameExercise: FC<
  ExercisesResultsRenameExerciseProps
> = ({ exerciseName }) => {
  const { user } = useCurrentUser();

  const { renamings, renamingsList, refreshRenamings } = useRenamings();

  const handleRenameExercise = (newName: string) => {
    supabase
      .from("suivi_renamings")
      .insert({
        user_id: user.id,
        exercise_original: exerciseName,
        exercise_renamed: newName,
      })
      .then(refreshRenamings);
  };

  const handleUnrenameExercise = () => {
    const renamingToDelete = renamings.find(
      (r) => r.exercise_original === exerciseName,
    );

    if (!renamingToDelete) {
      return;
    }

    supabase
      .from("suivi_renamings")
      .delete()
      .eq("id", renamingToDelete.id)
      .then(refreshRenamings);
  };

  if (renamingsList[exerciseName]) {
    return (
      <Tooltip delayDuration={250}>
        <TooltipTrigger
          className="text-slate-300 hover:text-slate-500 cursor-pointer"
          asChild
        >
          <RemoveFormattingIcon
            className="hidden sm:group-hover:block w-4 h-4"
            onClick={handleUnrenameExercise}
            data-umami-event="suivi:removeRenaming"
          />
        </TooltipTrigger>
        <TooltipContent>Remettre le nom original</TooltipContent>
      </Tooltip>
    );
  }

  return (
    <div className="flex items-center justify-center gap-2">
      <Tooltip delayDuration={250}>
        <TooltipTrigger
          className="text-slate-300 hover:text-slate-500"
          onClick={() => {
            const newName = prompt(
              "Comment souhaitez-vous renommer cet exercice ?",
            );

            if (!newName) {
              return;
            }

            handleRenameExercise(newName);
          }}
        >
          <TextCursorIcon className="hidden sm:group-hover:block w-4 h-4" />
        </TooltipTrigger>
        <TooltipContent>Renommer cet exercice</TooltipContent>
      </Tooltip>
    </div>
  );
};
