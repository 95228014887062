export enum Role {
  Admin = "admin",
  AdminQuizlet = "admin-quizlet",
  Angles = "angles",
  CM1 = "cm1",
  CM2 = "cm2",
  Quizlet = "quizlet",
  Tangram = "tangram",
  Memory = "memory",
}
