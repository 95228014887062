import { isEqual, sortBy } from "lodash";
import { LibraryBigIcon, SettingsIcon } from "lucide-react";
import { FC, useEffect, useMemo } from "react";
import { useSet } from "react-use";

import { Button } from "~/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu.tsx";
import { ExercisesResultsHeader } from "~/routes/suivi/components/ExercisesResultsHeader.tsx";
import { ExercisesResultsHeaderEmpty } from "~/routes/suivi/components/ExercisesResultsHeaderEmpty.tsx";
import ExercisesResultsRows from "~/routes/suivi/components/ExercisesResultsRows.tsx";
import { SUIVI_DEFAULT_GROUP } from "~/routes/suivi/components/Navigation.tsx";
import { useAttempts } from "~/routes/suivi/hooks/useAttempts.tsx";
import { useEplTestAttempts } from "~/routes/suivi/hooks/useEplTestAttempts.tsx";
import { usePilotestAttempts } from "~/routes/suivi/hooks/usePilotestAttempts.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import {
  DEFAULT_STATS_COLUMNS,
  StatsColumn,
} from "~/routes/suivi/types/StatsColumn.ts";
import { getExerciseResultsFromExerciseAttempts } from "~/routes/suivi/utils/getExerciseResultsFromExerciseAttempts.ts";
import { sortExerciseResults } from "~/routes/suivi/utils/sortExerciseResults.ts";
import { Enums } from "~/supabase.types.ts";

interface ExercisesResultsProps {
  group: string;
}

export const ExercisesResults: FC<ExercisesResultsProps> = ({ group }) => {
  const { settings, saveSettings } = useSettings();
  const { attempts } = useAttempts();
  const { attempts: pilotestAttempts } = usePilotestAttempts();
  const { attempts: eplTestAttempts } = useEplTestAttempts();
  const { groups } = useGroups();

  const [columns, { has: hasColumn, toggle: toggleColumn }] =
    useSet<StatsColumn>(
      new Set(
        (settings?.table_columns as StatsColumn[]) ?? DEFAULT_STATS_COLUMNS,
      ),
    );

  const exerciseResults = useMemo(
    () =>
      getExerciseResultsFromExerciseAttempts(
        attempts,
        settings?.display_from_date ?? "",
      ),
    [attempts, settings?.display_from_date],
  );

  useEffect(() => {
    if (
      !settings?.table_display &&
      isEqual(sortBy([...columns]), sortBy(DEFAULT_STATS_COLUMNS))
    ) {
      return;
    }

    saveSettings({ table_columns: [...columns] });
  }, [columns, saveSettings, settings?.table_display]);

  const exerciseRows = sortExerciseResults(
    exerciseResults,
    group === SUIVI_DEFAULT_GROUP
      ? []
      : (groups.find((g) => g.name === group)?.exercises ?? []),
    groups.find((g) => g.name === group)?.use_custom_order ?? false,
  );

  return (
    <div>
      <div className="border rounded">
        <div className="flex items-center justify-between border-b px-3 py-1.5 bg-slate-200">
          <p className="font-medium text-2xl inline-flex items-center gap-2.5 pl-1">
            <LibraryBigIcon className="text-slate-600" />
            <span>Classes</span>
          </p>
          <div className="hidden sm:flex sm:items-center sm:gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <SettingsIcon className="w-4 h-4" />
                  <span className="ml-2">Paramètres</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Affichage</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuCheckboxItem
                  checked={hasColumn("distance")}
                  onCheckedChange={() => toggleColumn("distance")}
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Dernière session
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={hasColumn("general")}
                  onCheckedChange={() => toggleColumn("general")}
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Général
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={hasColumn("7days")}
                  onCheckedChange={() => toggleColumn("7days")}
                  onSelect={(evt) => evt.preventDefault()}
                >
                  7 jours
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={hasColumn("3days")}
                  onCheckedChange={() => toggleColumn("3days")}
                  onSelect={(evt) => evt.preventDefault()}
                >
                  3 jours
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={hasColumn("today")}
                  onCheckedChange={() => toggleColumn("today")}
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Aujourd'hui
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  checked={hasColumn("lastAttempt")}
                  onCheckedChange={() => toggleColumn("lastAttempt")}
                  onSelect={(evt) => evt.preventDefault()}
                >
                  Dernier résultat
                </DropdownMenuCheckboxItem>
                <DropdownMenuLabel>Mesure</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={settings?.table_display ?? "stanine"}
                  onValueChange={(display) =>
                    saveSettings({
                      table_display: display as Enums<"graph_display">,
                    })
                  }
                >
                  <DropdownMenuRadioItem
                    value="stanine"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Stanine (arrondi)
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="stanine_with_decimals"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Stanine (décimal)
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem
                    value="percentage"
                    onSelect={(evt) => evt.preventDefault()}
                  >
                    Pourcentage
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="hidden sm:flex pl-3 pr-2 items-center justify-between">
            <div className="w-1/2" />
            <div className="w-1/2 flex justify-end">
              {hasColumn("general") && (
                <ExercisesResultsHeader
                  title="Général"
                  subtitle={
                    settings?.table_display === "percentage"
                      ? "Moyenne"
                      : "Min/Moy/Max"
                  }
                />
              )}
              {hasColumn("7days") && (
                <ExercisesResultsHeader
                  title="7 jours"
                  subtitle={
                    settings?.table_display === "percentage"
                      ? "Moyenne"
                      : "Min/Moy/Max"
                  }
                />
              )}
              {hasColumn("3days") && (
                <ExercisesResultsHeader
                  title="3 jours"
                  subtitle={
                    settings?.table_display === "percentage"
                      ? "Moyenne"
                      : "Min/Moy/Max"
                  }
                />
              )}
              {hasColumn("today") && (
                <ExercisesResultsHeader
                  title="Aujourd'hui"
                  subtitle={
                    settings?.table_display === "percentage"
                      ? "Moyenne"
                      : "Min/Moy/Max"
                  }
                />
              )}
              {hasColumn("lastAttempt") && <ExercisesResultsHeaderEmpty />}
            </div>
          </div>
          <ExercisesResultsRows
            exercises={exerciseRows}
            hasColumn={hasColumn}
            withDrag={group !== SUIVI_DEFAULT_GROUP}
            group={group}
          />
        </div>
      </div>
      <p className="mt-2 text-xs text-slate-400">
        <span className="font-medium">
          {new Intl.NumberFormat("fr-FR").format(attempts.length)} essais
          réalisés
        </span>{" "}
        ({new Intl.NumberFormat("fr-FR").format(pilotestAttempts.length)} sur
        Pilotest et{" "}
        {new Intl.NumberFormat("fr-FR").format(eplTestAttempts.length)} sur
        EPLtest).
      </p>
    </div>
  );
};
