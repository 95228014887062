import { FC } from "react";

import { ProgressStatus } from "~/components/ProgressStatus.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";

interface ExercisesResultsStatusProps {
  distance: number | undefined;
}

export const ExercisesResultsStatus: FC<ExercisesResultsStatusProps> = ({
  distance,
}) => (
  <p className="hidden sm:flex items-center justify-end mr-1">
    <Tooltip delayDuration={0}>
      <TooltipTrigger className="cursor-default">
        <ProgressStatus distance={distance === undefined ? null : distance} />
      </TooltipTrigger>
      <TooltipContent>
        {distance !== undefined ? (
          <>
            {distance} {distance > 1 ? "jours" : "jour"} depuis le dernier essai
          </>
        ) : (
          "Pas d'essai trouvé depuis la date sélectionnée"
        )}
      </TooltipContent>
    </Tooltip>
  </p>
);
