import { FC } from "react";

interface ExercisesResultsHeaderProps {
  title: string;
  subtitle: string;
}

export const ExercisesResultsHeader: FC<ExercisesResultsHeaderProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="w-[128px] sm:flex flex-col items-end justify-center py-1.5 space-y-0.5 hidden last-of-type:flex">
      <p className="text-sm font-medium uppercase">{title}</p>
      <p className="text-[10px] uppercase text-slate-400 tracking-wider">
        {subtitle}
      </p>
    </div>
  );
};
