import clsx from "clsx";
import { useAtom } from "jotai";
import { random } from "lodash";
import { RabbitIcon, SnailIcon } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";

import { ScoreButton } from "~/components/ScoreButton.tsx";
import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Slider } from "~/components/ui/slider.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { useScores } from "~/hooks/useScores.ts";
import { Role } from "~/role.ts";
import {
  memoryInverseModeAtom,
  memorySpeedAtom,
} from "~/routes/memory/atoms.ts";
import { NumberDisplay } from "~/routes/memory/NumberDisplay.tsx";
import { randomIntArrayInRange, reverseString } from "~/routes/memory/utils.ts";

export const Memory: FC = () => {
  const { saveScore } = useScores();

  const [mode, setMode] = useAtom(memoryInverseModeAtom);
  const [speed, setSpeed] = useAtom(memorySpeedAtom);

  const [numbers, setNumbers] = useState(
    randomIntArrayInRange(
      0,
      9,
      mode === "random" ? random(3, 8) : parseInt(mode),
    ),
  );

  const [hasStarted, setHasStarted] = useState(false);
  const [isDisplayComplete, setIsDisplayComplete] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [inputValue, setInputValue] = useState("");

  const handleComplete = () => {
    setIsDisplayComplete(true);
  };

  const handleCheckResult = useCallback(() => {
    setIsCorrect(reverseString(numbers.join("")) === inputValue);

    saveScore("memory", `inverse:${mode}:${speed}`, {
      success: reverseString(numbers.join("")) === inputValue,
      timing: 0,
    });
  }, [inputValue, mode, numbers, saveScore, speed]);

  const handleRestart = useCallback(() => {
    setNumbers(
      randomIntArrayInRange(
        0,
        9,
        mode === "random" ? random(3, 8) : parseInt(mode),
      ),
    );
    setIsDisplayComplete(false);
    setIsCorrect(null);
    setInputValue("");
  }, [mode]);

  useEffect(() => {
    setHasStarted(false);
    handleRestart();
  }, [handleRestart, mode]);

  useEffect(() => {
    if (inputValue.length === numbers.length) {
      handleCheckResult();
    }
  }, [handleCheckResult, inputValue.length, numbers.length]);

  return (
    <Wrapper
      requireRole={Role.Memory}
      header={
        <div className="flex items-center justify-between">
          <h1 className="w-full flex justify-between items-center">
            <span className="text-3xl font-bold underline">Memory</span>
            <div className="flex items-center justify-center gap-3">
              <ScoreButton
                label={`Memory (${mode}, ${speed} sec)`}
                exercise="memory"
                variant={`inverse:${mode}:${speed}`}
                period="daily"
                withTimings={false}
                filter={() => true}
              />
              <Select value={mode} onValueChange={setMode}>
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner un mode…" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="3">3 chiffres</SelectItem>
                  <SelectItem value="4">4 chiffres</SelectItem>
                  <SelectItem value="5">5 chiffres</SelectItem>
                  <SelectItem value="6">6 chiffres</SelectItem>
                  <SelectItem value="7">7 chiffres</SelectItem>
                  <SelectItem value="8">8 chiffres</SelectItem>
                  <SelectItem value="9">9 chiffres</SelectItem>
                  <SelectItem value="10">10 chiffres</SelectItem>
                  <SelectItem value="random">
                    Aléatoire (3 - 8 chiffres)
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </h1>
        </div>
      }
    >
      {!hasStarted ? (
        <div className="mt-8 sm:mt-32 text-center">
          <Button
            onClick={() => {
              setHasStarted(true);
              setIsCorrect(null);
              setInputValue("");
            }}
          >
            Commencer
          </Button>
          <div>
            <div className="w-48 flex items-center justify-center gap-3 mx-auto mt-6">
              <p className="shrink-0 text-xs text-slate-400">
                <RabbitIcon />
              </p>
              <Slider
                min={1}
                max={3}
                step={0.5}
                value={[speed]}
                onValueChange={([newSpeed]) => setSpeed(newSpeed)}
              />
              <p className="shrink-0 text-xs text-slate-400">
                <SnailIcon />
              </p>
            </div>
            <p className="text-center text-sm mt-1 text-slate-800">
              {speed} sec
            </p>
          </div>
        </div>
      ) : (
        <div className="mt-8 sm:mt-24 flex flex-col items-center justify-center">
          {!isDisplayComplete ? (
            <NumberDisplay
              numbers={numbers}
              speed={speed}
              onComplete={handleComplete}
            />
          ) : (
            <div className="max-w-screen-lg">
              <Input
                className={clsx(
                  "text-3xl py-2 w-60 tabular-nums text-center",
                  isCorrect === true && "bg-green-100 text-green-500",
                  isCorrect === false && "bg-red-100 text-red-500",
                )}
                value={inputValue}
                onChange={(evt) => setInputValue(evt.currentTarget.value)}
                onKeyUp={(evt) => {
                  if (evt.key === "Enter") {
                    handleRestart();
                  }
                }}
                autoFocus={true}
              />
              {isCorrect === null ? (
                <Button
                  className="w-full mt-3"
                  onClick={handleCheckResult}
                  type="button"
                >
                  Valider
                </Button>
              ) : (
                <div className="text-center mt-2 ">
                  {isCorrect ? (
                    <p className="text-lg font-semibold text-green-500">
                      Correct &nbsp;🎉
                    </p>
                  ) : (
                    <p className="text-lg font-semibold text-red-500">
                      Faux ! ({reverseString(numbers.join(""))})
                    </p>
                  )}
                  <button
                    type="button"
                    className="text-xs underline hover:no-underline mt-4"
                    onClick={handleRestart}
                  >
                    Suivant (ou touche Entrée)
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};
