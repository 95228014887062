import { groupBy } from "lodash";

enum THEMES {
  Aero = "Aérien",
  Animal = "Animaux",
  Arbres = "Arbres",
  Arme = "Armes",
  Bijou = "Bijoux",
  Bâti = "Bâti",
  Chaussures = "Chaussures",
  Compositeur = "Compositeurs",
  Couleurs = "Couleurs",
  Créatures = "Créatures",
  Danses = "Danses",
  Ecrivains = "Écrivains",
  Equitation = "Equitation",
  Fleuves = "Fleuves",
  Fruits = "Fruits",
  Instrument = "Instruments",
  Jeu = "Jeux",
  Meubles = "Meubles",
  Mineral = "Minéraux",
  Oiseau = "Oiseaux",
  Outil = "Outils",
  Plantes = "Plantes",
  Poissons = "Poissons",
  Realisateur = "Réalisateurs",
  Sports = "Sports",
  Sucreries = "Sucreries",
  Terre = "Terre",
  Theatre = "Théâtre",
  Villes = "Villes",
}

export interface VocabularyWord {
  theme: THEMES;
  word: string;
}

export const allWords: VocabularyWord[] = [
  { theme: THEMES.Arbres, word: "Cyprès" },
  { theme: THEMES.Arbres, word: "Sycamore" },
  { theme: THEMES.Arbres, word: "Ajonc" },
  { theme: THEMES.Arbres, word: "Cèdre" },
  { theme: THEMES.Arbres, word: "Avocatier" },
  { theme: THEMES.Arbres, word: "If" },
  { theme: THEMES.Arbres, word: "Conifère" },
  { theme: THEMES.Arbres, word: "Bigarreau" },
  { theme: THEMES.Arbres, word: "Mélèze" },

  { theme: THEMES.Terre, word: "Plaine" },
  { theme: THEMES.Terre, word: "Ravin" },
  { theme: THEMES.Terre, word: "Écueil" },
  { theme: THEMES.Terre, word: "Alluvion" },
  { theme: THEMES.Terre, word: "Caverne" },

  { theme: THEMES.Plantes, word: "Muguet" },
  { theme: THEMES.Plantes, word: "Cyclamen" },
  { theme: THEMES.Plantes, word: "Zinnia" },
  { theme: THEMES.Plantes, word: "Edelweiss" },
  { theme: THEMES.Plantes, word: "Narcisse" },
  { theme: THEMES.Plantes, word: "Colchique" },
  { theme: THEMES.Plantes, word: "Azalée" },
  { theme: THEMES.Plantes, word: "Liseron" },
  { theme: THEMES.Plantes, word: "Bette" },
  { theme: THEMES.Plantes, word: "Fève" },
  { theme: THEMES.Plantes, word: "Endive" },
  { theme: THEMES.Plantes, word: "Dahlia" },
  { theme: THEMES.Plantes, word: "Anémone" },
  { theme: THEMES.Plantes, word: "Misère" },
  { theme: THEMES.Plantes, word: "Oeillet" },
  { theme: THEMES.Plantes, word: "Primevère" },
  { theme: THEMES.Plantes, word: "Myosotis" },
  { theme: THEMES.Plantes, word: "Glycine" },
  { theme: THEMES.Plantes, word: "Arum" },
  { theme: THEMES.Plantes, word: "Airelle" },

  { theme: THEMES.Créatures, word: "Silène" },
  { theme: THEMES.Créatures, word: "Sirène" },
  { theme: THEMES.Créatures, word: "Griffon" },
  { theme: THEMES.Créatures, word: "Korrigan" },
  { theme: THEMES.Créatures, word: "Nymphe" },
  { theme: THEMES.Créatures, word: "Sauvageon" },
  { theme: THEMES.Créatures, word: "Succube" },

  { theme: THEMES.Mineral, word: "Citrine" },
  { theme: THEMES.Mineral, word: "Grès" },
  { theme: THEMES.Mineral, word: "Opale" },
  { theme: THEMES.Mineral, word: "Turquoise" },
  { theme: THEMES.Mineral, word: "Gypse" },

  { theme: THEMES.Couleurs, word: "Prusse" },
  { theme: THEMES.Couleurs, word: "Violine" },
  { theme: THEMES.Couleurs, word: "Lilas" },

  { theme: THEMES.Chaussures, word: "Escarpin" },
  { theme: THEMES.Chaussures, word: "Guêtre" },
  { theme: THEMES.Chaussures, word: "Cothurne" },
  { theme: THEMES.Chaussures, word: "Poulaine" },
  { theme: THEMES.Chaussures, word: "Santiag" },
  { theme: THEMES.Chaussures, word: "Savate" },

  { theme: THEMES.Villes, word: "Tirana" },
  { theme: THEMES.Villes, word: "Canberra" },

  { theme: THEMES.Fruits, word: "Nèfle" },
  { theme: THEMES.Fruits, word: "Chataigne" },
  { theme: THEMES.Fruits, word: "Cacahuète" },

  { theme: THEMES.Poissons, word: "Espadon" },
  { theme: THEMES.Poissons, word: "Merlu" },
  { theme: THEMES.Poissons, word: "Roussette" },
  { theme: THEMES.Poissons, word: "Turbot" },
  { theme: THEMES.Poissons, word: "Flétan" },
  { theme: THEMES.Poissons, word: "Épinoche" },
  { theme: THEMES.Poissons, word: "Murène" },
  { theme: THEMES.Poissons, word: "Gardon" },
  { theme: THEMES.Poissons, word: "Lote" },

  { theme: THEMES.Equitation, word: "Johdpur" },

  { theme: THEMES.Fleuves, word: "Limpopo" },
  { theme: THEMES.Fleuves, word: "Indus" },
  { theme: THEMES.Fleuves, word: "Moskva" },
  { theme: THEMES.Fleuves, word: "Orénoque" },
  { theme: THEMES.Fleuves, word: "Amour" },
  { theme: THEMES.Fleuves, word: "Mekong" },
  { theme: THEMES.Fleuves, word: "Columbia" },
  { theme: THEMES.Fleuves, word: "Murray" },
  { theme: THEMES.Fleuves, word: "Niger" },
  { theme: THEMES.Fleuves, word: "Jourdain" },

  { theme: THEMES.Danses, word: "Mazurka" },
  { theme: THEMES.Danses, word: "Sardane" },
  { theme: THEMES.Danses, word: "Slow" },
  { theme: THEMES.Danses, word: "Cancan" },
  { theme: THEMES.Danses, word: "Rock" },
  { theme: THEMES.Danses, word: "Fandango" },
  { theme: THEMES.Danses, word: "Bourrée" },
  { theme: THEMES.Danses, word: "Claquettes" },
  { theme: THEMES.Danses, word: "Mazurka" },
  { theme: THEMES.Danses, word: "Boogie" },
  { theme: THEMES.Danses, word: "Gigue" },
  { theme: THEMES.Danses, word: "Bourrée" },

  { theme: THEMES.Meubles, word: "Marquise" },
  { theme: THEMES.Meubles, word: "Dos d'âne" },
  { theme: THEMES.Meubles, word: "Baguier" },

  { theme: THEMES.Bâti, word: "Pilastre" },
  { theme: THEMES.Bâti, word: "Aile" },
  { theme: THEMES.Bâti, word: "Appentis" },
  { theme: THEMES.Bâti, word: "Embrasure" },
  { theme: THEMES.Bâti, word: "Vassistas" },
  { theme: THEMES.Bâti, word: "Chéneau" },

  { theme: THEMES.Sports, word: "Aikado" },
  { theme: THEMES.Sports, word: "Savate" },

  { theme: THEMES.Ecrivains, word: "Breton" },
  { theme: THEMES.Ecrivains, word: "Bazin" },
  { theme: THEMES.Ecrivains, word: "Rimbaud" },
  { theme: THEMES.Ecrivains, word: "Leroux" },
  { theme: THEMES.Ecrivains, word: "Pascal" },
  { theme: THEMES.Ecrivains, word: "Cohen" },
  { theme: THEMES.Ecrivains, word: "Rousseau" },
  { theme: THEMES.Ecrivains, word: "Aymé" },
  { theme: THEMES.Ecrivains, word: "Perrault" },
  { theme: THEMES.Ecrivains, word: "Camus" },
  { theme: THEMES.Ecrivains, word: "Gary" },
  { theme: THEMES.Ecrivains, word: "Rostand" },
  { theme: THEMES.Ecrivains, word: "Kessel" },
  { theme: THEMES.Ecrivains, word: "Cohen" },
  { theme: THEMES.Ecrivains, word: "Taine" },

  { theme: THEMES.Sucreries, word: "Pogne" },
  { theme: THEMES.Sucreries, word: "Crème" },
  { theme: THEMES.Sucreries, word: "Bugne" },

  { theme: THEMES.Theatre, word: "Cintre" },
  { theme: THEMES.Theatre, word: "Mystère" },
  { theme: THEMES.Theatre, word: "Saynète" },
  { theme: THEMES.Theatre, word: "Farce" },
  { theme: THEMES.Theatre, word: "Odéon" },

  { theme: THEMES.Bijou, word: "Rivière" },
  { theme: THEMES.Bijou, word: "Sautoir" },

  { theme: THEMES.Arme, word: "Onagre" },
  { theme: THEMES.Arme, word: "Fusée" },
  { theme: THEMES.Arme, word: "Gourdin" },
  { theme: THEMES.Arme, word: "Coutelas" },
  { theme: THEMES.Arme, word: "Fourche" },
  { theme: THEMES.Arme, word: "Boulet" },

  { theme: THEMES.Aero, word: "Efis" },

  { theme: THEMES.Instrument, word: "Guimbarde" },
  { theme: THEMES.Instrument, word: "Mélodica" },
  { theme: THEMES.Instrument, word: "Bugle" },
  { theme: THEMES.Instrument, word: "Bugle" },
  { theme: THEMES.Instrument, word: "Hélicon" },
  { theme: THEMES.Instrument, word: "Crécelle" },

  { theme: THEMES.Oiseau, word: "Pinson" },
  { theme: THEMES.Oiseau, word: "Vanneau" },
  { theme: THEMES.Oiseau, word: "Oie" },
  { theme: THEMES.Oiseau, word: "Choucas" },
  { theme: THEMES.Oiseau, word: "Ara" },
  { theme: THEMES.Oiseau, word: "Héron" },
  { theme: THEMES.Oiseau, word: "Roitelet" },
  { theme: THEMES.Oiseau, word: "Passereau" },
  { theme: THEMES.Oiseau, word: "Petit-Duc" },
  { theme: THEMES.Oiseau, word: "Perdreau" },
  { theme: THEMES.Oiseau, word: "Fou" },
  { theme: THEMES.Oiseau, word: "Bouvreuil" },
  { theme: THEMES.Oiseau, word: "Mainate" },

  { theme: THEMES.Animal, word: "Jars" },

  { theme: THEMES.Outil, word: "Varlope" },
  { theme: THEMES.Outil, word: "Meule" },

  { theme: THEMES.Realisateur, word: "Tourneur" },
  { theme: THEMES.Realisateur, word: "Gance" },
  { theme: THEMES.Realisateur, word: "Lubitsch" },
  { theme: THEMES.Realisateur, word: "Chabrol" },
  { theme: THEMES.Realisateur, word: "Miller" },
  { theme: THEMES.Realisateur, word: "Annaud" },

  { theme: THEMES.Jeu, word: "Osselets" },
  { theme: THEMES.Jeu, word: "Tricycle" },
  { theme: THEMES.Jeu, word: "Croquet" },
  { theme: THEMES.Jeu, word: "Piquet" },

  { theme: THEMES.Compositeur, word: "Torelli" },
  { theme: THEMES.Compositeur, word: "Bizet" },
  { theme: THEMES.Compositeur, word: "Bruckner" },
  { theme: THEMES.Compositeur, word: "Gounod" },
  { theme: THEMES.Compositeur, word: "Offenbach" },
];

export const getDefaultThemes = () =>
  Object.entries(groupBy(allWords, "theme")).map(([theme, words]) => ({
    theme,
    words: words.map((w) => w.word),
  }));
