import clsx from "clsx";
import { FC, ReactNode } from "react";

interface TitleLabelProps {
  children: ReactNode;
  className?: string;
}

export const TitleLabel: FC<TitleLabelProps> = ({ children, className }) => (
  <p
    className={clsx(
      "uppercase font-medium text-sm text-slate-400 tracking-wider",
      className,
    )}
  >
    {children}
  </p>
);
