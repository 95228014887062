import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import {
  AtSignIcon,
  Binary,
  BookA,
  Boxes,
  Brackets,
  Calculator,
  CircleHelp,
  Grid2x2X,
  Hash,
  HeartHandshakeIcon,
  Link as LinkLucide,
  LogOutIcon,
  Shapes,
  SquareStack,
  Trophy,
  UnlockIcon,
  Watch,
} from "lucide-react";
import { FC, Suspense, useEffect, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Drawer } from "~/components/Drawer.tsx";
import { ScrollTop } from "~/components/ScrollTop.tsx";
import { TooltipProvider } from "~/components/ui/tooltip.tsx";
import { useLatestVersionCheck } from "~/hooks/useLatestVersionCheck.tsx";
import {
  CurrentUserProvider,
  useCurrentUser,
} from "~/providers/CurrentUserProvider.tsx";
import { DismissalsProvider } from "~/providers/DismissalsProvider.tsx";
import { DrawerProvider } from "~/providers/DrawerProvider.tsx";
import { SupabaseProvider } from "~/providers/SupabaseProvider.tsx";
import { Role } from "~/role.ts";
import { supabase } from "~/supabase.ts";

const navigation = [
  { name: "Suivi", href: "/suivi", icon: Trophy, isBigger: true },
  {
    name: "Alphabet",
    href: "/alphabet",
    badge: (
      <span className="text-[10px] text-slate-500 border rounded px-1.5 py-0.5">
        PSY0
      </span>
    ),
    icon: BookA,
  },
  {
    name: "Nombres Premiers",
    href: "/nombres-premiers",
    badge: (
      <span className="text-[10px] text-slate-500 border rounded px-1.5 py-0.5">
        PSY0
      </span>
    ),
    icon: Hash,
  },
  {
    name: "Calculs",
    href: "/calculs",
    badge: (
      <span className="text-[10px] text-slate-500 border rounded px-1.5 py-0.5">
        PSY0
      </span>
    ),
    icon: Grid2x2X,
  },
  {
    name: "Quizlet",
    href: "/quizlet",
    badge: (
      <span className="text-[10px] text-slate-500 border rounded px-1.5 py-0.5">
        PSY0
      </span>
    ),
    icon: CircleHelp,
    requireRole: Role.Quizlet,
  },
  {
    name: "Boites à mots",
    href: "/boites-mots",
    badge: (
      <span className="text-[10px] text-slate-500 border rounded px-1.5 py-0.5">
        PSY0
      </span>
    ),
    icon: Boxes,
  },
  {
    name: "CM1",
    href: "/cm1",
    badge: (
      <span className="text-[10px] text-slate-600 border rounded px-1.5 py-0.5">
        PSY1
      </span>
    ),
    icon: Binary,
    requireRole: Role.CM1,
  },
  {
    name: "CM2",
    href: "/cm2",
    badge: (
      <span className="text-[10px] text-slate-600 border rounded px-1.5 py-0.5">
        PSY1
      </span>
    ),
    icon: Brackets,
    requireRole: Role.CM2,
  },
  {
    name: "Angles",
    href: "/angles",
    badge: (
      <span className="text-[10px] text-slate-600 border rounded px-1.5 py-0.5">
        PSY1
      </span>
    ),
    icon: Watch,
    requireRole: Role.Angles,
  },
  {
    name: "Memory",
    href: "/memory",
    badge: (
      <span className="text-[10px] text-slate-600 border rounded px-1.5 py-0.5">
        PSY1
      </span>
    ),
    icon: SquareStack,
    requireRole: Role.Memory,
  },
  {
    name: "Tangram",
    href: "/tangram",
    badge: (
      <span className="text-[10px] text-slate-600 border rounded px-1.5 py-0.5">
        PSY1
      </span>
    ),
    icon: Shapes,
    requireRole: Role.Tangram,
  },
  {
    name: "Mathématiques",
    href: "/mathematiques",
    badge: (
      <span className="text-[10px] text-slate-600 border rounded px-1.5 py-0.5">
        PSY1
      </span>
    ),
    icon: Calculator,
  },
  {
    name: "Liens",
    href: "/liens",
    icon: LinkLucide,
  },
];

const Layout: FC = () => {
  const location = useLocation();

  const { canAccess } = useCurrentUser();
  useLatestVersionCheck();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const link = navigation.find((link) =>
      location.pathname.includes(link.href),
    );

    if (!link) {
      return;
    }

    document.title = `${link.name} – KD Tools`;
  }, [location.pathname]);

  const authenticatedLinks = useMemo(
    () =>
      navigation.filter(
        (link) => !link.requireRole || canAccess(link.requireRole),
      ),
    [canAccess],
  );

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  return (
    <div>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-10 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-slate-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </button>
              </div>
            </TransitionChild>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pt-6 pb-2">
              <nav className="flex flex-1 flex-col mt-4">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2">
                      {authenticatedLinks.map((item) => (
                        <li
                          key={item.name}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <Link
                            to={item.href}
                            className={clsx(
                              location.pathname === item.href
                                ? "bg-slate-50 text-blue-600"
                                : "text-slate-700 hover:bg-slate-50 hover:text-blue-600",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className={clsx(
                                location.pathname === item.href
                                  ? "text-blue-600"
                                  : "text-slate-400 group-hover:text-blue-600",
                                "h-6 w-6 shrink-0",
                              )}
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-slate-200 bg-slate-50 px-6">
          <div className="mx-auto mt-6">
            <Link to="/" className="flex items-end gap-4">
              <img src="/ico.png" className="w-16 drop-shadow" />
              {/*<span className="text-xl font-medium -mt-4">KD Tools</span>*/}
            </Link>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-3.5 pl-2">
                  {authenticatedLinks.map((item) => (
                    <li
                      key={item.name}
                      className="flex justify-between items-center"
                    >
                      <Link
                        to={item.href}
                        className={clsx(
                          location.pathname.includes(item.href)
                            ? "bg-slate-50 text-blue-600"
                            : "text-slate-700 hover:bg-slate-50 hover:text-blue-600",
                          "group flex items-center justify-center gap-x-3 rounded-md font-semibold leading-6",
                          item.isBigger ? "text-md mb-1.5" : "text-sm",
                        )}
                      >
                        <item.icon
                          aria-hidden="true"
                          className={clsx(
                            location.pathname.includes(item.href)
                              ? "text-blue-600"
                              : "text-slate-500 group-hover:text-blue-600 shrink-0",
                            item.isBigger ? "h-5.5 w-5.5" : "h-5 w-5",
                          )}
                        />
                        <span className="inline-flex items-center gap-1.5">
                          {item.name}
                          {item.requireRole && (
                            <UnlockIcon className="w-3 h-3 text-slate-300" />
                          )}
                        </span>
                      </Link>
                      {item.badge && item.badge}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto px-6 py-6">
                <div className="text-xs text-slate-400 space-y-3 leading-5">
                  <p>
                    Site réalisé par un candidat aux sélections Cadets Air
                    France et non affilié à Air France, Pilotest ou EPLtest.
                  </p>
                  <p className="flex items-center gap-3">
                    <a
                      className="text-black underline hover:no-underline font-medium opacity-50 hover:opacity-100 inline-flex items-center gap-1"
                      href="https://forms.fillout.com/t/vJdHp6jTEqus"
                      data-umami-event="global:contact"
                      target="_blank"
                    >
                      <AtSignIcon size={16} />
                      <span>Contact</span>
                    </a>
                    <a
                      className="text-black underline hover:no-underline font-medium opacity-50 hover:opacity-100 inline-flex items-center gap-1"
                      href="https://www.paypal.com/donate/?hosted_button_id=TJTCW9ZUJ7DS8"
                      data-umami-event="global:donate"
                      target="_blank"
                    >
                      <HeartHandshakeIcon size={16} />
                      <span>Soutenir</span>
                    </a>
                  </p>
                  <p className="flex items-center gap-3">
                    <button
                      onClick={handleLogout}
                      className="text-red-500 underline hover:no-underline font-medium opacity-50 hover:opacity-100 flex items-center gap-1"
                    >
                      <LogOutIcon size={16} />
                      <span>Se déconnecter</span>
                    </button>
                  </p>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {!sidebarOpen && (
        <div className="absolute top-0 right-0 z-40 pt-5 pr-5 lg:hidden">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="text-slate-700 lg:hidden"
          >
            <span className="sr-only">Ouvrir</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      )}

      <main className="lg:pl-72">
        <Suspense>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

const LayoutWithProviders = () => (
  <SupabaseProvider>
    <CurrentUserProvider>
      <DismissalsProvider>
        <DrawerProvider>
          <TooltipProvider>
            <Toaster />
            <Layout />
            <Drawer />
            <ScrollTop />
          </TooltipProvider>
        </DrawerProvider>
      </DismissalsProvider>
    </CurrentUserProvider>
  </SupabaseProvider>
);

export default LayoutWithProviders;
