import { SparklesIcon } from "lucide-react";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";

import { useTabState } from "~/hooks/useTabState.ts";

export const useLatestVersionCheck = () => {
  const { isTabActive, tabStateCurrent, tabStatePrevious } = useTabState();

  const notify = useCallback(
    () =>
      toast(
        () => (
          <div className="text-sm">
            <p className="font-semibold text-blue-600 inline-flex items-center gap-1.5">
              <SparklesIcon size={16} />
              <span>Mise à jour disponible !</span>
            </p>
            <p className="text-xs text-slate-500 mt-1">
              Rafraichissez la page pour bénéficier
              <br />
              des dernières fonctionnalités de KD Tools.
            </p>
            <p className="mt-1">
              <button
                className="text-xs font-bold underline hover:no-underline"
                data-umami-event="global:refreshFromUpdate"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Rafraichir
              </button>
            </p>
          </div>
        ),
        {
          id: "global:updateAvailable",
          duration: Infinity,
        },
      ),
    [],
  );

  const getVersion = useCallback(async () => {
    const response = await fetch("/version.json", { cache: "no-store" });
    const data = await response.json();
    return data.version;
  }, []);

  const getInitialVersionCallback = useCallback(async () => {
    localStorage.setItem("global:version", await getVersion());
  }, [getVersion]);

  const checkVersionCallback = useCallback(
    async (skipNotify = false) => {
      if (!isTabActive) {
        return;
      }

      const updatedVersion = await getVersion();

      const currentVersion = localStorage.getItem("global:version");
      if (currentVersion && currentVersion !== updatedVersion) {
        if (skipNotify) {
          window.location.reload();
        } else {
          notify();
        }
      }

      localStorage.setItem("global:version", updatedVersion);
    },
    [getVersion, isTabActive, notify],
  );

  useEffect(() => {
    getInitialVersionCallback();
  }, [getInitialVersionCallback]);

  useEffect(() => {
    const interval = setInterval(checkVersionCallback, 5 * 60 * 1000); // Checking every 5 minutes…

    return () => clearInterval(interval);
  }, [checkVersionCallback]);

  useEffect(() => {
    if (
      tabStatePrevious &&
      tabStatePrevious !== "visible" &&
      tabStateCurrent === "visible"
    ) {
      checkVersionCallback(true);
    }
  }, [checkVersionCallback, tabStateCurrent, tabStatePrevious]);
};
