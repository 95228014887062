import { FC, ReactNode } from "react";

type ButtonProps = { children: ReactNode; onClick: () => void };

export const Button: FC<ButtonProps> = ({ children, onClick }) => {
  return (
    <button
      type="button"
      className="rounded-md bg-blue-50 px-3 py-2 text-sm font-semibold text-blue-600 hover:bg-blue-100 border border-blue-200"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
