import clsx from "clsx";
import { ShieldAlert } from "lucide-react";
import { FC, ReactNode } from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";

interface WrapperProps {
  header: ReactNode;
  children: ReactNode;
  requireRole?: Role;
  extended?: boolean;
  isLoading?: boolean;
}

export const Wrapper: FC<WrapperProps> = ({
  header,
  children,
  requireRole,
  extended = false,
  isLoading = false,
}) => {
  const { canAccess } = useCurrentUser();

  if (isLoading) {
    return <LayoutLoading />;
  }

  if (requireRole && !canAccess(requireRole)) {
    return (
      <div className="h-screen -mt-8 flex items-center justify-center">
        <p className="text-center text-lg flex flex-col items-center justify-center space-y-2">
          <ShieldAlert className="text-red-500" size={52} />
          <span>Accès restreint</span>
        </p>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-6xl px-4 py-4 sm:px-12 sm:py-4 md:py-6 lg:py-8">
      <div className="pr-10 sm:pr-0">{header}</div>
      <div
        className={clsx(
          "mx-auto mt-4 sm:mt-8",
          extended ? "max-w-5xl" : "max-w-3xl",
        )}
      >
        {children}
      </div>
    </div>
  );
};
