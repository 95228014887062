import { random, shuffle, take } from "lodash";

import { AnglesDirection, AnglesProposition } from "./types";

export const getPropositions = (angle: number) => {
  const correctPropositions = take(getCorrectPropositions(angle), random(0, 4));

  return shuffle([
    ...correctPropositions,
    ...getIncorrectPropositions(angle, 8 - correctPropositions.length),
  ]);
};

export const getCorrectPropositions = (angle: number): AnglesProposition[] => {
  // Normalize the angle to be within 0 to 360 degrees
  const normalizedAngle = ((angle % 360) + 360) % 360;

  // Calculate the propositions for clockwise rotation
  const clockwise = [normalizedAngle, normalizedAngle - 360];

  // Calculate the propositions for anti-clockwise rotation
  const counterClockwise = [
    (360 - normalizedAngle) % 360,
    ((360 - normalizedAngle) % 360) - 360,
  ];

  return [
    ...clockwise.map((angle) => ({
      direction: "clockwise" as const,
      angle,
      isCorrect: true,
    })),
    ...counterClockwise.map((angle) => ({
      direction: "counterClockwise" as const,
      angle,
      isCorrect: true,
    })),
  ];
};

export const getIncorrectPropositions = (
  angle: number,
  size: number,
): AnglesProposition[] => {
  const validPropositions: AnglesProposition[] = [
    { direction: "clockwise", angle: angle, isCorrect: true },
    { direction: "clockwise", angle: angle - 360, isCorrect: true },
    { direction: "counterClockwise", angle: 360 - angle, isCorrect: true },
    {
      direction: "counterClockwise",
      angle: 360 - angle - 360,
      isCorrect: true,
    },
  ];

  const multiplesOf15 = Array.from({ length: 24 }, (_, i) => 15 * (i + 1));

  const falsePropositions: AnglesProposition[] = [];

  while (falsePropositions.length < size) {
    const randomIndex = Math.floor(Math.random() * multiplesOf15.length);
    const angle = multiplesOf15[randomIndex];

    const directions: AnglesDirection[] = ["clockwise", "counterClockwise"];

    directions.forEach((direction) => {
      const proposition: AnglesProposition = {
        direction,
        angle: direction === "clockwise" ? angle : 360 - angle,
        isCorrect: false,
      };
      const isValid = validPropositions.some(
        (validProp) =>
          validProp.direction === proposition.direction &&
          validProp.angle === proposition.angle,
      );

      if (
        !isValid &&
        !falsePropositions.some(
          (falseProp) =>
            falseProp.direction === proposition.direction &&
            falseProp.angle === proposition.angle,
        )
      ) {
        falsePropositions.push(proposition);
      }
    });

    // Ensure we don't end up in an infinite loop if the size is too large
    if (
      falsePropositions.length >=
      multiplesOf15.length * 2 - validPropositions.length
    ) {
      break;
    }
  }

  return falsePropositions.slice(0, size);
};

export const generateClockwiseAndCounterClockwise = (
  start?: number,
): {
  start: number;
  clockwise: number[];
  counterClockwise: number[];
} => {
  const values = [3, 6, 9, 12];

  // If the start value is not provided or is invalid, select a random start value
  if (start === undefined || !values.includes(start)) {
    const startIndex = Math.floor(Math.random() * values.length);
    start = values[startIndex];
  } else {
    // Ensure the provided start value is valid
    if (!values.includes(start)) {
      throw new Error("Invalid starting value. Must be one of [3, 6, 9, 12]");
    }
  }

  // Find the starting index
  const startIndex = values.indexOf(start);

  // Generate the clockwise array
  const clockwise = values
    .slice(startIndex)
    .concat(values.slice(0, startIndex));

  // Generate the counter-clockwise array
  const counterClockwise = values
    .slice(0, startIndex + 1)
    .reverse()
    .concat(values.slice(startIndex + 1).reverse());

  return { start, clockwise, counterClockwise };
};

export const getAllAngles = (semiCircle = true): number[] => {
  const angles: number[] = [];

  for (let angle = 15; angle <= (semiCircle ? 180 : 360); angle += 15) {
    angles.push(angle);
  }

  return angles;
};

export const getRandomAngle = (semiCircle = false): number => {
  const multiplesOf15 = Array.from(
    { length: semiCircle ? 12 : 23 },
    (_, i) => 15 * (i + 1),
  ).filter((angle) => angle !== 180 && angle !== 360);

  const randomIndex = Math.floor(Math.random() * multiplesOf15.length);

  return multiplesOf15[randomIndex];
};

export const getOppositeAngle = (angle: number): number => (angle + 180) % 360;

export const getComplementaryAngle = (angle: number): number =>
  (360 - angle) % 360;
