import { set } from "date-fns";
import { useAtom } from "jotai";
import { useCallback } from "react";
import toast from "react-hot-toast";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { suiviPilotestAttempts } from "~/routes/suivi/atoms.ts";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { PilotestAttemptApiJson } from "~/routes/suivi/types/PilotestAttempt.ts";
import { supabase } from "~/supabase.ts";
import { Enums } from "~/supabase.types.ts";

export const usePilotestAttempts = () => {
  const { saveImport } = useImports();
  const { user, accessToken } = useCurrentUser();
  const { hasPilotestAutoSyncEnabled, credentials } = usePilotestCredentials();

  const [attempts, setAttempts] = useAtom(suiviPilotestAttempts);

  const syncPilotest = useCallback(
    () =>
      supabase
        .from("suivi_attempts")
        .select("provider, exercise, percent_score, stanine_class, at")
        .eq("user_id", user.id)
        .then(({ data }) =>
          setAttempts(
            data?.map((d) => ({
              name: `${d.exercise}##PT`,
              stanineClass: d.stanine_class,
              percentScore: d.percent_score,
              at: new Date(d.at),
            })) ?? [],
          ),
        ),
    [setAttempts, user.id],
  );

  const savePilotestAttempts = useCallback(
    async (source: Enums<"import_source">, newAttempts: ExerciseAttempt[]) =>
      supabase
        .from("suivi_attempts")
        .upsert(
          newAttempts.map((attempt) => ({
            user_id: user.id,
            provider: "pilotest",
            exercise: attempt.name,
            percent_score: attempt.percentScore,
            stanine_class: attempt.stanineClass,
            at: set(new Date(attempt.at), {
              seconds: 0,
              milliseconds: 0,
            }).toISOString(),
          })),
          { ignoreDuplicates: true },
        )
        .select()
        .then(({ data }) => {
          saveImport(source, "pilotest", data?.length);
          return syncPilotest();
        }),
    [user.id, saveImport, syncPilotest],
  );

  const retrieveResultsFromPilotestFetch = useCallback(() => {
    if (!credentials) {
      return Promise.reject();
    }

    const { email, password } = credentials;

    return fetch("https://api-kd.valentin.xyz/pilotest/results", {
      headers: {
        "X-Supabase-Token": accessToken,
        "X-PT-U": btoa(email),
        "X-PT-P": btoa(password),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return Promise.reject();
        }

        return data.map((a: PilotestAttemptApiJson) => ({
          name: a.name,
          percentScore: a.percent_score,
          stanineClass: a.stanine_class,
          at: new Date(a.at),
        }));
      });
  }, [accessToken, credentials]);

  const retrieveResultsFromPilotest = useCallback(() => {
    if (!hasPilotestAutoSyncEnabled) {
      return Promise.resolve();
    }

    const promise = retrieveResultsFromPilotestFetch().then((res) =>
      savePilotestAttempts("auto_sync", res),
    );

    toast.promise(
      promise,
      {
        loading: <p className="text-sm">Import Pilotest en cours…</p>,
        success: <p className="text-sm">Import Pilotest terminé !</p>,
        error: <p className="text-sm">Erreur lors de l'import Pilotest.</p>,
      },
      { success: { duration: 1500 } },
    );

    return promise;
  }, [
    hasPilotestAutoSyncEnabled,
    retrieveResultsFromPilotestFetch,
    savePilotestAttempts,
  ]);

  return {
    attempts,
    syncPilotest,
    retrieveResultsFromPilotest,
    savePilotestAttempts,
  };
};
