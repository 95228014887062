export const getDailyRandomItem = <T>(items: T[]): T => {
  const currentDate = new Date();
  const dateString = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

  const hash = dateString
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const index = hash % items.length;

  return items[index];
};
