import clsx from "clsx";
import { HighlighterIcon, PenOffIcon } from "lucide-react";
import { FC, useState } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { useFlaggedExercises } from "~/routes/suivi/providers/FlaggedExercisesProvider.tsx";
import { supabase } from "~/supabase.ts";

interface ExercisesResultsFlagExercisePopoverProps {
  exerciseName: string;
}

const tailwindColors = [
  { color: "red-100", hex: "#fee2e2" },
  { color: "blue-100", hex: "#dbeafe" },
  { color: "green-100", hex: "#d1fae5" },
  { color: "yellow-100", hex: "#fef9c3" },
  { color: "purple-100", hex: "#f3e8ff" },
];

export const ExercisesResultsFlagExercisePopover: FC<
  ExercisesResultsFlagExercisePopoverProps
> = ({ exerciseName }) => {
  const { user } = useCurrentUser();
  const { flaggedExercisesList, refreshFlaggedExercises } =
    useFlaggedExercises();

  const [showPalette, setShowPalette] = useState(false);

  const handlePinExercise = (color: string) => {
    supabase
      .from("suivi_flagged_exercises")
      .insert({ user_id: user.id, name: exerciseName, color })
      .then(refreshFlaggedExercises);
  };

  const handleUnpinExercise = (pinId: string) => {
    supabase
      .from("suivi_flagged_exercises")
      .delete()
      .eq("id", pinId)
      .then(refreshFlaggedExercises);
  };

  if (flaggedExercisesList[exerciseName]) {
    return (
      <Tooltip delayDuration={250}>
        <TooltipTrigger
          className="text-slate-300 hover:text-slate-500 cursor-pointer"
          asChild
        >
          <PenOffIcon
            className="hidden sm:group-hover:block w-4 h-4"
            onClick={() =>
              handleUnpinExercise(flaggedExercisesList[exerciseName].id)
            }
            data-umami-event="suivi:removeHighlight"
          />
        </TooltipTrigger>
        <TooltipContent>Effacer le surlignage</TooltipContent>
      </Tooltip>
    );
  }

  return (
    <div className="flex items-center justify-center gap-2">
      <Tooltip delayDuration={250}>
        <TooltipTrigger
          className="text-slate-300 hover:text-slate-500"
          onClick={() => setShowPalette(!showPalette)}
        >
          <HighlighterIcon className="hidden sm:group-hover:block w-4 h-4" />
        </TooltipTrigger>
        <TooltipContent>Surligner cet exercice</TooltipContent>
      </Tooltip>

      {showPalette && (
        <div className="flex gap-1.5">
          {tailwindColors.map((color) => (
            <div
              key={color.color}
              className={clsx(`rounded-full w-4 h-4 cursor-pointer`)}
              style={{ backgroundColor: color.hex }}
              onClick={() => {
                handlePinExercise(color.hex);
                setShowPalette(false);
              }}
              data-umami-event="suivi:highlight"
            />
          ))}
        </div>
      )}
    </div>
  );
};
