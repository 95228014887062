import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useEffectOnce } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface SettingsContextType {
  settings: Tables<"suivi_settings"> | null;
  registerSettings: (
    settings: Partial<Tables<"suivi_settings">>,
  ) => Promise<void>;
  saveSettings: (settings: Partial<Tables<"suivi_settings">>) => Promise<void>;
}

const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined,
);

export const useSettings = () => {
  const context = useContext(SettingsContext);

  if (context === undefined) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }

  return context;
};

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  const [settings, setSettings] = useState<Tables<"suivi_settings"> | null>(
    null,
  );
  const [settingsLoading, setSettingsLoading] = useState(true);

  useEffectOnce(() => {
    supabase
      .from("suivi_settings")
      .select()
      .eq("user_id", user.id)
      .limit(1)
      .single()
      .then(({ data }) => {
        setSettings(data);
        setSettingsLoading(false);
      });
  });

  const registerSettings = useCallback(
    async (settings: Partial<Tables<"suivi_settings">>) => {
      const { data } = await supabase
        .from("suivi_settings")
        .insert({ user_id: user.id, ...settings })
        .select()
        .single();

      setSettings(data);
    },
    [user.id],
  );

  const saveSettings = useCallback(
    async (settings: Partial<Tables<"suivi_settings">>) => {
      const { data } = await supabase
        .from("suivi_settings")
        .update(settings)
        .eq("user_id", user.id)
        .select()
        .single();

      setSettings(data);
    },
    [user.id],
  );

  if (settingsLoading) {
    return <LayoutLoading />;
  }

  return (
    <SettingsContext.Provider
      value={{ settings, registerSettings, saveSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
