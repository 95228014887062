import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface GroupsContextType {
  groups: Tables<"suivi_groups">[];
  refreshGroups: () => void;
}

const GroupsContext = createContext<GroupsContextType | undefined>(undefined);

export const useGroups = () => {
  const context = useContext(GroupsContext);

  if (context === undefined) {
    throw new Error("useGroups must be used within an GroupsProvider");
  }

  return context;
};

interface GroupsProviderProps {
  children: ReactNode;
}

export const GroupsProvider: React.FC<GroupsProviderProps> = ({ children }) => {
  const { user } = useCurrentUser();

  const [groups, setGroups] = useState<Tables<"suivi_groups">[]>([]);

  const refreshGroups = useCallback(
    async () =>
      supabase
        .from("suivi_groups")
        .select("*")
        .eq("user_id", user.id)
        .order("name", { ascending: true })
        .then(({ data }) => setGroups(data ?? [])),
    [user.id],
  );

  useEffect(() => {
    refreshGroups();
  }, [refreshGroups]);

  return (
    <GroupsContext.Provider value={{ groups, refreshGroups }}>
      {children}
    </GroupsContext.Provider>
  );
};
