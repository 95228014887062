import { ChangeEvent } from "react";

import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { PilotestAttemptTamperMonkeyJson } from "~/routes/suivi/types/PilotestAttempt.ts";

export const handleJsonUpload = (
  event: ChangeEvent<HTMLInputElement>,
  onResults: (results?: ExerciseAttempt[]) => void,
) => {
  if (!event.target?.files?.[0]) {
    return;
  }

  const file = event.target?.files?.[0];

  const reader = new FileReader();
  reader.onload = (event) => {
    try {
      const jsonRows = JSON.parse(event.target?.result as string);

      onResults(
        jsonRows.map((row: PilotestAttemptTamperMonkeyJson) => ({
          name: row.test_name,
          percentScore: Math.trunc(row.score * 100),
          stanineClass: row.stanine,
          at: new Date(row.created_at),
        })),
      );
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };
  reader.onerror = (error) => {
    console.error("Error reading file:", error);
  };

  reader.readAsText(file);
};
