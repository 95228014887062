import { useAtom } from "jotai/index";
import { FC } from "react";

import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Role } from "~/role.ts";
import { AnglesView } from "~/routes/angles/AnglesView.tsx";
import { anglesModeAtom } from "~/routes/angles/atoms.ts";
import { ComplementaryAnglesView } from "~/routes/angles/ComplementaryAnglesView.tsx";
import { GridView } from "~/routes/angles/GridView.tsx";

export type ANGLES_MODES = "default" | "complementary_angles" | "angles";

export const Angles: FC = () => {
  const [mode, setMode] = useAtom(anglesModeAtom);

  return (
    <Wrapper
      requireRole={Role.Angles}
      header={
        <div className="flex items-center justify-between">
          <h1 className="w-full flex justify-between items-center">
            <p className="text-3xl font-bold underline">Angles</p>
            <div className="flex gap-3">
              <ScoreButton
                label="Angles"
                exercise="angles"
                variant={mode}
                period="weekly"
              />
              <Select
                value={mode}
                onValueChange={(mode) => setMode(mode as ANGLES_MODES)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Sélectionner un mode…" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="default">Examen</SelectItem>
                  <SelectItem value="complementary_angles">
                    Angles Complémentaires
                  </SelectItem>
                  <SelectItem value="angles">Angles</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </h1>
        </div>
      }
    >
      {mode === "complementary_angles" ? (
        <ComplementaryAnglesView />
      ) : mode === "angles" ? (
        <AnglesView />
      ) : (
        <GridView />
      )}
    </Wrapper>
  );
};
