import clsx from "clsx";
import { FC, useState } from "react";
import { useStopwatch } from "react-timer-hook";

import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import { useScores } from "~/hooks/useScores.ts";
import { generateGrid, getRandomAlphabet } from "~/routes/tangram/utils.tsx";

export interface TrainingBaseViewProps {
  withChrono?: boolean;
}

export const TrainingBaseView: FC<TrainingBaseViewProps> = () => {
  const {
    totalSeconds,
    reset: resetStopwatch,
    pause: pauseStopwatch,
  } = useStopwatch({
    autoStart: true,
  });
  const { saveScore } = useScores();

  const [randomAlphabet] = useState(getRandomAlphabet());
  const [grid, setGrid] = useState(generateGrid(4, randomAlphabet));
  const [highlightedFigure, setHighlightedFigure] = useState("");
  const [showResult, setShowResult] = useState(false);

  const [values, setValues] = useState<Record<string, string>>({});

  const handleOnClickFigure = (name: string) => {
    setHighlightedFigure(name === highlightedFigure ? "" : name);
  };

  const handleShowResult = () => {
    pauseStopwatch();
    setShowResult(true);

    saveScore("tangram", "guess-all", {
      timing: totalSeconds,
      success: grid.figures.every(
        (fig) =>
          grid.items.filter((f) => f.letter === fig.letter).length ===
          parseInt(values[fig.letter] ?? "0"),
      ),
    });
  };

  const handleReset = () => {
    setValues({});
    setShowResult(false);
    setGrid(generateGrid(4, randomAlphabet));
    resetStopwatch();
  };

  const withGrid = false;

  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          "mx-auto flex flex-wrap w-80 sm:w-96",
          withGrid && "divide-x divide-y",
        )}
      >
        {grid.items.map(({ Figure, name, letter }, idx) => (
          <Figure
            key={idx}
            onClick={() => handleOnClickFigure(name)}
            highlight={highlightedFigure === name}
            withCaption={showResult ? letter : undefined}
            size="md"
          />
        ))}
      </div>

      <div className="mt-6 flex flex-wrap gap-2 sm:gap-4 justify-center bg-slate-100 py-2">
        {grid.figures.map(({ Figure, name, letter }) => (
          <div key={`figure-${name}`}>
            <Figure
              size="xs"
              highlight={highlightedFigure === name}
              onClick={() => handleOnClickFigure(name)}
            />
            <Input
              value={values[letter] ?? ""}
              onChange={(evt) =>
                setValues({
                  ...values,
                  [letter]: evt.currentTarget.value,
                })
              }
              className={clsx(
                "w-16 mt-2 mx-auto text-center",
                showResult &&
                  (grid.items.filter((f) => f.letter === letter).length ===
                  parseInt(values[letter] ?? "0")
                    ? "bg-green-100"
                    : "bg-red-100 font-semibold"),
              )}
            />
          </div>
        ))}
      </div>

      <div className="mt-2">
        {showResult ? (
          <div className="text-center">
            <Button className="w-full" onClick={handleReset}>
              Suivant
            </Button>
            <p className="text-xs mt-2 text-slate-500">({totalSeconds} sec)</p>
          </div>
        ) : (
          <Button className="w-full" onClick={handleShowResult}>
            Correction
          </Button>
        )}
      </div>
    </div>
  );
};
