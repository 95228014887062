import clsx from "clsx";
import { FC, useEffect } from "react";
import { Updater } from "use-immer";

import { Input } from "~/components/ui/input.tsx";
import { Switch } from "~/components/ui/switch.tsx";
import IconEpl from "~/routes/suivi/imgs/epl-icon.png";
import { OnboardingSettings } from "~/routes/suivi/OnboardingPage.tsx";

export interface OnboardingEplTestProps {
  settings: OnboardingSettings;
  onChangeSettings: Updater<OnboardingSettings>;
}

export const OnboardingEplTest: FC<OnboardingEplTestProps> = ({
  settings,
  onChangeSettings,
}) => {
  useEffect(() => {
    if (settings.eplTest.enabled) {
      return;
    }

    onChangeSettings((draft) => {
      delete draft.eplTest.apiKey;
    });
  }, [onChangeSettings, settings.eplTest.enabled]);

  return (
    <div
      className={clsx(
        "text-sm border rounded px-4 py-3",
        settings.eplTest.enabled && "shadow-md shadow-blue-100",
      )}
    >
      <h2 className="flex items-center justify-between font-semibold text-xl">
        <p className="space-x-2">
          <img src={IconEpl} className="w-6 h-6 inline mr-1 -mt-1" />
          <span>EPLtest</span>
        </p>
        <Switch
          checked={settings.eplTest.enabled}
          onCheckedChange={(value) =>
            onChangeSettings((draft) => {
              draft.eplTest.enabled = value;
            })
          }
        />
      </h2>
      {settings.eplTest.enabled && (
        <div className="mt-4 pb-2">
          <p>
            Saisissez votre clé API <strong>EPLtest</strong> pour synchroniser
            automatiquement vos résultats.
          </p>
          <p className="mt-4">
            1. <span className="font-semibold">Récupérez votre clé API</span>{" "}
            EPLtest depuis{" "}
            <a
              href="https://epltest.fr/fr/account/public_api"
              rel="noreferrer"
              className="underline hover:no-underline"
              target="_blank"
            >
              ce lien
            </a>
            .
          </p>
          <div className="mt-3">
            <label htmlFor="apiKey" className="font-semibold block">
              2. Copiez/collez votre clé :
            </label>
            <Input
              id="apiKey"
              type="text"
              name="apiKey"
              onChange={(e) =>
                onChangeSettings((draft) => {
                  draft.eplTest.apiKey = e.currentTarget.value;
                })
              }
              value={settings.eplTest.apiKey ?? ""}
              className="w-full mt-2"
            />
          </div>
        </div>
      )}
    </div>
  );
};
