import { FC } from "react";

import { EplTestDetailsDrawer } from "~/routes/suivi/components/EplTestDetailsDrawer.tsx";
import { PilotestDetailsDrawer } from "~/routes/suivi/components/PilotestDetailsDrawer.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";
import { getExerciseNameAndSource } from "~/routes/suivi/utils/getExerciseNameAndSource.ts";

interface ExercisesResultsDetailsDrawerProps {
  exerciseName: string;
  attempts: ExerciseAttempt[];
}

export const ExercisesResultsDetailsDrawer: FC<
  ExercisesResultsDetailsDrawerProps
> = ({ exerciseName, attempts }) => {
  const { source } = getExerciseNameAndSource(exerciseName);

  return source === "PT" ? (
    <PilotestDetailsDrawer exercise={exerciseName} />
  ) : (
    <EplTestDetailsDrawer attempts={attempts} />
  );
};
