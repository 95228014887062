import clsx from "clsx";
import { sample } from "lodash";
import { StarIcon } from "lucide-react";
import { FC, useCallback, useEffect, useState } from "react";

import { ButtonPillCountdown } from "~/components/ButtonPillCountdown.tsx";
import { useScores } from "~/hooks/useScores.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { QuizletFilter } from "~/routes/quizlet/index.tsx";
import { supabase } from "~/supabase.ts";
import { Enums, Tables } from "~/supabase.types.ts";

interface QuestionsProps {
  filter: QuizletFilter;
}

const CATEGORIES: Record<Enums<"quizlet_category">, string> = {
  aircrafts: "Appareils",
  airports: "Aéroports",
  airline: "Compagnie",
  fleet: "Flotte",
  network: "Réseau",
  theory: "PPL/BIA",
  military: "Militaire",
  history: "Histoire",
  geography: "Géographie",
};

export const Questions: FC<QuestionsProps> = ({ filter }) => {
  const { user, canAccess } = useCurrentUser();
  const { saveScore } = useScores();

  const [questions, setQuestions] = useState<Tables<"quizlet_questions">[]>([]);
  const [bookmarks, setBookmarks] = useState<string[]>([]);

  const [currentQuestion, setCurrentQuestion] =
    useState<Tables<"quizlet_questions"> | null>(null);
  const [currentAnswer, setCurrentAnswer] = useState<string | null>(null);

  const refreshBookmarks = useCallback(async () => {
    const { data } = await supabase
      .from("quizlet_bookmarks")
      .select("quizlet_question_id")
      .eq("user_id", user.id);

    setBookmarks((data ?? []).map((b) => b.quizlet_question_id));
  }, [user.id]);

  useEffect(() => {
    (filter === "allQuestions"
      ? supabase.from("quizlet_questions").select()
      : filter === "bookmarked"
        ? supabase
            .from("quizlet_questions")
            .select("*, quizlet_bookmarks!inner(*)")
            .eq("quizlet_bookmarks.user_id", user.id)
        : supabase
            .from("quizlet_questions")
            .select()
            .eq(
              "author",
              user.identities?.find((i) => i.provider === "discord")
                ?.identity_data?.full_name,
            )
    ).then(({ data }) => {
      setQuestions(data ?? []);
      setCurrentQuestion(sample(data) ?? null);
    });
  }, [filter]);

  useEffect(() => {
    refreshBookmarks();
  }, [refreshBookmarks]);

  const handleOnReportIssue = () => {
    if (!currentQuestion) {
      return;
    }

    const issue = prompt(
      "Quel problème souhaitez-vous signaler sur cette question ?",
    );

    if (!issue) {
      return;
    }

    supabase.from("quizlet_issues").insert({
      user_id: user.id,
      quizlet_question_id: currentQuestion.id,
      issue,
    });

    alert(
      "Merci ! Un modérateur va prendre connaissance de votre signalement sous peu.",
    );

    handleNextQuestion();
  };

  const handleOnDeleteQuestion = async () => {
    if (!currentQuestion) {
      return;
    }

    if (confirm("Souhaitez-vous vraiment supprimer cette question ?")) {
      await supabase
        .from("quizlet_questions")
        .delete()
        .eq("id", currentQuestion.id);

      setCurrentQuestion(sample(questions) ?? null);
      setCurrentAnswer(null);
    }
  };

  const handleOnCategoriseQuestion = (
    category: Enums<"quizlet_category">,
    nextQuestion = false,
  ) => {
    if (!currentQuestion) {
      return;
    }

    supabase
      .from("quizlet_questions")
      .update({ category })
      .eq("id", currentQuestion.id)
      .then(() => {
        if (nextQuestion) {
          handleNextQuestion();
        }
      });
  };

  const handleNextQuestion = () => {
    if (!currentQuestion || !currentAnswer) {
      return;
    }

    saveScore("quizlet", filter, {
      success: currentAnswer === currentQuestion.correct_answer,
      timing: 0,
    });

    setCurrentQuestion(sample(questions) ?? null);
    setCurrentAnswer(null);
  };

  const handleOnBookmarkQuestion = () => {
    if (!currentQuestion) {
      return;
    }

    const alreadyBookmarked = bookmarks.includes(currentQuestion.id);

    const supabasePromise = alreadyBookmarked
      ? supabase
          .from("quizlet_bookmarks")
          .delete()
          .eq("quizlet_question_id", currentQuestion.id)
          .eq("user_id", user.id)
      : supabase.from("quizlet_bookmarks").insert({
          quizlet_question_id: currentQuestion.id,
          user_id: user.id,
        });

    supabasePromise.then(refreshBookmarks);
  };

  if (!currentQuestion) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="bg-slate-100 px-2 py-6 sm:py-12 sm:px-2 rounded-md relative">
        <p className="text-lg sm:text-2xl font-medium max-w-2xl mx-auto text-center">
          {currentQuestion.question}
        </p>
        <div className="absolute top-3 right-4">
          <button
            title="Mettre en favoris"
            onClick={handleOnBookmarkQuestion}
            className={clsx(
              "flex items-center justify-center space-x-1 text-xs text-yellow-500",
            )}
          >
            <StarIcon
              className={clsx(
                "w-4 sm:w-6 h-4 sm:h-6 hover:fill-yellow-500",
                bookmarks.includes(currentQuestion.id) && "fill-yellow-500",
              )}
              strokeWidth={1.5}
            />
          </button>
        </div>
      </div>
      <div className="flex text-xs items-center justify-end mt-2 text-slate-300 gap-1">
        <p>
          par <span className="text-slate-400">@{currentQuestion.author}</span>
        </p>
        -
        {!canAccess(Role.AdminQuizlet) && (
          <button
            className="underline hover:no-underline text-slate-400 cursor-pointer"
            onClick={handleOnReportIssue}
          >
            Signaler un problème
          </button>
        )}
        {canAccess(Role.AdminQuizlet) && (
          <p className="flex space-x-2">
            <button
              className="underline hover:no-underline text-red-500 cursor-pointer"
              onClick={handleOnDeleteQuestion}
            >
              Supprimer
            </button>
          </p>
        )}
      </div>
      <div className={clsx("mt-8  grid grid-cols-1 gap-4", `sm:grid-cols-2`)}>
        {currentQuestion.answers.map((answer) => (
          <button
            key={answer}
            className={clsx(
              "border px-3 py-2 flex flex-col items-center justify-center rounded cursor-pointer hover:bg-slate-50",
              currentAnswer && "pointer-events-none",
              currentAnswer &&
                answer === currentQuestion.correct_answer &&
                "bg-green-100 font-semibold border-2 border-green-500",
              currentAnswer &&
                answer === currentAnswer &&
                currentAnswer !== currentQuestion.correct_answer &&
                "bg-yellow-100 border-yellow-500",
            )}
            onClick={() => setCurrentAnswer(answer)}
          >
            {answer}
          </button>
        ))}
      </div>
      {currentAnswer && (
        <div className="text-center mt-4">
          {canAccess(Role.AdminQuizlet) && !currentQuestion.category ? (
            <select
              className="text-black py-1.5 rounded w-full"
              onChange={(evt) =>
                handleOnCategoriseQuestion(
                  evt.currentTarget.value as Enums<"quizlet_category">,
                  true,
                )
              }
            >
              <option value="">Catégoriser…</option>
              {Object.entries(CATEGORIES).map(
                ([categoryKey, categoryValue]) => (
                  <option
                    key={`category-${currentQuestion.id}-${categoryKey}`}
                    value={categoryKey}
                  >
                    {categoryValue}
                  </option>
                ),
              )}
            </select>
          ) : (
            <ButtonPillCountdown
              duration={
                canAccess(Role.AdminQuizlet)
                  ? -1
                  : currentAnswer !== currentQuestion.correct_answer
                    ? 4
                    : 1
              }
              onExpire={handleNextQuestion}
              onClick={handleNextQuestion}
              className="w-full"
            >
              Suivant
            </ButtonPillCountdown>
          )}
        </div>
      )}
    </div>
  );
};
