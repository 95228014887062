export const fillClassNameFromStanineClass = (stanineClass: number): string => {
  if (stanineClass < 2) {
    return "fill-red-500";
  } else if (stanineClass < 3) {
    return "fill-orange-500";
  } else if (stanineClass < 4) {
    return "fill-amber-500";
  } else if (stanineClass < 5) {
    return "fill-yellow-500";
  } else if (stanineClass < 6) {
    return "fill-lime-500";
  } else if (stanineClass < 7) {
    return "fill-green-500";
  } else if (stanineClass < 8) {
    return "fill-emerald-500";
  } else if (stanineClass < 9) {
    return "fill-blue-500";
  } else if (stanineClass < 10) {
    return "fill-blue-800";
  } else {
    return "fill-black";
  }
};
